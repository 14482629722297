@import '../v2/colors';

.multi-range-slider {
  padding: 8px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  .multi-range-slider-thumb {
    position: absolute;
    height: 0;
    width: 100%;
    padding: 0;
    border: none;
    outline: none;
    pointer-events: none;
    -webkit-appearance: none;

    &::-webkit-slider-thumb {
      position: relative;
      height: 20px;
      width: 20px;
      margin-top: 4px;
      background-color: $color-green;
      border: 2px solid $color-white;
      border-radius: 50%;
      cursor: pointer;
      pointer-events: all;
      -webkit-appearance: none;
    }

    &::-moz-range-thumb {
      position: relative;
      height: 20px;
      width: 20px;
      margin-top: 4px;
      background-color: $color-green;
      border: 2px solid $color-white;
      border-radius: 50%;
      cursor: pointer;
      pointer-events: all;
    }

    &-left {
      z-index: 3;
    }

    &-right {
      z-index: 4;
    }
  }

  .multi-range-slider-inner {
    position: relative;
    width: 100%;

    .multi-range-slider-track,
    .multi-range-slider-range {
      position: absolute;
      border-radius: 3px;
      height: 5px;
    }

    .multi-range-slider-track {
      background-color: $color-grey-five;
      width: 100%;
      z-index: 1;
    }

    .multi-range-slider-range {
      background-color: $color-green;
      z-index: 2;
    }
  }
}
