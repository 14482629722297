@import "~src/scss/mixin/responsive";

.b-auth-logo {
  margin-bottom: 40px;
  display: inline-block;
}

.b-auth-text {
  text-align: center;
  margin: 0 auto;

  @include respond-to-min(990) {
    max-width: 80%;
  }
}


.w-auth {
  display: flex;
  min-height: calc(100vh - 190px);
  margin-bottom: -1px;

  @include media-breakpoint-down(sm) {
    min-height: 100vh;
  }


  @include respond-to-max(1000) {
    flex-direction: column;
  }

  &-media {
    padding: 50px;
    display: flex;
    flex: 0 1 39%;
    position: relative;
    align-items: center;

    &:before {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      content: '';
      position: absolute;
      background-color: rgba(70, 143, 100, .7);
    }

    & > .container {
      z-index: 1;
      color: #fff;
      position: relative;
    }
  }

  &-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
  }

  &--sing-in {

    .w-auth-media {
      background: url('/assets/img/sign-in.jpg') no-repeat top center;
      background-size: cover;
    }
  }

  &--sing-up {

    .w-auth-media {
      background: url('/assets/img/sign-up.jpg') no-repeat top center;
      background-size: cover;
    }
  }

  &--reset-email {

    .w-auth-media {
      background: url("https://picsum.photos/800/1000/?image=56") no-repeat top center;
      background-size: cover;
    }
  }
}
