@import '../variables';

app-colabo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  app-timeline {
    @include respond-to-max(880) {
      display: none;
    }
  }

  .container {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .b-card {
    padding: 10px;
  }
}

.colabo-100 {
  display: flex;
  min-height: 100%;
  flex-direction: column;

  .container--wide {
    max-width: 100%;
  }

  .container {
    padding: 0px;
  }
}

.colabo-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1rem;

  @include respond-to-max(1200) {
    padding: 0;
  }

  .container {

    @include respond-to-max(1200) {
      padding: 0;
    }
  }
}

app-chat-tree {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-tree-view {
  flex: 1;
  transition: opacity .4s;

  .dx-treeview-node-container {
    padding-top: 10px;

    .badge {
      top: -8px;
      left: 45px;
    }

    .tree-item-name {
      max-width: 250px;
      overflow: hidden !important;
      text-overflow: ellipsis !important;

      .name {
        padding-top: 2px;
        padding-bottom: 2px;
        display: inline-block;
        font-size: 18px;
      }
    }

    .dx-treeview-node-container .tree-item-name .name {
      font-size: 16px;
    }
  }

  .dx-treeview-item:hover .name {
    border-color: $color-white;
  }

  .dx-treeview-node {
    padding-left: 0;

    .dx-treeview-node {
      padding-left: 15px;

      &.dx-state-selected .dx-treeview-item-content {
        font-weight: 700;

        .tree-item-name .name {
          display: inline-block;
        }
      }
    }
  }

  img {
    width: 33px;
    height: 33px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.5);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
    margin: 5px;
  }

  .dx-treeview-toggle-item-visibility:before {
    font-family: FontAwesome;
    content: "\f196";
    display: inline-block;
    font-size: 0.9rem;
    color: $color-primary;
  }

  .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened:before {
    font-family: FontAwesome;
    content: "\f147";
    display: inline-block;
    font-size: 0.9rem;
    color: $color-primary;
  }

  .dx-treeview-item-content {
    padding-right: 20px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .dx-treeview-toggle-item-visibility {
    top: 0;
    right: 0;
    left: auto;
  }

  .dx-item.dx-treeview-item {
    padding: 0;

    .dx-template-wrapper {
      & > [class^="icon"] {
        font-size: 24px;
      }
    }
  }
}

.colabo-columns {
  padding-bottom: 61px;
  padding-top: 18px;
  max-height: calc(100vh - 74px);
  display: flex;
  flex-grow: 1;
}

app-timeline + .colabo-columns {
  max-height: calc(100vh - 169px);
  padding-top: 0;
}

.collapse-button {
  width: 20px;
  height: 20px;
  padding: 3px;
  border: none;
  flex-shrink: 0;
  font-size: 9px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #fff;

  @include respond-to-max(880) {
    display: none;
  }

  &-mob {
    display: none;

    @include respond-to-max(880) {
      display: block;
    }
  }

  span {
    display: inline-block;
    transition: transform .4s;
  }
}

.col-channels {
  width: 300px;
  display: flex;
  flex-direction: column;
  transition: all .4s;

  &.collapsed {
    width: 60px;

    .b-header {
      width: 0;
    }

    .collapse-button span {
      transform: rotate(180deg);
    }

    .chat-tree-view {
      opacity: 0;
    }
  }

  &-header {
    margin: 20px;
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;

    @include respond-to-max(880) {
      display: flex;
    }

    .b-header {
      overflow: hidden;
      position: relative;
      transition: all .4s;
      font-size: 18px;

      &-notification {
        top: 0;
        width: 18px;
        color: #fff;
        right: -20px;
        height: 18px;
        display: flex;
        font-size: 12px;
        border-radius: 50%;
        position: absolute;
        align-items: center;
        justify-content: center;
        background-color: #ff5e5e;
      }
    }

    .badge {
      margin: 0 0 -1px 5px;
      padding: 0 5px;
      width: auto;
      position: static;
      border-radius: 8px;
    }
  }

  &-tree {
    max-height: calc(100% - 53px);
    flex-grow: 1;
  }

  .chat-tree-wrapper {
    padding: 20px 20px 6px;
  }
}

.col-chat {
  display: flex;
  flex: 0 1 40%;
  min-width: 300px;
  margin-left: 5px;
  flex-direction: column;

  &-header,
  &-tabs {
    flex: 1;
  }

  &-chat {
    flex: 20;
    display: flex;
    flex-direction: column;
    background: $color-white;

    app-chat-view {
      min-height: 100%;
    }

    .dx-list .dx-empty-message {
      border-top: 0;
    }
  }
}

.col-chat-tabs {
  font-size: 1rem;
  border-bottom: none;

  .dx-tabs-wrapper .dx-tab {
    padding: 15px;
    width: 100%;
    margin-left: 0 !important;
    max-width: 300px;

    &:after {
      content: none;
    }

    .tab-title {
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.075rem;
      color: #8E939C;
      text-transform: uppercase;
    }

    &.dx-tab-selected {
      border-top-left-radius: 22px;
      border-top-right-radius: 22px;
      background: #F3F4F6;

      .tab-title {
        color: #424B5A;
      }
    }
  }

  .badge {
    top: 8px;
    left: auto;
    right: 0;
  }

  @include respond-to-max(880) {
    padding-left: 63px;
  }
}

.col-lease {
  flex: 1 1 40%;
  min-width: 300px;
}

.b-step {
  display: flex;
  align-items: center;

  &-media {
    width: 20px;
    color: #fff;
    height: 20px;
    display: flex;
    flex-shrink: 0;
    font-size: 13px;
    font-weight: 500;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    background-color: $color-primary;
  }

  &-text {
    font-size: 14px;
    padding-left: 10px;
  }
}



.w-col-channels {
  z-index: 100;
  border: 1px solid #dfdfdf;
  border-radius: 4px 0 0 4px;
  background-color: #FAFAFB;

  @include respond-to-max(880) {
    top: 0;
    bottom: 0;
    left: -130%;
    border: none;
    border-radius: 0;
    position: absolute;
    transition: all ease .2s;
  }

  &.active {
    left: 0;
    width: 100%;
    max-width: 80%;

    &:after {
      top: 0;
      bottom: 0;
      width: 25%;
      left: 100%;
      content: "";
      position: absolute;
      background-color: rgba(0, 0, 0, .6);
    }
  }

  .chat-tree-view .dx-treeview-toggle-item-visibility:before {
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    content: "\e909";
  }

  .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened:before {
    color: #fff;
    font-family: "icomoon" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #fff;
    content: "\e908";
  }

  .dx-treeview-item-without-checkbox.dx-state-selected>.dx-treeview-item {
    background: inherit;
  }

  .dx-treeview-node {
    color: #fff;

    &+.dx-treeview-node {
      margin-top: 5px;
    }
  }
}

.w-col-chat {
  margin-left: 18px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dfdfdf;

  @include respond-to-max(880) {
    margin-left: 0;
    margin-top: 5px;
    min-height: calc(100% - 5px);
  }

  &.full--screen {
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    bottom: 0;
    display: flex;
    position: fixed;
  }

  .w-chat {
    padding: 33px 17px;

    @include respond-to-max(880) {
      padding: 15px;
    }
  }
}

.controls-back {
  z-index: 1;
  top: 15px;
  left: 20px;
  width: 21px;
  color: #fff;
  height: 21px;
  border: none;
  display: none;
  flex-shrink: 0;
  font-size: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #4EC280;

  @include respond-to-max(880) {
    display: flex;
  }
}

.controls-back.right {
  left: calc(100% - 40px)
}

@media(max-width: 375px) {
  .controls-back.right {
    left: calc(100% - 33px);
    top: 12px !important;
  }
}

.w-col-lease {
  padding: 16px 20px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-left: none;
  border-radius: 0 4px 4px 0;

  @include respond-to-max(1200) {
    padding: 0px 5px;
  }

  @include respond-to-max(880) {
    border-right: 0;
    border-radius: 0;
    border: none;
    padding: 0;
  }

  .b-header {
    font-size: 22px;
    margin: 0 0 20px;
  }

  .b-list-status {

    .name {

      @include respond-to-max(880) {
        white-space: normal;
        text-overflow: inherit;
      }
    }

    &-header {

      @include respond-to-max(1200) {
        margin-left: 20px;
      }
    }

    .dx-accordion-item-body {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .b-header {

    @include respond-to-max(1200) {
      margin-left: 20px;
    }
  }
}

.w-mobile-timeline {
  display: none;
  position: relative;

  @include respond-to-max(880) {
    display: block;
  }

  .b-collapse {

    .dx-accordion-item {

      &-title {
        color: #4D4D4D;
        position: relative;
        padding: 24px 15px;
        justify-content: flex-end;

        &:before {
          color: #4D4D4D;
        }
      }

      &.dx-accordion-item-opened {

        &>.dx-accordion-item-title {
          border-bottom: 1px solid #ddd;
        }
      }
    }
  }

  .b-step {
    top: 23px;
    left: 10px;
    position: absolute;
  }
}

$badge-height: 1rem;

.badge {
  padding: 0;
  height: $badge-height;
  width: $badge-height;
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: 25;
  border-radius: 50%;
  color: $color-white;
  font-size: 12px;
  font-weight: normal;
  line-height: $badge-height;
  text-align: center;
}
.badge-danger {background-color: rgba($color-red, .8);}

@media(max-width: 412px) {
  .collapse-button {
    padding: 5px;
  }
}

.tenant-square-footage-popup {
  background-color: rgba(0, 0, 0, .643) !important;

  .dx-overlay-content {
    border-radius: 6px !important;
    box-shadow: none !important;

    .dx-popup-content {
      padding: 0 !important;
      height: 100% !important;

      .dx-button-content {
        .dx-icon {
          width: 12px !important;
          height: 12px !important;
          font-size: 12px !important;
          line-height: 12px !important;
        }

        .dx-button-text {
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }

      .dx-texteditor.dx-editor-outlined {
        border: 0 !important;
        border-radius: 0 !important;
      }
    }
  }

  dx-number-box, dx-select-box {
    input {
      text-align: center !important;
    }
  }

  .dx-dropdowneditor-button-visible .dx-texteditor-input {
    padding-right: 9px !important;
  }

  app-tenant-square-footage-phase-in-result td {
    font-weight: 600;
  }

  app-tenant-square-footage-phase-in-result .table-responsive.with-margin {
    margin: 0;
  }
}

.burn-down-schedule-dialog {
  background-color: rgba(0, 0, 0, .643) !important;

  .dx-overlay-content {
    border-radius: 6px !important;
    box-shadow: none !important;

    .dx-popup-content {
      padding: 0 !important;
      height: 100% !important;

      .dx-button-content {
        .dx-icon {
          width: 12px !important;
          height: 12px !important;
          font-size: 12px !important;
          line-height: 12px !important;
        }

        .dx-button-text {
          font-size: 12px !important;
          font-weight: 500 !important;
        }
      }

      .dx-texteditor.dx-editor-outlined {
        border: 0 !important;
        border-radius: 0 !important;
      }
    }
  }

  app-security-deposit-burn-down-schedule-result td {
    font-weight: 600;
  }
}
