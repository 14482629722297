.b-message {
  max-width: 75%;
  display: flex;
  min-width: 300px;
  margin: 0 0 18px 10px;
  width: fit-content;

  &--self {
    margin-left: auto;
    justify-content: flex-end;


    .b-message-ava {
      margin: 0 0 0 22px;
    }

    .b-message-body {
      margin-right: 16px;
      justify-content: flex-end;
      &-date {
        margin-left: 0;
      }

      &-content {
        background-color: #e4f0f5;
        border-radius: 20px 20px 0px 20px;
      }
    }
  }

  &-ava {
    width: 46px;
    height: 46px;
    flex-shrink: 0;
    overflow: hidden;
    margin: 0 14px 0 0;
    border-radius: 50%;
  }

  &-body {
    display: flex;
    width: fit-content;
    // width: 100%;
    color: #4d4d4d;
    font-size: 14px;
    flex-wrap: wrap;
    min-height: 90px;
    position: relative;
    border-radius: 7px;
    align-items: center;
    // justify-content: space-between;

    &-author {
      color: #858585;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 2px;
      margin-right: 6px;
      text-transform: capitalize;
    }

    &-content {
      flex: 0 1 content;
      min-width: 35%;
      padding: 15px 15px;
      background-color: #daffea;
      border-radius: 20px 20px 20px 0;
    }

    &-date {

    margin-right: 7%;
      color: #a6a6a6;
      display: block;
      font-size: 12px;
      padding: 0 10px;
    }

    &-text {
      color: #4d4d4d;
      font-size: 15px;
      font-weight: 500;
    }
  }
}

.w-chat {
  padding: 18px 0 0 0;

  &-body {
    overflow-y: auto;
    padding-right: 15px;
    flex-direction: column;
  }

  &-controls {
    margin-top: 15px;
    position: relative;
    align-items: center;

    & > .b-btn {
      right: 15px;
      min-width: 80px;
      position: absolute;
    }

    & > .b-input {
      min-height: 70px;
      border-color: #e8e8e8;
      background-color: #f7f7f7;
    }
  }
}
