@charset "UTF-8";
/*!
 * Bootstrap v4.6.2 (https://getbootstrap.com/)
 * Copyright 2011-2022 The Bootstrap Authors
 * Copyright 2011-2022 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
@import "../assets/fonts/icon-fonts/style.css";
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap");
@import url("https://fonts.googleapis.com/css?family=Source+Sans+Pro:900&display=swap");
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #59b680;
  --secondary: #a6a6a6;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #4d4d4d;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #59b680;
  text-decoration: none;
  background-color: transparent;
}
a:hover {
  color: #3b875b;
  text-decoration: underline;
}

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio],
input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 2rem;
}

h3, .h3 {
  font-size: 1.75rem;
}

h4, .h4 {
  font-size: 1.5rem;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 0.875em;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

pre {
  display: block;
  font-size: 87.5%;
  color: #212529;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.no-gutters > .col,
.no-gutters > [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}

.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.33333333%;
  max-width: 8.33333333%;
}

.col-2 {
  flex: 0 0 16.66666667%;
  max-width: 16.66666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.33333333%;
  max-width: 33.33333333%;
}

.col-5 {
  flex: 0 0 41.66666667%;
  max-width: 41.66666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.33333333%;
  max-width: 58.33333333%;
}

.col-8 {
  flex: 0 0 66.66666667%;
  max-width: 66.66666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.33333333%;
  max-width: 83.33333333%;
}

.col-11 {
  flex: 0 0 91.66666667%;
  max-width: 91.66666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-sm-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-sm-first {
    order: -1;
  }

  .order-sm-last {
    order: 13;
  }

  .order-sm-0 {
    order: 0;
  }

  .order-sm-1 {
    order: 1;
  }

  .order-sm-2 {
    order: 2;
  }

  .order-sm-3 {
    order: 3;
  }

  .order-sm-4 {
    order: 4;
  }

  .order-sm-5 {
    order: 5;
  }

  .order-sm-6 {
    order: 6;
  }

  .order-sm-7 {
    order: 7;
  }

  .order-sm-8 {
    order: 8;
  }

  .order-sm-9 {
    order: 9;
  }

  .order-sm-10 {
    order: 10;
  }

  .order-sm-11 {
    order: 11;
  }

  .order-sm-12 {
    order: 12;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-md-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-md-first {
    order: -1;
  }

  .order-md-last {
    order: 13;
  }

  .order-md-0 {
    order: 0;
  }

  .order-md-1 {
    order: 1;
  }

  .order-md-2 {
    order: 2;
  }

  .order-md-3 {
    order: 3;
  }

  .order-md-4 {
    order: 4;
  }

  .order-md-5 {
    order: 5;
  }

  .order-md-6 {
    order: 6;
  }

  .order-md-7 {
    order: 7;
  }

  .order-md-8 {
    order: 8;
  }

  .order-md-9 {
    order: 9;
  }

  .order-md-10 {
    order: 10;
  }

  .order-md-11 {
    order: 11;
  }

  .order-md-12 {
    order: 12;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-lg-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-lg-first {
    order: -1;
  }

  .order-lg-last {
    order: 13;
  }

  .order-lg-0 {
    order: 0;
  }

  .order-lg-1 {
    order: 1;
  }

  .order-lg-2 {
    order: 2;
  }

  .order-lg-3 {
    order: 3;
  }

  .order-lg-4 {
    order: 4;
  }

  .order-lg-5 {
    order: 5;
  }

  .order-lg-6 {
    order: 6;
  }

  .order-lg-7 {
    order: 7;
  }

  .order-lg-8 {
    order: 8;
  }

  .order-lg-9 {
    order: 9;
  }

  .order-lg-10 {
    order: 10;
  }

  .order-lg-11 {
    order: 11;
  }

  .order-lg-12 {
    order: 12;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }

  .col-xl-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%;
  }

  .col-xl-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
  }

  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%;
  }

  .col-xl-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%;
  }

  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%;
  }

  .col-xl-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%;
  }

  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
  }

  .col-xl-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%;
  }

  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .order-xl-first {
    order: -1;
  }

  .order-xl-last {
    order: 13;
  }

  .order-xl-0 {
    order: 0;
  }

  .order-xl-1 {
    order: 1;
  }

  .order-xl-2 {
    order: 2;
  }

  .order-xl-3 {
    order: 3;
  }

  .order-xl-4 {
    order: 4;
  }

  .order-xl-5 {
    order: 5;
  }

  .order-xl-6 {
    order: 6;
  }

  .order-xl-7 {
    order: 7;
  }

  .order-xl-8 {
    order: 8;
  }

  .order-xl-9 {
    order: 9;
  }

  .order-xl-10 {
    order: 10;
  }

  .order-xl-11 {
    order: 11;
  }

  .order-xl-12 {
    order: 12;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }
}
.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}
.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.table tbody + tbody {
  border-top: 2px solid rgba(0, 0, 0, 0.1);
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.table-bordered th,
.table-bordered td {
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d1ebdb;
}
.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #a9d9bd;
}

.table-hover .table-primary:hover {
  background-color: #bfe3cd;
}
.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #bfe3cd;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #e6e6e6;
}
.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #d1d1d1;
}

.table-hover .table-secondary:hover {
  background-color: #d9d9d9;
}
.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #d9d9d9;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}
.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}
.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}
.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}
.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}
.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}
.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}
.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}
.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe;
}
.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc;
}

.table-hover .table-light:hover {
  background-color: #ececf6;
}
.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececf6;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #cdcdcd;
}
.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #a2a2a2;
}

.table-hover .table-dark:hover {
  background-color: silver;
}
.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: silver;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);
}
.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55;
}
.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: rgba(0, 0, 0, 0.1);
}

.table-dark {
  color: #fff;
  background-color: #343a40;
}
.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #454d55;
}
.table-dark.table-bordered {
  border: 0;
}
.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(255, 255, 255, 0.05);
}
.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #b2ddc4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #e9ecef;
  opacity: 1;
}

input[type=date].form-control,
input[type=time].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control {
  appearance: none;
}

select.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}
select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}
.form-row > .col,
.form-row > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  color: #6c757d;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}
.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .valid-tooltip, .form-row > [class*=col-] > .valid-tooltip {
  left: 5px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
}
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}
.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}
.form-row > .col > .invalid-tooltip, .form-row > [class*=col-] > .invalid-tooltip {
  left: 5px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}
.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.form-inline .form-check {
  width: 100%;
}
@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
.form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
  text-decoration: none;
}
.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.btn.disabled, .btn:disabled {
  opacity: 0.65;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary {
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.btn-primary:hover {
  color: #fff;
  background-color: #47a26d;
  border-color: #439967;
}
.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #47a26d;
  border-color: #439967;
  box-shadow: 0 0 0 0.2rem rgba(114, 193, 147, 0.5);
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #439967;
  border-color: #3f9061;
}
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(114, 193, 147, 0.5);
}

.btn-secondary {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #939393;
  border-color: #8d8d8d;
}
.btn-secondary:focus, .btn-secondary.focus {
  color: #fff;
  background-color: #939393;
  border-color: #8d8d8d;
  box-shadow: 0 0 0 0.2rem rgba(146, 147, 147, 0.5);
}
.btn-secondary.disabled, .btn-secondary:disabled {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #8d8d8d;
  border-color: #868686;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(146, 147, 147, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}
.btn-success:focus, .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}
.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}
.btn-info:focus, .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}
.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
}

.btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}
.btn-warning:focus, .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}
.btn-warning.disabled, .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active, .show > .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}
.btn-danger:focus, .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}
.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}
.btn-light:focus, .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}
.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active, .show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:hover {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
}
.btn-dark:focus, .btn-dark.focus {
  color: #fff;
  background-color: #3a3a3a;
  border-color: #343434;
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}
.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #343434;
  border-color: #2d2d2d;
}
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(104, 104, 104, 0.5);
}

.btn-outline-primary {
  color: #59b680;
  border-color: #59b680;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.5);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #59b680;
  background-color: transparent;
}
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.5);
}

.btn-outline-secondary {
  color: #a6a6a6;
  border-color: #a6a6a6;
}
.btn-outline-secondary:hover {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #a6a6a6;
  background-color: transparent;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  color: #212529;
  background-color: #a6a6a6;
  border-color: #a6a6a6;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}
.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active, .show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}
.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active, .show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: transparent;
}
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active, .show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #4d4d4d;
  background-color: transparent;
}
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #4d4d4d;
  border-color: #4d4d4d;
}
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.btn-link {
  font-weight: 400;
  color: #59b680;
  text-decoration: none;
}
.btn-link:hover {
  color: #3b875b;
  text-decoration: underline;
}
.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}
.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type=submit].btn-block,
input[type=reset].btn-block,
input[type=button].btn-block {
  width: 100%;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropleft .dropdown-toggle::after {
  display: none;
}
.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #16181b;
  text-decoration: none;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #59b680;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
  z-index: 1;
}
.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropright .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropleft .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0;
}
.btn-group-toggle > .btn input[type=radio],
.btn-group-toggle > .btn input[type=checkbox],
.btn-group-toggle > .btn-group > .btn input[type=radio],
.btn-group-toggle > .btn-group > .btn input[type=checkbox] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-control-plaintext,
.input-group > .custom-select,
.input-group > .custom-file {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  margin-bottom: 0;
}
.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .form-control-plaintext + .form-control,
.input-group > .form-control-plaintext + .custom-select,
.input-group > .form-control-plaintext + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
  margin-left: -1px;
}
.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
  z-index: 3;
}
.input-group > .custom-file .custom-file-input:focus {
  z-index: 4;
}
.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .custom-file {
  display: flex;
  align-items: center;
}
.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > .custom-file:not(:first-child) .custom-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group:not(.has-validation) > .form-control:not(:last-child),
.input-group:not(.has-validation) > .custom-select:not(:last-child),
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label,
.input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > .form-control:nth-last-child(n+3),
.input-group.has-validation > .custom-select:nth-last-child(n+3),
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label,
.input-group.has-validation > .custom-file:nth-last-child(n+3) .custom-file-label::after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-prepend,
.input-group-append {
  display: flex;
}
.input-group-prepend .btn,
.input-group-append .btn {
  position: relative;
  z-index: 2;
}
.input-group-prepend .btn:focus,
.input-group-append .btn:focus {
  z-index: 3;
}
.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
  margin-left: -1px;
}

.input-group-prepend {
  margin-right: -1px;
}

.input-group-append {
  margin-left: -1px;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.input-group-text input[type=radio],
.input-group-text input[type=checkbox] {
  margin-top: 0;
}

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px);
}

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px);
}

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n+3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.custom-control {
  position: relative;
  z-index: 1;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
  print-color-adjust: exact;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.25rem;
  opacity: 0;
}
.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #59b680;
  background-color: #59b680;
}
.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #b2ddc4;
}
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #d5eddf;
  border-color: #d5eddf;
}
.custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
  color: #6c757d;
}
.custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #e9ecef;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}
.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: 1px solid #adb5bd;
}
.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: 50%/50% 50% no-repeat;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #59b680;
  background-color: #59b680;
}
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(89, 182, 128, 0.5);
}
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(89, 182, 128, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(89, 182, 128, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}
.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 2px);
  left: calc(-2.25rem + 2px);
  width: calc(1rem - 4px);
  height: calc(1rem - 4px);
  background-color: #adb5bd;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}
.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(89, 182, 128, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.custom-select:focus {
  border-color: #b2ddc4;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-select:focus::-ms-value {
  color: #495057;
  background-color: #fff;
}
.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 0.75rem;
  background-image: none;
}
.custom-select:disabled {
  color: #6c757d;
  background-color: #e9ecef;
}
.custom-select::-ms-expand {
  display: none;
}
.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #495057;
}

.custom-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  overflow: hidden;
  opacity: 0;
}
.custom-file-input:focus ~ .custom-file-label {
  border-color: #b2ddc4;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-file-input[disabled] ~ .custom-file-label, .custom-file-input:disabled ~ .custom-file-label {
  background-color: #e9ecef;
}
.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}
.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + 0.75rem);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.custom-range:focus {
  outline: 0;
}
.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}
.custom-range::-moz-focus-outer {
  border: 0;
}
.custom-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #59b680;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}
.custom-range::-webkit-slider-thumb:active {
  background-color: #d5eddf;
}
.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #59b680;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}
.custom-range::-moz-range-thumb:active {
  background-color: #d5eddf;
}
.custom-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.custom-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #59b680;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}
.custom-range::-ms-thumb:active {
  background-color: #d5eddf;
}
.custom-range::-ms-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.5rem;
}
.custom-range::-ms-fill-lower {
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #dee2e6;
  border-radius: 1rem;
}
.custom-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}
.custom-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}
.custom-range:disabled::-moz-range-track {
  cursor: default;
}
.custom-range:disabled::-ms-thumb {
  background-color: #adb5bd;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
.custom-file-label,
.custom-select {
    transition: none;
  }
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}
.nav-link:hover, .nav-link:focus {
  text-decoration: none;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: #e9ecef #e9ecef #dee2e6;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #59b680;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar .container,
.navbar .container-fluid,
.navbar .container-sm,
.navbar .container-md,
.navbar .container-lg,
.navbar .container-xl {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:hover, .navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm > .container,
.navbar-expand-sm > .container-fluid,
.navbar-expand-sm > .container-sm,
.navbar-expand-sm > .container-md,
.navbar-expand-sm > .container-lg,
.navbar-expand-sm > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md > .container,
.navbar-expand-md > .container-fluid,
.navbar-expand-md > .container-sm,
.navbar-expand-md > .container-md,
.navbar-expand-md > .container-lg,
.navbar-expand-md > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg > .container,
.navbar-expand-lg > .container-fluid,
.navbar-expand-lg > .container-sm,
.navbar-expand-lg > .container-md,
.navbar-expand-lg > .container-lg,
.navbar-expand-lg > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl > .container,
.navbar-expand-xl > .container-fluid,
.navbar-expand-xl > .container-sm,
.navbar-expand-xl > .container-md,
.navbar-expand-xl > .container-lg,
.navbar-expand-xl > .container-xl {
    flex-wrap: nowrap;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  padding-right: 0;
  padding-left: 0;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand > .container,
.navbar-expand > .container-fluid,
.navbar-expand > .container-sm,
.navbar-expand > .container-md,
.navbar-expand > .container-lg,
.navbar-expand > .container-xl {
  flex-wrap: nowrap;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}
.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}
.navbar-dark .navbar-text a {
  color: #fff;
}
.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-deck .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .card-deck .card {
    flex: 1 0 0%;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }
}

.card-group > .card {
  margin-bottom: 15px;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.card-columns .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }
  .card-columns .card {
    display: inline-block;
    width: 100%;
  }
}

.accordion {
  overflow-anchor: none;
}
.accordion > .card {
  overflow: hidden;
}
.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}
.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #59b680;
  background-color: #fff;
  border: 1px solid #dee2e6;
}
.page-link:hover {
  z-index: 2;
  color: #3b875b;
  text-decoration: none;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.25);
}

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .badge {
    transition: none;
  }
}
a.badge:hover, a.badge:focus {
  text-decoration: none;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
}

.badge-primary {
  color: #fff;
  background-color: #59b680;
}
a.badge-primary:hover, a.badge-primary:focus {
  color: #fff;
  background-color: #439967;
}
a.badge-primary:focus, a.badge-primary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(89, 182, 128, 0.5);
}

.badge-secondary {
  color: #212529;
  background-color: #a6a6a6;
}
a.badge-secondary:hover, a.badge-secondary:focus {
  color: #212529;
  background-color: #8d8d8d;
}
a.badge-secondary:focus, a.badge-secondary.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(166, 166, 166, 0.5);
}

.badge-success {
  color: #fff;
  background-color: #28a745;
}
a.badge-success:hover, a.badge-success:focus {
  color: #fff;
  background-color: #1e7e34;
}
a.badge-success:focus, a.badge-success.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.badge-info {
  color: #fff;
  background-color: #17a2b8;
}
a.badge-info:hover, a.badge-info:focus {
  color: #fff;
  background-color: #117a8b;
}
a.badge-info:focus, a.badge-info.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.badge-warning {
  color: #212529;
  background-color: #ffc107;
}
a.badge-warning:hover, a.badge-warning:focus {
  color: #212529;
  background-color: #d39e00;
}
a.badge-warning:focus, a.badge-warning.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

.badge-danger {
  color: #fff;
  background-color: #dc3545;
}
a.badge-danger:hover, a.badge-danger:focus {
  color: #fff;
  background-color: #bd2130;
}
a.badge-danger:focus, a.badge-danger.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.5);
}

.badge-light {
  color: #212529;
  background-color: #f8f9fa;
}
a.badge-light:hover, a.badge-light:focus {
  color: #212529;
  background-color: #dae0e5;
}
a.badge-light:focus, a.badge-light.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.badge-dark {
  color: #fff;
  background-color: #4d4d4d;
}
a.badge-dark:hover, a.badge-dark:focus {
  color: #fff;
  background-color: #343434;
}
a.badge-dark:focus, a.badge-dark.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(77, 77, 77, 0.5);
}

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #e9ecef;
  border-radius: 0.3rem;
}
@media (min-width: 576px) {
  .jumbotron {
    padding: 4rem 2rem;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 4rem;
}
.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 0.75rem 1.25rem;
  color: inherit;
}

.alert-primary {
  color: #2e5f43;
  background-color: #def0e6;
  border-color: #d1ebdb;
}
.alert-primary hr {
  border-top-color: #bfe3cd;
}
.alert-primary .alert-link {
  color: #1d3d2b;
}

.alert-secondary {
  color: #565656;
  background-color: #ededed;
  border-color: #e6e6e6;
}
.alert-secondary hr {
  border-top-color: #d9d9d9;
}
.alert-secondary .alert-link {
  color: #3d3d3d;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-success hr {
  border-top-color: #b1dfbb;
}
.alert-success .alert-link {
  color: #0b2e13;
}

.alert-info {
  color: #0c5460;
  background-color: #d1ecf1;
  border-color: #bee5eb;
}
.alert-info hr {
  border-top-color: #abdde5;
}
.alert-info .alert-link {
  color: #062c33;
}

.alert-warning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
}
.alert-warning hr {
  border-top-color: #ffe8a1;
}
.alert-warning .alert-link {
  color: #533f03;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
.alert-danger hr {
  border-top-color: #f1b0b7;
}
.alert-danger .alert-link {
  color: #491217;
}

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light hr {
  border-top-color: #ececf6;
}
.alert-light .alert-link {
  color: #686868;
}

.alert-dark {
  color: #282828;
  background-color: #dbdbdb;
  border-color: #cdcdcd;
}
.alert-dark hr {
  border-top-color: silver;
}
.alert-dark .alert-link {
  color: #0f0f0f;
}

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0;
  }
  to {
    background-position: 0 0;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #59b680;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #59b680;
  border-color: #59b680;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #2e5f43;
  background-color: #d1ebdb;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #2e5f43;
  background-color: #bfe3cd;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #2e5f43;
  border-color: #2e5f43;
}

.list-group-item-secondary {
  color: #565656;
  background-color: #e6e6e6;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #565656;
  background-color: #d9d9d9;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #565656;
  border-color: #565656;
}

.list-group-item-success {
  color: #155724;
  background-color: #c3e6cb;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #155724;
  background-color: #b1dfbb;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #155724;
  border-color: #155724;
}

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #0c5460;
  background-color: #abdde5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #0c5460;
  border-color: #0c5460;
}

.list-group-item-warning {
  color: #856404;
  background-color: #ffeeba;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #856404;
  background-color: #ffe8a1;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #856404;
  border-color: #856404;
}

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #721c24;
  background-color: #f1b0b7;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #721c24;
  border-color: #721c24;
}

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #818182;
  background-color: #ececf6;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #818182;
  border-color: #818182;
}

.list-group-item-dark {
  color: #282828;
  background-color: #cdcdcd;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #282828;
  background-color: silver;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #282828;
  border-color: #282828;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}
.close:hover {
  color: #000;
  text-decoration: none;
}
.close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
  opacity: 0.75;
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
}

a.close.disabled {
  pointer-events: none;
}

.toast {
  flex-basis: 350px;
  max-width: 350px;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  opacity: 0;
  border-radius: 0.25rem;
}
.toast:not(:last-child) {
  margin-bottom: 0.75rem;
}
.toast.showing {
  opacity: 1;
}
.toast.show {
  display: block;
  opacity: 1;
}
.toast.hide {
  display: none;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.toast-body {
  padding: 0.75rem;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  height: min-content;
  content: "";
}
.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .close {
  padding: 1rem 1rem;
  margin: -1rem -1rem -1rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
    height: min-content;
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^=top] .arrow {
  bottom: 0;
}
.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^=top] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^=right] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^=right] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^=bottom] .arrow {
  top: 0;
}
.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^=bottom] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^=left] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^=left] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
  margin: 0 0.3rem;
}
.popover .arrow::before, .popover .arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top, .bs-popover-auto[x-placement^=top] {
  margin-bottom: 0.5rem;
}
.bs-popover-top > .arrow, .bs-popover-auto[x-placement^=top] > .arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^=top] > .arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^=top] > .arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-right, .bs-popover-auto[x-placement^=right] {
  margin-left: 0.5rem;
}
.bs-popover-right > .arrow, .bs-popover-auto[x-placement^=right] > .arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^=right] > .arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^=right] > .arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom, .bs-popover-auto[x-placement^=bottom] {
  margin-top: 0.5rem;
}
.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^=bottom] > .arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f7f7f7;
}

.bs-popover-left, .bs-popover-auto[x-placement^=left] {
  margin-right: 0.5rem;
}
.bs-popover-left > .arrow, .bs-popover-auto[x-placement^=left] > .arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
  margin: 0.3rem 0;
}
.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^=left] > .arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^=left] > .arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: 50%/100% 100% no-repeat;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentcolor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentcolor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #59b680 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #439967 !important;
}

.bg-secondary {
  background-color: #a6a6a6 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #8d8d8d !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #4d4d4d !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #343434 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-right-0 {
  border-right: 0 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-left-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #59b680 !important;
}

.border-secondary {
  border-color: #a6a6a6 !important;
}

.border-success {
  border-color: #28a745 !important;
}

.border-info {
  border-color: #17a2b8 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #4d4d4d !important;
}

.border-white {
  border-color: #fff !important;
}

.rounded-sm {
  border-radius: 0.2rem !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-lg {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }

  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.85714286%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }

  .float-sm-right {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }
}
@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }

  .float-md-right {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }
}
@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }

  .float-lg-right {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }

  .float-xl-right {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }
}
.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }

  .mt-sm-0,
.my-sm-0 {
    margin-top: 0 !important;
  }

  .mr-sm-0,
.mx-sm-0 {
    margin-right: 0 !important;
  }

  .mb-sm-0,
.my-sm-0 {
    margin-bottom: 0 !important;
  }

  .ml-sm-0,
.mx-sm-0 {
    margin-left: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .mt-sm-1,
.my-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mr-sm-1,
.mx-sm-1 {
    margin-right: 0.25rem !important;
  }

  .mb-sm-1,
.my-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-sm-1,
.mx-sm-1 {
    margin-left: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .mt-sm-2,
.my-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mr-sm-2,
.mx-sm-2 {
    margin-right: 0.5rem !important;
  }

  .mb-sm-2,
.my-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-sm-2,
.mx-sm-2 {
    margin-left: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .mt-sm-3,
.my-sm-3 {
    margin-top: 1rem !important;
  }

  .mr-sm-3,
.mx-sm-3 {
    margin-right: 1rem !important;
  }

  .mb-sm-3,
.my-sm-3 {
    margin-bottom: 1rem !important;
  }

  .ml-sm-3,
.mx-sm-3 {
    margin-left: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .mt-sm-4,
.my-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mr-sm-4,
.mx-sm-4 {
    margin-right: 1.5rem !important;
  }

  .mb-sm-4,
.my-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-sm-4,
.mx-sm-4 {
    margin-left: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .mt-sm-5,
.my-sm-5 {
    margin-top: 3rem !important;
  }

  .mr-sm-5,
.mx-sm-5 {
    margin-right: 3rem !important;
  }

  .mb-sm-5,
.my-sm-5 {
    margin-bottom: 3rem !important;
  }

  .ml-sm-5,
.mx-sm-5 {
    margin-left: 3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .pt-sm-0,
.py-sm-0 {
    padding-top: 0 !important;
  }

  .pr-sm-0,
.px-sm-0 {
    padding-right: 0 !important;
  }

  .pb-sm-0,
.py-sm-0 {
    padding-bottom: 0 !important;
  }

  .pl-sm-0,
.px-sm-0 {
    padding-left: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .pt-sm-1,
.py-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pr-sm-1,
.px-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pb-sm-1,
.py-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-sm-1,
.px-sm-1 {
    padding-left: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .pt-sm-2,
.py-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pr-sm-2,
.px-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pb-sm-2,
.py-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-sm-2,
.px-sm-2 {
    padding-left: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .pt-sm-3,
.py-sm-3 {
    padding-top: 1rem !important;
  }

  .pr-sm-3,
.px-sm-3 {
    padding-right: 1rem !important;
  }

  .pb-sm-3,
.py-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pl-sm-3,
.px-sm-3 {
    padding-left: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .pt-sm-4,
.py-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pr-sm-4,
.px-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pb-sm-4,
.py-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-sm-4,
.px-sm-4 {
    padding-left: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .pt-sm-5,
.py-sm-5 {
    padding-top: 3rem !important;
  }

  .pr-sm-5,
.px-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-5,
.py-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pl-sm-5,
.px-sm-5 {
    padding-left: 3rem !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .mt-sm-n1,
.my-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-sm-n1,
.mx-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-sm-n1,
.my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-sm-n1,
.mx-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .mt-sm-n2,
.my-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-sm-n2,
.mx-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-sm-n2,
.my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-sm-n2,
.mx-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .mt-sm-n3,
.my-sm-n3 {
    margin-top: -1rem !important;
  }

  .mr-sm-n3,
.mx-sm-n3 {
    margin-right: -1rem !important;
  }

  .mb-sm-n3,
.my-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-sm-n3,
.mx-sm-n3 {
    margin-left: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .mt-sm-n4,
.my-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-sm-n4,
.mx-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-sm-n4,
.my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-sm-n4,
.mx-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mt-sm-n5,
.my-sm-n5 {
    margin-top: -3rem !important;
  }

  .mr-sm-n5,
.mx-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n5,
.my-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-sm-n5,
.mx-sm-n5 {
    margin-left: -3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mt-sm-auto,
.my-sm-auto {
    margin-top: auto !important;
  }

  .mr-sm-auto,
.mx-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-auto,
.my-sm-auto {
    margin-bottom: auto !important;
  }

  .ml-sm-auto,
.mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }

  .mt-md-0,
.my-md-0 {
    margin-top: 0 !important;
  }

  .mr-md-0,
.mx-md-0 {
    margin-right: 0 !important;
  }

  .mb-md-0,
.my-md-0 {
    margin-bottom: 0 !important;
  }

  .ml-md-0,
.mx-md-0 {
    margin-left: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .mt-md-1,
.my-md-1 {
    margin-top: 0.25rem !important;
  }

  .mr-md-1,
.mx-md-1 {
    margin-right: 0.25rem !important;
  }

  .mb-md-1,
.my-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-md-1,
.mx-md-1 {
    margin-left: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .mt-md-2,
.my-md-2 {
    margin-top: 0.5rem !important;
  }

  .mr-md-2,
.mx-md-2 {
    margin-right: 0.5rem !important;
  }

  .mb-md-2,
.my-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-md-2,
.mx-md-2 {
    margin-left: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .mt-md-3,
.my-md-3 {
    margin-top: 1rem !important;
  }

  .mr-md-3,
.mx-md-3 {
    margin-right: 1rem !important;
  }

  .mb-md-3,
.my-md-3 {
    margin-bottom: 1rem !important;
  }

  .ml-md-3,
.mx-md-3 {
    margin-left: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .mt-md-4,
.my-md-4 {
    margin-top: 1.5rem !important;
  }

  .mr-md-4,
.mx-md-4 {
    margin-right: 1.5rem !important;
  }

  .mb-md-4,
.my-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-md-4,
.mx-md-4 {
    margin-left: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .mt-md-5,
.my-md-5 {
    margin-top: 3rem !important;
  }

  .mr-md-5,
.mx-md-5 {
    margin-right: 3rem !important;
  }

  .mb-md-5,
.my-md-5 {
    margin-bottom: 3rem !important;
  }

  .ml-md-5,
.mx-md-5 {
    margin-left: 3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .pt-md-0,
.py-md-0 {
    padding-top: 0 !important;
  }

  .pr-md-0,
.px-md-0 {
    padding-right: 0 !important;
  }

  .pb-md-0,
.py-md-0 {
    padding-bottom: 0 !important;
  }

  .pl-md-0,
.px-md-0 {
    padding-left: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .pt-md-1,
.py-md-1 {
    padding-top: 0.25rem !important;
  }

  .pr-md-1,
.px-md-1 {
    padding-right: 0.25rem !important;
  }

  .pb-md-1,
.py-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-md-1,
.px-md-1 {
    padding-left: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .pt-md-2,
.py-md-2 {
    padding-top: 0.5rem !important;
  }

  .pr-md-2,
.px-md-2 {
    padding-right: 0.5rem !important;
  }

  .pb-md-2,
.py-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-md-2,
.px-md-2 {
    padding-left: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .pt-md-3,
.py-md-3 {
    padding-top: 1rem !important;
  }

  .pr-md-3,
.px-md-3 {
    padding-right: 1rem !important;
  }

  .pb-md-3,
.py-md-3 {
    padding-bottom: 1rem !important;
  }

  .pl-md-3,
.px-md-3 {
    padding-left: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .pt-md-4,
.py-md-4 {
    padding-top: 1.5rem !important;
  }

  .pr-md-4,
.px-md-4 {
    padding-right: 1.5rem !important;
  }

  .pb-md-4,
.py-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-md-4,
.px-md-4 {
    padding-left: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .pt-md-5,
.py-md-5 {
    padding-top: 3rem !important;
  }

  .pr-md-5,
.px-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-5,
.py-md-5 {
    padding-bottom: 3rem !important;
  }

  .pl-md-5,
.px-md-5 {
    padding-left: 3rem !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .mt-md-n1,
.my-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-md-n1,
.mx-md-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-md-n1,
.my-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-md-n1,
.mx-md-n1 {
    margin-left: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .mt-md-n2,
.my-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-md-n2,
.mx-md-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-md-n2,
.my-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-md-n2,
.mx-md-n2 {
    margin-left: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .mt-md-n3,
.my-md-n3 {
    margin-top: -1rem !important;
  }

  .mr-md-n3,
.mx-md-n3 {
    margin-right: -1rem !important;
  }

  .mb-md-n3,
.my-md-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-md-n3,
.mx-md-n3 {
    margin-left: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .mt-md-n4,
.my-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-md-n4,
.mx-md-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-md-n4,
.my-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-md-n4,
.mx-md-n4 {
    margin-left: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mt-md-n5,
.my-md-n5 {
    margin-top: -3rem !important;
  }

  .mr-md-n5,
.mx-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n5,
.my-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-md-n5,
.mx-md-n5 {
    margin-left: -3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mt-md-auto,
.my-md-auto {
    margin-top: auto !important;
  }

  .mr-md-auto,
.mx-md-auto {
    margin-right: auto !important;
  }

  .mb-md-auto,
.my-md-auto {
    margin-bottom: auto !important;
  }

  .ml-md-auto,
.mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }

  .mt-lg-0,
.my-lg-0 {
    margin-top: 0 !important;
  }

  .mr-lg-0,
.mx-lg-0 {
    margin-right: 0 !important;
  }

  .mb-lg-0,
.my-lg-0 {
    margin-bottom: 0 !important;
  }

  .ml-lg-0,
.mx-lg-0 {
    margin-left: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .mt-lg-1,
.my-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mr-lg-1,
.mx-lg-1 {
    margin-right: 0.25rem !important;
  }

  .mb-lg-1,
.my-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-lg-1,
.mx-lg-1 {
    margin-left: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .mt-lg-2,
.my-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mr-lg-2,
.mx-lg-2 {
    margin-right: 0.5rem !important;
  }

  .mb-lg-2,
.my-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-lg-2,
.mx-lg-2 {
    margin-left: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .mt-lg-3,
.my-lg-3 {
    margin-top: 1rem !important;
  }

  .mr-lg-3,
.mx-lg-3 {
    margin-right: 1rem !important;
  }

  .mb-lg-3,
.my-lg-3 {
    margin-bottom: 1rem !important;
  }

  .ml-lg-3,
.mx-lg-3 {
    margin-left: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .mt-lg-4,
.my-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mr-lg-4,
.mx-lg-4 {
    margin-right: 1.5rem !important;
  }

  .mb-lg-4,
.my-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-lg-4,
.mx-lg-4 {
    margin-left: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .mt-lg-5,
.my-lg-5 {
    margin-top: 3rem !important;
  }

  .mr-lg-5,
.mx-lg-5 {
    margin-right: 3rem !important;
  }

  .mb-lg-5,
.my-lg-5 {
    margin-bottom: 3rem !important;
  }

  .ml-lg-5,
.mx-lg-5 {
    margin-left: 3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .pt-lg-0,
.py-lg-0 {
    padding-top: 0 !important;
  }

  .pr-lg-0,
.px-lg-0 {
    padding-right: 0 !important;
  }

  .pb-lg-0,
.py-lg-0 {
    padding-bottom: 0 !important;
  }

  .pl-lg-0,
.px-lg-0 {
    padding-left: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .pt-lg-1,
.py-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pr-lg-1,
.px-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pb-lg-1,
.py-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-lg-1,
.px-lg-1 {
    padding-left: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .pt-lg-2,
.py-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pr-lg-2,
.px-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pb-lg-2,
.py-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-lg-2,
.px-lg-2 {
    padding-left: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .pt-lg-3,
.py-lg-3 {
    padding-top: 1rem !important;
  }

  .pr-lg-3,
.px-lg-3 {
    padding-right: 1rem !important;
  }

  .pb-lg-3,
.py-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pl-lg-3,
.px-lg-3 {
    padding-left: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .pt-lg-4,
.py-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pr-lg-4,
.px-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pb-lg-4,
.py-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-lg-4,
.px-lg-4 {
    padding-left: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .pt-lg-5,
.py-lg-5 {
    padding-top: 3rem !important;
  }

  .pr-lg-5,
.px-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-5,
.py-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pl-lg-5,
.px-lg-5 {
    padding-left: 3rem !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .mt-lg-n1,
.my-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-lg-n1,
.mx-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-lg-n1,
.my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-lg-n1,
.mx-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .mt-lg-n2,
.my-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-lg-n2,
.mx-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-lg-n2,
.my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-lg-n2,
.mx-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .mt-lg-n3,
.my-lg-n3 {
    margin-top: -1rem !important;
  }

  .mr-lg-n3,
.mx-lg-n3 {
    margin-right: -1rem !important;
  }

  .mb-lg-n3,
.my-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-lg-n3,
.mx-lg-n3 {
    margin-left: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .mt-lg-n4,
.my-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-lg-n4,
.mx-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-lg-n4,
.my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-lg-n4,
.mx-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mt-lg-n5,
.my-lg-n5 {
    margin-top: -3rem !important;
  }

  .mr-lg-n5,
.mx-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n5,
.my-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-lg-n5,
.mx-lg-n5 {
    margin-left: -3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mt-lg-auto,
.my-lg-auto {
    margin-top: auto !important;
  }

  .mr-lg-auto,
.mx-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-auto,
.my-lg-auto {
    margin-bottom: auto !important;
  }

  .ml-lg-auto,
.mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }

  .mt-xl-0,
.my-xl-0 {
    margin-top: 0 !important;
  }

  .mr-xl-0,
.mx-xl-0 {
    margin-right: 0 !important;
  }

  .mb-xl-0,
.my-xl-0 {
    margin-bottom: 0 !important;
  }

  .ml-xl-0,
.mx-xl-0 {
    margin-left: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .mt-xl-1,
.my-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mr-xl-1,
.mx-xl-1 {
    margin-right: 0.25rem !important;
  }

  .mb-xl-1,
.my-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .ml-xl-1,
.mx-xl-1 {
    margin-left: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .mt-xl-2,
.my-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mr-xl-2,
.mx-xl-2 {
    margin-right: 0.5rem !important;
  }

  .mb-xl-2,
.my-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .ml-xl-2,
.mx-xl-2 {
    margin-left: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .mt-xl-3,
.my-xl-3 {
    margin-top: 1rem !important;
  }

  .mr-xl-3,
.mx-xl-3 {
    margin-right: 1rem !important;
  }

  .mb-xl-3,
.my-xl-3 {
    margin-bottom: 1rem !important;
  }

  .ml-xl-3,
.mx-xl-3 {
    margin-left: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .mt-xl-4,
.my-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mr-xl-4,
.mx-xl-4 {
    margin-right: 1.5rem !important;
  }

  .mb-xl-4,
.my-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .ml-xl-4,
.mx-xl-4 {
    margin-left: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .mt-xl-5,
.my-xl-5 {
    margin-top: 3rem !important;
  }

  .mr-xl-5,
.mx-xl-5 {
    margin-right: 3rem !important;
  }

  .mb-xl-5,
.my-xl-5 {
    margin-bottom: 3rem !important;
  }

  .ml-xl-5,
.mx-xl-5 {
    margin-left: 3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .pt-xl-0,
.py-xl-0 {
    padding-top: 0 !important;
  }

  .pr-xl-0,
.px-xl-0 {
    padding-right: 0 !important;
  }

  .pb-xl-0,
.py-xl-0 {
    padding-bottom: 0 !important;
  }

  .pl-xl-0,
.px-xl-0 {
    padding-left: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .pt-xl-1,
.py-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pr-xl-1,
.px-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pb-xl-1,
.py-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pl-xl-1,
.px-xl-1 {
    padding-left: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .pt-xl-2,
.py-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pr-xl-2,
.px-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pb-xl-2,
.py-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pl-xl-2,
.px-xl-2 {
    padding-left: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .pt-xl-3,
.py-xl-3 {
    padding-top: 1rem !important;
  }

  .pr-xl-3,
.px-xl-3 {
    padding-right: 1rem !important;
  }

  .pb-xl-3,
.py-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pl-xl-3,
.px-xl-3 {
    padding-left: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .pt-xl-4,
.py-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pr-xl-4,
.px-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pb-xl-4,
.py-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pl-xl-4,
.px-xl-4 {
    padding-left: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .pt-xl-5,
.py-xl-5 {
    padding-top: 3rem !important;
  }

  .pr-xl-5,
.px-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-5,
.py-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pl-xl-5,
.px-xl-5 {
    padding-left: 3rem !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .mt-xl-n1,
.my-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mr-xl-n1,
.mx-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .mb-xl-n1,
.my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .ml-xl-n1,
.mx-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .mt-xl-n2,
.my-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mr-xl-n2,
.mx-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .mb-xl-n2,
.my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .ml-xl-n2,
.mx-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .mt-xl-n3,
.my-xl-n3 {
    margin-top: -1rem !important;
  }

  .mr-xl-n3,
.mx-xl-n3 {
    margin-right: -1rem !important;
  }

  .mb-xl-n3,
.my-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .ml-xl-n3,
.mx-xl-n3 {
    margin-left: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .mt-xl-n4,
.my-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mr-xl-n4,
.mx-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .mb-xl-n4,
.my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .ml-xl-n4,
.mx-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mt-xl-n5,
.my-xl-n5 {
    margin-top: -3rem !important;
  }

  .mr-xl-n5,
.mx-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n5,
.my-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ml-xl-n5,
.mx-xl-n5 {
    margin-left: -3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mt-xl-auto,
.my-xl-auto {
    margin-top: auto !important;
  }

  .mr-xl-auto,
.mx-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-auto,
.my-xl-auto {
    margin-bottom: auto !important;
  }

  .ml-xl-auto,
.mx-xl-auto {
    margin-left: auto !important;
  }
}
.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }

  .text-sm-right {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }

  .text-md-right {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }

  .text-xl-right {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #59b680 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #3b875b !important;
}

.text-secondary {
  color: #a6a6a6 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: gray !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important;
}

.text-dark {
  color: #4d4d4d !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #272727 !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media print {
  *,
*::before,
*::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }

  a:not(.btn) {
    text-decoration: underline;
  }

  abbr[title]::after {
    content: " (" attr(title) ")";
  }

  pre {
    white-space: pre-wrap !important;
  }

  pre,
blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }

  tr,
img {
    page-break-inside: avoid;
  }

  p,
h2,
h3 {
    orphans: 3;
    widows: 3;
  }

  h2,
h3 {
    page-break-after: avoid;
  }

  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }

  .container {
    min-width: 992px !important;
  }

  .navbar {
    display: none;
  }

  .badge {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }
  .table td,
.table th {
    background-color: #fff !important;
  }

  .table-bordered th,
.table-bordered td {
    border: 1px solid #dee2e6 !important;
  }

  .table-dark {
    color: inherit;
  }
  .table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
    border-color: rgba(0, 0, 0, 0.1);
  }

  .table .thead-dark th {
    color: inherit;
    border-color: rgba(0, 0, 0, 0.1);
  }
}
.emoji-mart,
.emoji-mart * {
  box-sizing: border-box;
  line-height: 1.15;
}

.emoji-mart {
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", sans-serif;
  font-size: 16px;
  display: inline-block;
  color: #222427;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: #fff;
}

.emoji-mart .emoji-mart-emoji {
  padding: 6px;
}

.emoji-mart-bar {
  border: 0 solid #d9d9d9;
}

.emoji-mart-bar:first-child {
  border-bottom-width: 1px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.emoji-mart-bar:last-child {
  border-top-width: 1px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.emoji-mart-anchors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 6px;
  line-height: 0;
}

.emoji-mart-anchor {
  position: relative;
  display: block;
  flex: 1 1 auto;
  color: #858585;
  text-align: center;
  padding: 12px 4px;
  overflow: hidden;
  transition: color 0.1s ease-out;
  margin: 0;
  box-shadow: none;
  background: none;
  border: none;
}

.emoji-mart-anchor:focus {
  outline: 0;
}

.emoji-mart-anchor:hover,
.emoji-mart-anchor:focus,
.emoji-mart-anchor-selected {
  color: #464646;
}

.emoji-mart-anchor-selected .emoji-mart-anchor-bar {
  bottom: 0;
}

.emoji-mart-anchor-bar {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #464646;
}

.emoji-mart-anchors i {
  display: inline-block;
  width: 100%;
  max-width: 22px;
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 18px;
}

.emoji-mart-scroll {
  overflow-y: scroll;
  height: 270px;
  padding: 0 6px 6px 6px;
  will-change: transform;
  /* avoids "repaints on scroll" in mobile Chrome */
}

.emoji-mart-search {
  margin-top: 6px;
  padding: 0 6px;
  position: relative;
}

.emoji-mart-search input {
  font-size: 16px;
  display: block;
  width: 100%;
  padding: 5px 25px 6px 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  outline: 0;
}

.emoji-mart-search input,
.emoji-mart-search input::-webkit-search-decoration,
.emoji-mart-search input::-webkit-search-cancel-button,
.emoji-mart-search input::-webkit-search-results-button,
.emoji-mart-search input::-webkit-search-results-decoration {
  /* remove webkit/blink styles for <input type="search">
   * via https://stackoverflow.com/a/9422689 */
  -webkit-appearance: none;
}

.emoji-mart-search-icon {
  position: absolute;
  top: 3px;
  right: 11px;
  z-index: 2;
  padding: 2px 5px 1px;
  border: none;
  background: none;
}

.emoji-mart-category .emoji-mart-emoji span {
  z-index: 1;
  position: relative;
  text-align: center;
  cursor: default;
}

.emoji-mart-category .emoji-mart-emoji:hover:before {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f4f4f4;
  border-radius: 100%;
}

.emoji-mart-category-label {
  z-index: 2;
  position: relative;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.emoji-mart-category-label span {
  display: block;
  width: 100%;
  font-weight: 500;
  padding: 5px 6px;
  background-color: #fff;
  background-color: rgba(255, 255, 255, 0.95);
}

.emoji-mart-category-list {
  margin: 0;
  padding: 0;
}

.emoji-mart-category-list li {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

.emoji-mart-emoji {
  position: relative;
  display: inline-block;
  font-size: 0;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  box-shadow: none;
}

.emoji-mart-emoji-native {
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "Android Emoji";
}

.emoji-mart-no-results {
  font-size: 14px;
  text-align: center;
  padding-top: 70px;
  color: #858585;
}

.emoji-mart-no-results .emoji-mart-category-label {
  display: none;
}

.emoji-mart-no-results .emoji-mart-no-results-label {
  margin-top: 0.2em;
}

.emoji-mart-no-results .emoji-mart-emoji:hover:before {
  content: none;
}

.emoji-mart-preview {
  position: relative;
  height: 70px;
}

.emoji-mart-preview-emoji,
.emoji-mart-preview-data,
.emoji-mart-preview-skins {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.emoji-mart-preview-emoji {
  left: 12px;
}

.emoji-mart-preview-data {
  left: 68px;
  right: 12px;
  word-break: break-all;
}

.emoji-mart-preview-skins {
  right: 30px;
  text-align: right;
}

.emoji-mart-preview-skins.custom {
  right: 10px;
  text-align: right;
}

.emoji-mart-preview-name {
  font-size: 14px;
}

.emoji-mart-preview-shortname {
  font-size: 12px;
  color: #888;
}

.emoji-mart-preview-shortname + .emoji-mart-preview-shortname,
.emoji-mart-preview-shortname + .emoji-mart-preview-emoticon,
.emoji-mart-preview-emoticon + .emoji-mart-preview-emoticon {
  margin-left: 0.5em;
}

.emoji-mart-preview-emoticon {
  font-size: 11px;
  color: #bbb;
}

.emoji-mart-title span {
  display: inline-block;
  vertical-align: middle;
}

.emoji-mart-title .emoji-mart-emoji {
  padding: 0;
}

.emoji-mart-title-label {
  color: #999A9C;
  font-size: 26px;
  font-weight: 300;
}

.emoji-mart-skin-swatches {
  font-size: 0;
  padding: 2px 0;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  background-color: #fff;
}

.emoji-mart-skin-swatches.custom {
  font-size: 0;
  border: none;
  background-color: #fff;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch {
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatches.opened .emoji-mart-skin-swatch.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-swatch {
  display: inline-block;
  width: 0;
  vertical-align: middle;
  transition-property: width, padding;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
}

.emoji-mart-skin-swatch:nth-child(1) {
  transition-delay: 0s;
}

.emoji-mart-skin-swatch:nth-child(2) {
  transition-delay: 0.03s;
}

.emoji-mart-skin-swatch:nth-child(3) {
  transition-delay: 0.06s;
}

.emoji-mart-skin-swatch:nth-child(4) {
  transition-delay: 0.09s;
}

.emoji-mart-skin-swatch:nth-child(5) {
  transition-delay: 0.12s;
}

.emoji-mart-skin-swatch:nth-child(6) {
  transition-delay: 0.15s;
}

.emoji-mart-skin-swatch.selected {
  position: relative;
  width: 16px;
  padding: 0 2px;
}

.emoji-mart-skin-swatch.selected:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  margin: -2px 0 0 -2px;
  background-color: #fff;
  border-radius: 100%;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-out;
}

.emoji-mart-skin-swatch.custom {
  display: inline-block;
  width: 0;
  height: 38px;
  overflow: hidden;
  vertical-align: middle;
  transition-property: width, height;
  transition-duration: 0.125s;
  transition-timing-function: ease-out;
  cursor: default;
}

.emoji-mart-skin-swatch.custom.selected {
  position: relative;
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatch.custom.selected:after {
  content: "";
  width: 0;
  height: 0;
}

.emoji-mart-skin-swatches.custom .emoji-mart-skin-swatch.custom:hover {
  background-color: #f4f4f4;
  border-radius: 10%;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom {
  width: 36px;
  height: 38px;
  padding: 0 2px 0 0;
}

.emoji-mart-skin-swatches.custom.opened .emoji-mart-skin-swatch.custom.selected:after {
  opacity: 0.75;
}

.emoji-mart-skin-text.opened {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  color: #888;
  font-size: 11px;
  padding: 5px 2px;
  width: 95px;
  height: 40px;
  border-radius: 10%;
  background-color: #fff;
}

.emoji-mart-skin {
  display: inline-block;
  width: 100%;
  padding-top: 100%;
  max-width: 12px;
  border-radius: 100%;
}

.emoji-mart-skin-tone-1 {
  background-color: #ffc93a;
}

.emoji-mart-skin-tone-2 {
  background-color: #fadcbc;
}

.emoji-mart-skin-tone-3 {
  background-color: #e0bb95;
}

.emoji-mart-skin-tone-4 {
  background-color: #bf8f68;
}

.emoji-mart-skin-tone-5 {
  background-color: #9b643d;
}

.emoji-mart-skin-tone-6 {
  background-color: #594539;
}

/* For screenreaders only, via https://stackoverflow.com/a/19758620 */
.emoji-mart-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

/*
 * Dark mode styles
 */
.emoji-mart-dark {
  color: #fff;
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-bar {
  border-color: #555453;
}

.emoji-mart-dark .emoji-mart-search input {
  color: #fff;
  border-color: #555453;
  background-color: #2f2f2f;
}

.emoji-mart-dark .emoji-mart-search-icon svg {
  fill: #fff;
}

.emoji-mart-dark .emoji-mart-category .emoji-mart-emoji:hover:before {
  background-color: #444;
}

.emoji-mart-dark .emoji-mart-category-label span {
  background-color: #222;
  color: #fff;
}

.emoji-mart-dark .emoji-mart-skin-swatches {
  border-color: #555453;
  background-color: #222;
}

.emoji-mart-dark .emoji-mart-anchor:hover,
.emoji-mart-dark .emoji-mart-anchor:focus,
.emoji-mart-dark .emoji-mart-anchor-selected {
  color: #bfbfbf;
}

/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:43 AM */
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Heavy.eot");
  src: local("Avenir Next Cyr Heavy"), local("AvenirNextCyr-Heavy"), url("../assets/fonts/AvenirNextCyr-Heavy.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Heavy.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-HeavyItalic.eot");
  src: local("Avenir Next Cyr Heavy Italic"), local("AvenirNextCyr-HeavyItalic"), url("../assets/fonts/AvenirNextCyr-HeavyItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-HeavyItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-HeavyItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Bold.eot");
  src: local("Avenir Next Cyr Bold"), local("AvenirNextCyr-Bold"), url("../assets/fonts/AvenirNextCyr-Bold.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Bold.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-BoldItalic.eot");
  src: local("Avenir Next Cyr Bold Italic"), local("AvenirNextCyr-BoldItalic"), url("../assets/fonts/AvenirNextCyr-BoldItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-BoldItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Demi.eot");
  src: local("Avenir Next Cyr Demi"), local("AvenirNextCyr-Demi"), url("../assets/fonts/AvenirNextCyr-Demi.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Demi.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-DemiItalic.eot");
  src: local("Avenir Next Cyr Demi Italic"), local("AvenirNextCyr-DemiItalic"), url("../assets/fonts/AvenirNextCyr-DemiItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-DemiItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-DemiItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Medium.eot");
  src: local("Avenir Next Cyr Medium"), local("AvenirNextCyr-Medium"), url("../assets/fonts/AvenirNextCyr-Medium.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Medium.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-MediumItalic.eot");
  src: local("Avenir Next Cyr Medium Italic"), local("AvenirNextCyr-MediumItalic"), url("../assets/fonts/AvenirNextCyr-MediumItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-MediumItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Regular.eot");
  src: local("Avenir Next Cyr Regular"), local("AvenirNextCyr-Regular"), url("../assets/fonts/AvenirNextCyr-Regular.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Regular.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Italic.eot");
  src: local("Avenir Next Cyr Italic"), local("AvenirNextCyr-Italic"), url("../assets/fonts/AvenirNextCyr-Italic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Italic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Italic.ttf") format("truetype");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Light.eot");
  src: local("Avenir Next Cyr Light"), local("AvenirNextCyr-Light"), url("../assets/fonts/AvenirNextCyr-Light.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Light.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-LightItalic.eot");
  src: local("Avenir Next Cyr Light Italic"), local("AvenirNextCyr-LightItalic"), url("../assets/fonts/AvenirNextCyr-LightItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-LightItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr Ultra";
  src: url("../assets/fonts/AvenirNextCyr-UltraLight.eot");
  src: local("Avenir Next Cyr Ultra Light"), local("AvenirNextCyr-UltraLight"), url("../assets/fonts/AvenirNextCyr-UltraLight.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-UltraLight.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-UltraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr Ultra";
  src: url("../assets/fonts/AvenirNextCyr-UltraLightIt.eot");
  src: local("Avenir Next Cyr Ultra Light Italic"), local("AvenirNextCyr-UltraLightIt"), url("../assets/fonts/AvenirNextCyr-UltraLightIt.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-UltraLightIt.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-UltraLightIt.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-Thin.eot");
  src: local("Avenir Next Cyr Thin"), local("AvenirNextCyr-Thin"), url("../assets/fonts/AvenirNextCyr-Thin.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-Thin.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Avenir Next Cyr";
  src: url("../assets/fonts/AvenirNextCyr-ThinItalic.eot");
  src: local("Avenir Next Cyr Thin Italic"), local("AvenirNextCyr-ThinItalic"), url("../assets/fonts/AvenirNextCyr-ThinItalic.eot?#iefix") format("embedded-opentype"), url("../assets/fonts/AvenirNextCyr-ThinItalic.woff") format("woff"), url("../assets/fonts/AvenirNextCyr-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}
body, html {
  background-color: #FAFCFB;
  font-family: "Avenir Next Cyr", sans-serif;
}

.btn-primary {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  /*width: 100%;*/
}

.container {
  width: 100%;
  max-width: 1200px;
}
@media (max-width: 767.98px) {
  .container {
    padding: 0;
  }
}

html {
  overflow-y: auto;
}

body {
  position: relative;
  min-height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

.text-12 {
  font-size: 0.75rem;
}

.my-styling .pb-1 {
  font-weight: bold;
}

.my-styling .pb-4 {
  padding-bottom: 10px !important;
}

.text-13 {
  font-size: 0.8125rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-15 {
  font-size: 0.9375rem;
}

.text-16 {
  font-size: 1rem !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.w-0 {
  width: 0;
}

.w-91 {
  width: 91%;
}

.w-90 {
  width: 90%;
}

.w-96 {
  width: 96%;
}

.w-80 {
  width: 80%;
}

.w-77 {
  width: 77%;
}

.w-72 {
  width: 72%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-35 {
  width: 35%;
}

.w-15 {
  width: 15%;
}

.w-45 {
  width: 45% !important;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-1rem {
  width: 1rem;
}

.max-w-250 {
  max-width: 250px;
  margin-left: 40px !important;
  padding-left: 0;
  padding-right: 0;
}

.vh-100 {
  min-height: 75vh;
}

.text-aling-center {
  text-align: center;
}

.text-opacity-primary {
  color: rgba(0, 0, 0, 0.7);
  text-transform: uppercase !important;
}

.text-opacity-secondary {
  color: rgba(0, 0, 0, 0.4);
}

.text-black {
  color: #424B5A !important;
}

.border-2 {
  border-width: 2px !important;
}

.m-26 {
  margin: 26px;
}

.w-round-image {
  margin: 0 0 30px;
  overflow: hidden;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.mh-0 {
  min-height: 0;
}

.mw-0 {
  min-width: 0;
}

.object-fit-cover {
  object-fit: cover !important;
}

.insert-row {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  top: -5px;
  white-space: nowrap;
}

.alert-role-badge {
  background-color: #E4F4EA;
  color: #4EC280;
  padding: 0.25em 0.5em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.alert-button-badge {
  padding: 3px 9px;
  border: 1px solid #DCDFE5;
  background-color: #F3F4F6;
  border-radius: 5px;
  color: #424B5A;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  margin-left: 0;
  white-space: nowrap;
}

.btn-inactive {
  cursor: unset !important;
}
.btn-inactive.b-btn-primary:hover, .btn-inactive.b-btn-primary:focus {
  background-color: #59b680 !important;
  border-color: #59b680 !important;
  box-shadow: none !important;
}

.dialog .dx-popup-content {
  padding: 0 !important;
}
.dialog .dx-item.dx-toolbar-item.dx-toolbar-label {
  max-width: inherit !important;
}
.dialog.overflow-hidden .dx-popup-normal {
  overflow: hidden;
}

.document-viewer-container .dx-item.dx-toolbar-item.dx-toolbar-label {
  max-width: 60vw !important;
}

.image-uploader {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 20px;
  border: 1px dashed #dcdfe5;
}
.image-uploader input {
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
  position: absolute;
  z-index: 0;
}
.image-uploader-drag-area {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
}
.image-uploader-drag-area-plus {
  width: 26px;
  height: 26px;
  border-radius: 8px;
  padding: 5px;
  background-color: #e8eaee;
  opacity: 0;
}
.image-uploader-drag-area-plus:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNy4yODU3MSA3LjI4NTcxVjNIOC43MTQyOVY3LjI4NTcxSDEzVjguNzE0MjlIOC43MTQyOVYxM0g3LjI4NTcxVjguNzE0MjlIM1Y3LjI4NTcxSDcuMjg1NzFaIiBmaWxsPSIjNjg2ZjdiIi8+Cjwvc3ZnPg==") center center no-repeat;
}
.image-uploader-drag-area:hover .image-uploader-drag-area-plus {
  opacity: 1;
}
.image-uploader-empty-state {
  width: 100%;
  height: 100%;
  padding: 32px 26px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  gap: 6px;
}
.image-uploader-empty-state.dragged-over {
  background-color: #f9f9fa;
}
.image-uploader-empty-state-image {
  display: block;
  width: 25px;
  height: 25px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNS41NDUyIDIwLjU5OTlMNS41MjcyIDIwLjYxNzlMNS41MDgzIDIwLjU5OTlIMy44OTI4QzMuNjU1OTMgMjAuNTk5NyAzLjQyODg1IDIwLjUwNTQgMy4yNjE0NCAyMC4zMzc4QzMuMDk0MDMgMjAuMTcwMiAzIDE5Ljk0MzEgMyAxOS43MDYyVjUuMjkzNkMzLjAwMTY1IDUuMDU3MjQgMy4wOTYyMSA0LjgzMTAyIDMuMjYzMjYgNC42NjM4QzMuNDMwMzEgNC40OTY1OCAzLjY1NjQ0IDQuNDAxNzkgMy44OTI4IDQuMzk5OUgyMC4xMDcyQzIwLjYwMDQgNC4zOTk5IDIxIDQuODAwNCAyMSA1LjI5MzZWMTkuNzA2MkMyMC45OTg0IDE5Ljk0MjYgMjAuOTAzOCAyMC4xNjg4IDIwLjczNjcgMjAuMzM2QzIwLjU2OTcgMjAuNTAzMiAyMC4zNDM2IDIwLjU5OCAyMC4xMDcyIDIwLjU5OTlINS41NDUyWk0xOS4yIDE1LjE5OTlWNi4xOTk5SDQuOFYxOC43OTk5TDEzLjggOS43OTk5TDE5LjIgMTUuMTk5OVpNMTkuMiAxNy43NDUxTDEzLjggMTIuMzQ1MUw3LjM0NTIgMTguNzk5OUgxOS4yVjE3Ljc0NTFaTTguNCAxMS41OTk5QzcuOTIyNjEgMTEuNTk5OSA3LjQ2NDc3IDExLjQxMDMgNy4xMjcyMSAxMS4wNzI3QzYuNzg5NjQgMTAuNzM1MSA2LjYgMTAuMjc3MyA2LjYgOS43OTk5QzYuNiA5LjMyMjUxIDYuNzg5NjQgOC44NjQ2OCA3LjEyNzIxIDguNTI3MTFDNy40NjQ3NyA4LjE4OTU0IDcuOTIyNjEgNy45OTk5IDguNCA3Ljk5OTlDOC44NzczOSA3Ljk5OTkgOS4zMzUyMyA4LjE4OTU0IDkuNjcyNzkgOC41MjcxMUMxMC4wMTA0IDguODY0NjggMTAuMiA5LjMyMjUxIDEwLjIgOS43OTk5QzEwLjIgMTAuMjc3MyAxMC4wMTA0IDEwLjczNTEgOS42NzI3OSAxMS4wNzI3QzkuMzM1MjMgMTEuNDEwMyA4Ljg3NzM5IDExLjU5OTkgOC40IDExLjU5OTlaIiBmaWxsPSIjNDI0QjVBIi8+Cjwvc3ZnPgo=") center center no-repeat;
}
.image-uploader-empty-state-action {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: 0 !important;
  gap: 2px;
  font-family: "Avenir Next Cyr", sans-serif !important;
  font-size: 14px;
  font-weight: 400;
}
.image-uploader-empty-state-action > span {
  color: #5461f0;
}
.image-uploader-empty-state-description {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin: 0 !important;
  gap: 6px;
  color: #8e939c;
  font-family: "Avenir Next Cyr", sans-serif !important;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 1.3px;
  text-transform: uppercase;
}
.image-uploader-empty-state-description-dot {
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: #424B5A;
}
.image-uploader-empty-state:hover .image-uploader-empty-state-action > span, .image-uploader-empty-state.hovered .image-uploader-empty-state-action > span {
  text-decoration: underline;
  text-underline-offset: 3px;
}
.image-uploader-images {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  justify-content: stretch;
  gap: 2px;
}
.image-uploader-image {
  flex: 1 1 calc(50% - 2px);
}
.image-uploader-image > img {
  width: 100%;
  height: 100%;
  max-height: 150px;
  object-fit: cover;
}
.image-uploader-image-skeleton {
  display: block;
  width: 100%;
  height: 100%;
  max-height: 150px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.image-uploader-image-skeleton:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  animation-name: animate;
  animation-duration: 1.4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background: linear-gradient(to right, #dcdfe5 8%, #b3b7bd 18%, #dcdfe5 33%) 0 0;
  background-size: 100vw 100vh;
}
@keyframes animate {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100vw 0;
  }
}
.image-uploader-image-remove {
  width: 26px;
  height: 26px;
  border-radius: 8px;
  padding: 5px;
  background-color: #e8eaee;
  position: absolute;
  z-index: 8;
  cursor: pointer;
}
.image-uploader-image-remove:before {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTAuNzk5OSA0LjYzOTlIMTMuNTk5OVY1Ljc1OTlIMTIuNDc5OVYxMy4wMzk5QzEyLjQ3OTkgMTMuMTg4NCAxMi40MjA5IDEzLjMzMDkgMTIuMzE1OSAxMy40MzU5QzEyLjIxMDkgMTMuNTQwOSAxMi4wNjg0IDEzLjU5OTkgMTEuOTE5OSAxMy41OTk5SDQuMDc5OUMzLjkzMTM4IDEzLjU5OTkgMy43ODg5NCAxMy41NDA5IDMuNjgzOTIgMTMuNDM1OUMzLjU3ODkgMTMuMzMwOSAzLjUxOTkgMTMuMTg4NCAzLjUxOTkgMTMuMDM5OVY1Ljc1OTlIMi4zOTk5VjQuNjM5OUg1LjE5OTlWMi45NTk5QzUuMTk5OSAyLjgxMTM4IDUuMjU4OSAyLjY2ODk0IDUuMzYzOTIgMi41NjM5MkM1LjQ2ODk0IDIuNDU4OSA1LjYxMTM4IDIuMzk5OSA1Ljc1OTkgMi4zOTk5SDEwLjIzOTlDMTAuMzg4NCAyLjM5OTkgMTAuNTMwOSAyLjQ1ODkgMTAuNjM1OSAyLjU2MzkyQzEwLjc0MDkgMi42Njg5NCAxMC43OTk5IDIuODExMzggMTAuNzk5OSAyLjk1OTlWNC42Mzk5Wk0xMS4zNTk5IDUuNzU5OUg0LjYzOTlWMTIuNDc5OUgxMS4zNTk5VjUuNzU5OVpNOC43OTE3NCA5LjExOTlMOS43ODE4MiAxMC4xMUw4Ljk4OTk4IDEwLjkwMThMNy45OTk5IDkuOTExNzRMNy4wMDk4MiAxMC45MDE4TDYuMjE3OTggMTAuMTFMNy4yMDgwNiA5LjExOTlMNi4yMTc5OCA4LjEyOTgyTDcuMDA5ODIgNy4zMzc5OEw3Ljk5OTkgOC4zMjgwNkw4Ljk4OTk4IDcuMzM3OThMOS43ODE4MiA4LjEyOTgyTDguNzkxNzQgOS4xMTk5Wk02LjMxOTkgMy41MTk5VjQuNjM5OUg5LjY3OTlWMy41MTk5SDYuMzE5OVoiIGZpbGw9IiM2ODZmN2IiLz4KPC9zdmc+") center center no-repeat;
}

.multi-range-slider {
  padding: 8px 0 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multi-range-slider .multi-range-slider-thumb {
  position: absolute;
  height: 0;
  width: 100%;
  padding: 0;
  border: none;
  outline: none;
  pointer-events: none;
  -webkit-appearance: none;
}
.multi-range-slider .multi-range-slider-thumb::-webkit-slider-thumb {
  position: relative;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  background-color: #59b680;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
  -webkit-appearance: none;
}
.multi-range-slider .multi-range-slider-thumb::-moz-range-thumb {
  position: relative;
  height: 20px;
  width: 20px;
  margin-top: 4px;
  background-color: #59b680;
  border: 2px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
  pointer-events: all;
}
.multi-range-slider .multi-range-slider-thumb-left {
  z-index: 3;
}
.multi-range-slider .multi-range-slider-thumb-right {
  z-index: 4;
}
.multi-range-slider .multi-range-slider-inner {
  position: relative;
  width: 100%;
}
.multi-range-slider .multi-range-slider-inner .multi-range-slider-track,
.multi-range-slider .multi-range-slider-inner .multi-range-slider-range {
  position: absolute;
  border-radius: 3px;
  height: 5px;
}
.multi-range-slider .multi-range-slider-inner .multi-range-slider-track {
  background-color: #b3b7bd;
  width: 100%;
  z-index: 1;
}
.multi-range-slider .multi-range-slider-inner .multi-range-slider-range {
  background-color: #59b680;
  z-index: 2;
}

.rent-schedule-preview-popover .dx-overlay-content .dx-popover-arrow {
  display: none !important;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content {
  padding: 0 !important;
  overflow: hidden !important;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner {
  width: 100%;
  background-color: white;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner table {
  width: 100%;
  font-size: 14px;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner table tr:nth-child(even) td {
  background: #eeeeee;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner table th, .rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner table td {
  padding: 10px 15px;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner table th {
  font-weight: 400;
  white-space: nowrap;
}
.rent-schedule-preview-popover .dx-overlay-content .dx-popup-content .popover-inner .comment {
  padding: 10px 15px;
}

.dx-custom-gray-popover .dx-overlay-content {
  border: 0 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popover-arrow {
  display: none !important;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content {
  padding: 0 !important;
  overflow: hidden !important;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner {
  width: 100%;
  background-color: white;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner table {
  width: 100%;
  font-size: 14px;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner table tr:nth-child(even) td {
  background: #eeeeee;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner table th, .dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner table td {
  padding: 10px 15px;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner table th {
  font-weight: 600;
  white-space: nowrap;
}
.dx-custom-gray-popover .dx-overlay-content .dx-popup-content .popover-inner .comment {
  padding: 10px 15px;
}

.term-toast {
  margin-bottom: 20px;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
}
.term-toast > i {
  margin-right: 27px;
}
.term-toast > i > svg {
  width: 24px;
  height: 24px;
}
.term-toast-success {
  background-color: #D1EBDC;
  color: #215C3A;
}
.term-toast-success i svg path {
  fill: #215C3A;
}
.term-toast-neutral {
  background-color: #E8EAEE;
  color: #424B5A;
}
.term-toast-neutral i svg path {
  fill: #424B5A;
}
.term-toast-information {
  background-color: #E8EAEE;
  color: #424B5A;
}
.term-toast-information i svg path {
  fill: #424B5A;
}
.term-toast-warning {
  background-color: #FEF7EA;
  color: #832118;
}
.term-toast-warning i svg path {
  fill: #832118;
}
.term-toast-error {
  background-color: #FCD0CC;
  color: #832118;
}
.term-toast-error i svg path {
  fill: #832118;
}

.b-divider {
  margin: 30px 0;
}

.w-desktop {
  display: flex;
  position: relative;
}
.w-desktop .b-controls-back {
  color: #A6A6A6;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 30px 0;
}
.w-desktop .w-aside {
  min-height: calc(100vh - 265px);
  width: 380px;
  transition: width 0.4s ease-in-out;
  overflow: hidden;
}
.w-desktop .w-aside-container {
  padding: 0 30px;
  height: 100%;
  width: 380px;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #ffffff;
}
.w-desktop .w-aside .b-title {
  font-size: 24px;
}
.w-desktop .w-content {
  flex: 1 0 calc(100% - 380px);
  width: calc(100% - 380px);
}
.w-desktop .w-toggle {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 120px;
  left: 380px;
  z-index: 100;
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: transform 0.4s ease-in-out, left 0.4s ease-in-out;
  background-color: #ffffff;
  border: 0;
  border-radius: 50%;
  color: #4ec280;
  font-size: 14px;
}
.w-desktop.collapsed .w-aside {
  width: 30px;
}
.w-desktop.collapsed .w-toggle {
  left: 30px;
  transform: translate(-50%, -50%) rotate(90deg);
}

.w-page {
  padding: 0 15px;
  position: relative;
}
.w-page-building {
  margin-top: -80px;
}

.body-100 {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  padding: 0;
  margin: 0;
}
.body-100 .container {
  padding: 0px;
  margin: 0;
  max-width: 100%;
  width: 100%;
}
.body-100 .row {
  margin-left: 0px;
  margin-right: 0px;
}
.body-100 .stepper {
  max-width: 100% !important;
}
.body-100 .navbar-brand {
  margin-left: 15px;
}
.body-100 .all-screen {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.body-100 .all-screen .container--wide {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.body-100 .all-screen .container--wide app-doc-edit {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.static-footer footer {
  position: static !important;
}

.all-screen {
  background: #f7f7f7;
}

.all-screen .container--wide {
  width: 100%;
  max-width: 100%;
}

header .container--wide {
  width: 100%;
  max-width: 100%;
}

.container--wide {
  width: 100%;
  max-width: 1365px;
}

.overlay {
  z-index: 2999 !important;
}

.calendly-badge-widget {
  display: none;
  right: 130px;
}

@media (max-width: 1600px) {
  .w-desktop .w-aside {
    width: 330px;
  }
  .w-desktop .w-aside-container {
    width: 330px;
  }
  .w-desktop .w-toggle {
    left: 330px;
  }
}
.b-title {
  color: #4d4d4d;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 15px;
}

.uppercase .b-header {
  text-transform: uppercase;
}

.b-header {
  display: flex;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 15px;
  align-items: center;
  justify-content: space-between;
}
.b-header.fancy {
  margin-bottom: 20px;
  padding: 20px 15px 0;
}
@media (min-width: 768px) {
  .b-header {
    font-size: 16px;
    font-weight: 500;
  }
}
.b-header-controls {
  padding-left: 15px;
}
.b-header-controls .b-search {
  border-color: transparent;
}
.b-header-controls > button + button {
  margin-left: 20px;
}
.b-header-icon {
  display: flex;
  color: #A6A6A6;
  font-size: 12px;
  align-items: center;
  margin: 0 auto 0 32px;
}
.b-header-icon > [class^=icon] {
  font-size: 15px;
  margin-right: 5px;
}
.b-header .w-search {
  max-width: 120px;
}

.green-icon {
  color: #6ABE8E;
}

.green-header {
  display: flex;
  color: #6ABE8E !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 15px;
  align-items: center;
  justify-content: space-between;
}
.green-header.fancy {
  margin-bottom: 20px;
  border-bottom: 1px solid #E6E6E6;
  padding: 20px 20px;
  text-transform: uppercase;
}

.b-header2 {
  color: #4D4D4D;
  font-size: 22px;
  margin: 0 0 20px;
}

.bottom-border {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 20px !important;
  text-transform: uppercase;
}

.dx-widget, .dx-overlay-wrapper,
.dx-widget input, .dx-widget textarea {
  font-family: "Avenir Next Cyr", sans-serif;
}

.dx-treelist {
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
}
.dx-treelist .dx-treelist-header-panel {
  padding: 12px 20px;
  border-color: #d8d8d8;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar {
  margin: 0;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item {
  padding: 0 0 0 10px;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-button {
  border-radius: 50%;
  border: 0;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-button.dx-button-mode-contained {
  background-color: #9b9b9b;
  color: #ffffff;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-button.dx-button-mode-contained.dx-state-hover {
  background-color: #59b680;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-button .dx-button-content {
  padding: 4px;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-button .dx-button-content .dx-icon {
  color: #ffffff;
  font-size: 14px;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-treelist-search-panel.dx-texteditor.dx-editor-outlined {
  width: 250px !important;
  border: none;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-treelist-search-panel.dx-texteditor.dx-editor-outlined .dx-icon-search:before {
  color: #59b680;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-treelist-search-panel.dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  background: #dbffe5;
}
.dx-treelist .dx-treelist-header-panel .dx-toolbar .dx-toolbar-after .dx-toolbar-item .dx-treelist-search-panel.dx-texteditor.dx-editor-outlined .dx-placeholder {
  color: #747474;
}
.dx-treelist .dx-treelist-headers {
  border-color: #d8d8d8;
}
.dx-treelist .dx-treelist-headers .dx-treelist-table .dx-row > td {
  padding: 10px;
  border: none;
  font-size: 15px;
  text-transform: uppercase;
}
.dx-treelist .dx-treelist-headers .dx-treelist-table .dx-row > td.dx-treelist-select-all {
  padding-left: 44px;
}
.dx-treelist .dx-treelist-headers .dx-treelist-table .dx-row > td [class^=icon-] {
  margin-left: 5px;
}
.dx-treelist .dx-treelist-rowsview {
  border-top: none;
}
.dx-treelist .dx-treelist-rowsview tbody tr td {
  padding: 0 10px 10px;
  border: 0;
  font-size: 16px;
}
.dx-treelist .dx-treelist-rowsview tbody tr td .dx-treelist-empty-space {
  width: 25px;
}
.dx-treelist .dx-treelist-rowsview tbody tr td .dx-treelist-empty-space.dx-treelist-collapsed span:before {
  content: "";
}
.dx-treelist .dx-treelist-rowsview tbody tr td .dx-treelist-empty-space.dx-treelist-expanded span:before {
  content: "";
  color: #59b680;
}
.dx-treelist .dx-treelist-rowsview tbody tr td:first-child {
  font-weight: 500;
}
.dx-treelist .dx-treelist-rowsview tbody tr .dx-treelist-icon-container.dx-editor-inline-block {
  padding-left: 10px;
  padding-right: 25px;
}
.dx-treelist .dx-treelist-rowsview tbody tr .dx-treelist-icon-container.dx-editor-inline-block .dx-checkbox-icon {
  width: 18px;
  height: 18px;
  box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  border: 1px solid #cacaca;
  font-size: 10px;
}
.dx-treelist .dx-treelist-rowsview tbody tr[aria-level="0"] td {
  padding: 13px 10px;
  background: #fafafa;
  border-top: 1px solid #d8d8d8;
  font-size: 18px;
}
.dx-treelist .dx-treelist-rowsview tbody tr[aria-expanded=true] td {
  background: white;
}
.dx-treelist .dx-treelist-rowsview tbody tr[aria-expanded=true] td:first-child {
  color: #59b680;
}
.dx-treelist .dx-treelist-rowsview tbody tr:first-child td {
  border-top: 0;
}

.dx-treelist-filter-row {
  border-bottom: none !important;
}

.dx-treelist-borders > .dx-treelist-pager,
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-filter-panel {
  border-top: none;
}

.dx-treelist-content .dx-treelist-table {
  border: none;
}

.dx-treelist .dx-treelist-pager {
  border-top: none;
  padding: 5px;
}

.dx-treelist .mode-button {
  padding: 8px;
  margin: -3px -5px -7px -5px;
  min-width: 34px;
  height: 34px;
}

.documents .dx-row td:focus {
  outline: transparent !important;
}

.dx-header-filter-indicator {
  padding-left: 10px;
}

.button-group-last {
  margin-right: 10px;
}

.popup-button-ok {
  float: right;
}

.popup-button-cancel {
  float: right;
  margin-right: 10px;
}

.popup-box {
  height: 100%;
}

.treeview-item-source {
  color: #59b680;
  height: 30px;
  margin: -4px -5px -4px -5px;
  padding: 5px;
  background-color: white;
}

.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item * .treeview-item-source {
  color: #59b680;
}

.dx-tabs {
  border: none;
  border-bottom: 1px solid #d8d8d8;
}
.dx-tabs .dx-scrollable-container {
  overflow-x: auto;
}
.dx-tabs .dx-scrollable-container::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  border: 1px solid #fff;
}
.dx-tabs .dx-scrollable-container::-webkit-scrollbar-button {
  display: none;
}
.dx-tabs .dx-tabs-wrapper {
  display: flex;
}
.dx-tabs .dx-tabs-wrapper .dx-tab {
  background-color: transparent;
}
.dx-tabs .dx-tabs-wrapper .dx-tab:after {
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
  z-index: 25;
  transition: background-color ease-in-out 0.3s, transform ease-in-out 0.3s;
}
.dx-tabs .dx-tabs-wrapper .dx-tab:hover:after {
  transform: scale(0.8, 1);
  background-color: rgba(78, 194, 128, 0.5);
}
.dx-tabs .dx-tabs-wrapper .dx-tab.dx-tab-selected {
  background-color: transparent;
}
.dx-tabs .dx-tabs-wrapper .dx-tab.dx-tab-selected:after {
  top: auto;
  right: 0;
  left: 0;
  background-color: #4ec280;
  border: 0;
}
.dx-tabs .dx-tabs-wrapper .dx-tab.dx-tab-selected:hover:after {
  background-color: rgba(78, 194, 128, 0.5);
}
.dx-tabs .dx-tabs-wrapper .dx-tab + .dx-tab {
  margin-left: 8px;
}
.dx-tabs .dx-tabs-wrapper .dx-tab .dx-tab-text {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
}

.tabs-rounded {
  width: auto !important;
  border-bottom: 0;
}
.tabs-rounded .dx-tabs-wrapper .dx-tab {
  padding: 7px 25px 8px;
  transition: background-color ease-in-out 0.3s;
  border-bottom: 0;
  border-radius: 18px;
  line-height: 1;
}
.tabs-rounded .dx-tabs-wrapper .dx-tab:after {
  display: none;
}
.tabs-rounded .dx-tabs-wrapper .dx-tab:hover, .tabs-rounded .dx-tabs-wrapper .dx-tab:focus {
  background-color: rgba(78, 194, 128, 0.1);
}
.tabs-rounded .dx-tabs-wrapper .dx-tab.dx-tab-selected {
  background-color: #4ec280;
}
.tabs-rounded .dx-tabs-wrapper .dx-tab.dx-tab-selected .dx-tab-text {
  color: white;
}
.tabs-rounded .dx-tabs-wrapper .dx-tab .dx-tab-text {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.125px;
  text-transform: none !important;
  color: #2C2C2C;
}

.dx-tabs-scrollable {
  margin: 0 25px;
}
.dx-tabs-scrollable .dx-tabs-wrapper {
  border-top: 0;
  border-bottom: 0;
}

.uploader-no-padding .dx-fileuploader-wrapper,
.uploader-no-padding .dx-fileuploader-input-wrapper {
  padding: 0;
  border: none;
}

.mode-menu-item {
  padding-left: 19px;
}

.mode-menu-item.checked {
  padding-left: 5px;
}

.dx-invalid {
  border-color: #a84948;
  background-image: none;
}

.dx-invalid.dx-texteditor.dx-editor-filled .dx-texteditor-container:after,
.dx-invalid.dx-texteditor.dx-editor-underlined .dx-texteditor-container:after,
.dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-container:after {
  display: none;
}

.dx-invalid.dx-texteditor {
  border-color: #a84948;
}

.dx-invalid-message {
  display: none;
}

.avatar-image {
  display: flex;
  align-items: center;
}
.avatar-image .avatar-block {
  margin-right: 15px;
  flex-shrink: 0;
}
.avatar-image > a {
  white-space: normal;
}

@media (max-width: 1200px) {
  .avatar-image {
    flex-direction: column;
  }
  .avatar-image .avatar-block {
    margin: 0 0 5px;
  }
  .avatar-image > a {
    text-align: center;
  }
}
.tenants-grid .dx-row .dx-data-row .dx-column-lines > td {
  border: none;
}

.lease-accordion .dx-accordion-item-title-caption {
  font-size: 14px;
}

.lease-accordion .dx-accordion-item {
  border-right: none;
  border-left: none;
}

.dx-checkbox {
  position: relative;
  bottom: 3px;
  margin-right: 10px;
}
.dx-checkbox.dx-state-focused .dx-checkbox-icon, .dx-checkbox.dx-state-hover .dx-checkbox-icon {
  border: none;
}
.dx-checkbox .dx-checkbox-container {
  text-overflow: clip;
  height: auto;
}
.dx-checkbox .dx-checkbox-icon {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  border: none;
  background: #E8EAEE;
}
.dx-checkbox + label {
  font-size: 14px;
  font-weight: 400;
  color: #8E939C;
}
.dx-checkbox.dx-checkbox-has-text .dx-checkbox-text {
  font-size: 14px;
  font-weight: 400;
  color: #8E939C;
}
.dx-checkbox.dx-checkbox-has-text.dx-checkbox-checked .dx-checkbox-text {
  font-weight: 500;
  color: #424B5A;
}
.dx-checkbox.dx-checkbox-checked .dx-checkbox-icon:before {
  content: "";
  background: url("/assets/img/check-icon.svg") no-repeat center;
  width: 20px;
  height: 20px;
  margin-top: -9px;
  margin-left: -10px;
}
.dx-checkbox.dx-checkbox-checked + label {
  color: #424B5A;
}

.dx-textarea .dx-placeholder {
  color: #8E939C;
}
.dx-textarea .dx-placeholder.dx-widget.dx-invalid .dx-invalid-message .dx-overlay-wrapper {
  top: 11px !important;
}

.dx-widget.dx-treeview {
  width: auto !important;
}

.dx-widget.tree-list {
  font-weight: normal;
  font-size: 14px;
}

.dx-widget.real-estate-input {
  max-width: 323px;
  width: 100%;
  background: #F3F4F6;
  border: none;
  padding: 5px 0 5px 5px;
  opacity: 1;
}
.dx-widget.real-estate-input.dx-texteditor.dx-editor-outlined {
  border-radius: 10px;
}
.dx-widget.real-estate-input.dx-datebox .dx-texteditor-container {
  flex-direction: row-reverse;
  padding-left: 5px;
}
.dx-widget.real-estate-input .dx-texteditor-container {
  border-radius: 14px;
}
.dx-widget.real-estate-input .dx-texteditor-container .dx-texteditor-input {
  background: #F3F4F6;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #424B5A;
}
.dx-widget.real-estate-input.dx-numberbox.rounded-10 {
  border-radius: 10px;
}
.dx-widget.real-estate-input.dx-tagbox .dx-tag-container .dx-tag-content {
  border-radius: 15px;
  background-color: #D1EBDC;
  color: #356D4D;
  padding: 3.5px 25px 3.5px 10px;
  display: flex;
  align-items: center;
}
.dx-widget.real-estate-input.dx-tagbox .dx-tag-container .dx-tag-content .dx-tag-remove-button {
  right: 4px;
  top: -1px;
}
.dx-widget.real-estate-input.dx-tagbox .dx-tag-container .dx-tag-content .dx-tag-remove-button:after {
  content: "";
  background: url("/assets/img/close-tag.svg") no-repeat center;
  transform: none;
  width: 12px;
  height: 12px;
  top: calc(50% - 5px);
  margin-top: 0;
  filter: invert(37%) sepia(12%) saturate(1481%) hue-rotate(93deg) brightness(97%) contrast(93%);
}
.dx-widget.real-estate-input.dx-tagbox .dx-tag-container .dx-tag-content .dx-tag-remove-button:before {
  content: none;
}
.dx-widget.real-estate-input.dx-tagbox.dx-state-focused, .dx-widget.real-estate-input.dx-tagbox:not(.dx-texteditor-empty):active {
  border: 1px solid #9BD3B3;
}

.dx-overlay-wrapper .dx-overlay-content:not(.dx-popup-normal) {
  border-radius: 14px;
  background: #F3F4F6;
}
.dx-overlay-wrapper .dx-overlay-content:not(.dx-popup-normal) .dx-list-item-content {
  padding: 9px 22px !important;
  background: #F3F4F6;
  font-size: 14px;
  font-weight: 500;
  color: #424B5A;
}

.dx-widget.v2-checkbox .dx-checkbox-icon {
  width: 26px;
  height: 26px;
  border: none;
  border-radius: 8px;
  background-color: #E8EAEE;
}
.dx-widget.v2-checkbox .dx-checkbox-icon:before {
  font-size: 12px;
  color: #424B5A;
}
.dx-widget.v2-checkbox .dx-checkbox-text {
  padding-left: 36px;
  margin-left: -26px;
}

.dx-widget.no-invalid-message.dx-invalid .dx-invalid-message {
  display: none;
}

.dx-widget.dx-invalid:not(.dx-radiogroup) {
  border: 1px solid #F09C94;
  border-radius: 10px;
}
.dx-widget.dx-invalid .dx-item:not(.dx-state-disabled) .dx-radiobutton-icon:before {
  border: 1px solid #F09C94 !important;
}
.dx-widget.dx-invalid .dx-invalid-message {
  display: block;
  visibility: visible;
}
.dx-widget.dx-invalid .dx-invalid-message .dx-overlay-wrapper, .dx-widget.dx-invalid .dx-invalid-message .dx-overlay-content {
  transform: none !important;
  background: transparent;
  color: #E75A4D;
  padding: 10px 0;
  text-transform: uppercase;
  letter-spacing: 0.055rem;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  max-width: fit-content !important;
}

.dx-overlay-wrapper.unit-action-tooltip {
  display: none;
}
@media (max-width: 1150px) {
  .dx-overlay-wrapper.unit-action-tooltip {
    display: block;
  }
}
.dx-overlay-wrapper.unit-action-tooltip .dx-overlay-content {
  border: none;
  border-radius: 8px;
  background: #686F7B;
}
.dx-overlay-wrapper.unit-action-tooltip .dx-overlay-content .dx-popup-content {
  padding: 6px 8px;
}
.dx-overlay-wrapper.unit-action-tooltip .dx-overlay-content .dx-popup-content span {
  color: #FFFFFF;
  font-size: 11px;
  font-weight: 400;
}
.dx-overlay-wrapper.unit-action-tooltip .dx-overlay-content .dx-popover-arrow {
  display: none;
}

.dx-overlay-wrapper.no-border .dx-overlay-content {
  border: none;
  border-radius: 10px;
}

.dx-overlay-wrapper .html-editor-menu.dx-overlay-content.dx-context-menu.dx-menu-base .dx-submenu {
  border-radius: 14px;
  border: 1px solid #F3F4F6;
  background: #F3F4F6;
  padding: 9px 22px;
  display: flex;
  align-items: center;
  justify-items: center;
}
.dx-overlay-wrapper .html-editor-menu.dx-overlay-content.dx-context-menu.dx-menu-base .dx-submenu .dx-menu-item-content {
  padding: 9px 0;
}
.dx-overlay-wrapper .html-editor-menu.dx-overlay-content.dx-context-menu.dx-menu-base .dx-submenu .dx-menu-item-content i {
  color: #424B5A;
  font-size: 16px;
}
.dx-overlay-wrapper .html-editor-menu.dx-overlay-content.dx-context-menu.dx-menu-base .dx-submenu .dx-menu-item-content .dx-menu-item-text {
  font-size: 14px;
  font-weight: 500;
  color: #424B5A;
  padding-left: 33px;
  font-family: "Avenir Next Cyr", sans-serif;
}

.list-space-dialog.dx-popup-wrapper .dx-overlay-content {
  border: none;
  border-radius: 20px;
  background-color: #fff;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 30px;
  border-bottom: 1px solid #E8EAEE;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-items-container {
  height: 20px;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content {
  padding-left: 40px;
  color: #424B5A;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.03em;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content:before {
  content: "";
  background-image: url("/assets/img/list-space.svg");
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton {
  margin: -3px 0 0 0;
  width: 26px;
  height: 26px;
  background: #E8EAEE;
  border-radius: 8px;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close {
  height: 22px;
}
.list-space-dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close :before {
  color: #686F7B;
}

.landlord-cancel-lease .dx-toolbar-label .dx-item-content div {
  padding-left: 40px;
  position: relative;
}
.landlord-cancel-lease .dx-toolbar-label .dx-item-content div:before {
  content: "";
  position: absolute;
  left: 0;
  background-image: url(../assets/img/trash.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 20px;
  height: 20px;
  top: 2px;
}
.landlord-cancel-lease .dx-overlay-content {
  border-radius: 20px;
}
.landlord-cancel-lease .dx-toolbar-item-content {
  font-weight: 500;
  font-size: 18px;
  text-transform: capitalize;
  color: #424B5A;
}

.main-actions {
  gap: 20px;
}
.main-actions .dx-button {
  width: 323px;
  height: 52px;
  border-radius: 18px;
  padding: 0;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.3px;
  text-transform: uppercase;
  box-shadow: none;
}
.main-actions .dx-button.dx-button-cancel {
  border: 1px solid #DCDFE5;
  background: #fff;
  color: #686F7B;
  box-shadow: none;
}
.main-actions .dx-button.dx-button-cancel:hover {
  background-color: #fff;
  border: 1px solid #B3B7BD;
}
.main-actions .dx-button.dx-button-mode-contained.dx-button-success {
  color: #fff;
  border-color: #59b680;
  background-color: #59b680;
  min-width: 120px;
}
.main-actions .dx-button.dx-button-mode-contained.dx-button-success:hover {
  color: #fff;
  border-color: #439967;
  background-color: #4BAA72;
}

.column-input .dx-checkbox-checked .dx-checkbox-icon {
  color: #4EC280;
  background: #ffffff;
}

.dx-header-row {
  font-weight: 500;
  font-size: 13px;
}

.column-input .dx-header-row {
  font-weight: 500;
  font-size: 13px;
  background: #73BEDE;
  color: white;
  height: 84px;
}

.dx-datagrid .dx-header-row > td {
  border-left: none;
  border-right: none;
}

.dx-tooltip-wrapper .dx-overlay-content {
  border: none;
  border-radius: 10px;
  transition: all 0.15s ease-in-out;
}
.dx-tooltip-wrapper .dx-overlay-content .dx-popover-arrow {
  display: none;
}
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  white-space: normal;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content .tooltip-menu {
  font-size: 14px;
  font-weight: 400;
  color: #FFFFFF;
  text-align: left;
}
.dx-tooltip-wrapper.small-tooltip .dx-overlay-content .dx-popup-content {
  padding: 6px 8px;
  font-size: 11px;
}
.dx-tooltip-wrapper.white-tooltip .dx-overlay-content {
  background-color: #F3F4F6;
}
.dx-tooltip-wrapper.white-tooltip .dx-overlay-content .dx-popup-content {
  background: inherit;
  border-radius: 14px;
  color: #424B5A;
}
.dx-tooltip-wrapper.tooltip-arrow .dx-overlay-content .dx-popover-arrow {
  display: block;
  left: calc(50% - 12px) !important;
  height: 13px;
  width: 24px;
}
.dx-tooltip-wrapper.tooltip-arrow .dx-overlay-content .dx-popover-arrow:after {
  content: none;
}
.dx-tooltip-wrapper.tooltip-arrow .dx-overlay-content .dx-popover-arrow:before {
  content: "";
  position: absolute;
  background-color: #686F7B;
  bottom: 5px;
  left: calc(50% - 10px);
  clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
  transform: rotate(-45deg);
  border-radius: 0 0 0 0.3em;
  width: 20px;
  height: 20px;
}

.only-safari {
  background-color: white !important;
  width: fit-content !important;
  height: fit-content !important;
  border: 1px solid gray;
}

#eversignContainerId iframe {
  display: block;
  margin: auto;
  border: 0;
}

.active-hover.dx-tooltip-wrapper .dx-popup-content {
  padding: 20px;
}

.nsm-content {
  padding: 0 !important;
  margin: 0 !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space {
  padding-left: 8px;
}

.dx-texteditor-input {
  border-radius: 0;
}

.column-input .dx-datagrid-filter-row td {
  background: #51A6CA;
}

.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table tbody .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu,
.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container .dx-texteditor-input,
.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
  background: #51A6CA !important;
  color: #A0CEE2;
}

.dx-icon-filter-operation-default {
  display: none !important;
}

.column-input .dx-datagrid-headers .dx-texteditor-input,
.column-input .dx-datagrid-rowsview .dx-texteditor-input {
  border-bottom: 1px solid #A0CEE2;
}

.dx-datagrid-headers .dx-texteditor-input,
.dx-datagrid-rowsview .dx-texteditor-input {
  padding: 7px;
  padding-bottom: 1px;
  margin-bottom: 6px;
  margin-left: 3px;
  width: 80%;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
  padding-left: 10px;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-filter-panel .dx-icon-filter {
  display: none;
}

.dx-datagrid .dx-column-lines > .dx-col-1 {
  border-right: transparent !important;
}

.terms-form .dx-fieldset {
  margin: 0;
}

.terms-form .dx-button-has-text .dx-button-content {
  padding: 2px 18px 2px;
}

.terms-form .escalation-table .dx-widget {
  margin-bottom: 0;
}

.my-dashboard .dx-toolbar-after {
  width: 100%;
  padding: 0;
  position: static;
}
.my-dashboard .dx-toolbar-after .dx-item-content .dx-toolbar-item-content {
  padding: 14px 22px;
}
.my-dashboard .dx-toolbar-after .dx-texteditor.dx-editor-outlined {
  flex-grow: 1;
  background: transparent;
  border: none;
  border-radius: 0;
}
.my-dashboard .dx-toolbar-after .dx-texteditor.dx-editor-outlined .dx-icon-search {
  height: 42px;
  width: 50px;
  font-size: 20px;
  line-height: 1;
  text-align: left;
}
.my-dashboard .dx-toolbar-after .dx-texteditor.dx-editor-outlined .dx-texteditor-input {
  max-width: 150px;
  background: #F3F4F6;
  border-radius: 10px;
}
.my-dashboard .dx-toolbar-after .dx-texteditor.dx-editor-outlined .dx-placeholder:before {
  padding-left: 50px;
}

.my-dashboard .dx-searchbox .dx-icon-search:before {
  color: #424B5A;
}

.my-dashboard .dx-toolbar .dx-toolbar-items-container {
  height: auto;
}

.my-dashboard .dx-datagrid .dx-row > td {
  vertical-align: middle !important;
  font-size: 14px;
  font-weight: 500;
}
.my-dashboard .dx-datagrid .dx-row > td:nth-child(n+2) {
  padding-left: 14px;
  padding-right: 14px;
}
.my-dashboard .dx-datagrid .dx-row > td.dx-master-detail-cell {
  background: #f0f0f0;
}

.my-dashboard .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand .dx-datagrid-group-opened, .my-dashboard .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand .dx-datagrid-group-closed {
  width: 18px;
}

.my-dashboard .column-input .dx-header-row {
  background: #fafafa;
  color: #8E939C8e;
  height: 68px;
  text-transform: uppercase;
}
.my-dashboard .column-input .dx-header-row > td {
  outline: transparent !important;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.8px;
  color: #8E939C;
}

.my-dashboard .dx-datagrid-rowsview .dx-row {
  border: none;
  min-height: 62px;
  border-top: 1px solid #ddd;
}

.my-dashboard .dx-datagrid-header-panel,
.my-dashboard .dx-datagrid-rowsview,
.my-dashboard .dx-datagrid-pager,
.my-dashboard .dx-datagrid .dx-column-lines > td {
  border: none !important;
}

.my-dashboard .dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
}

.my-dashboard .dx-datagrid-pager .dx-page-sizes .dx-page-size,
.my-dashboard .dx-datagrid-pager .dx-pages .dx-page {
  width: 35px;
  text-align: center;
}

.suites-grid .dx-datagrid-headers {
  border-bottom: 0;
}
.suites-grid .dx-datagrid-headers .dx-header-row {
  background: #FAFAFB;
}
.suites-grid .dx-datagrid-headers .dx-row > td {
  padding: 13px 10px !important;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.3px;
  color: #8E939C;
  text-transform: uppercase;
}
.suites-grid .dx-datagrid-headers .dx-row > td:nth-child(2) {
  padding-left: 30px !important;
}
.suites-grid .dx-datagrid-headers .dx-row > td:first-child {
  padding-left: 22px !important;
}
.suites-grid .dx-datagrid-headers .dx-row > td:last-child {
  padding-right: 22px !important;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td {
  padding: 15px 10px !important;
  font-size: 14px;
  font-weight: 500;
  color: #424B5A;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td:nth-child(2) {
  padding-left: 30px !important;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td:first-child {
  padding-left: 22px !important;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td:last-child {
  padding-right: 22px !important;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-suite {
  width: fit-content;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-suite-content {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  white-space: nowrap;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-suite-content > a {
  margin-left: 10px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-suite .link-popover {
  font-size: 0;
  line-height: 0;
  display: block;
  height: 21px;
  cursor: pointer;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-suite .link-popover > img {
  height: 21px;
  filter: invert(68%) sepia(6%) saturate(2747%) hue-rotate(92deg) brightness(92%) contrast(89%);
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-size {
  white-space: nowrap;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-base-rate a {
  color: #59b680;
  font-size: 14px;
  font-weight: 500;
  white-space: nowrap;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-base-rate .cell-base-rate-tooltip {
  margin-left: 9px;
  width: 16px;
  height: 16px;
  background: #E8EAEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-base-rate .cell-base-rate-tooltip svg {
  width: 12px;
  height: 12px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .b-btn {
  margin-top: 5px;
  padding: 7px 15px;
  text-transform: uppercase;
  white-space: nowrap;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .cell-status-text {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  padding: 3px 10px;
  border-radius: 15px;
  width: fit-content;
  background: #D1EBDC;
  color: #356D4D;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .cell-status-text.vacant {
  background: #E8EAEE;
  color: #424B5A;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .cell-status-text.renewal, .suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .cell-status-text.restructure {
  background: #DBDDFC;
  color: #333B99;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-status .cell-status-text.expired {
  background: #FCD0CC;
  color: #8F372F;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact {
  padding-right: 15px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .cell-contact-name {
  gap: 14px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .cell-contact-name p {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #424B5A;
  white-space: nowrap;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .cell-contact-name span {
  font-size: 14px;
  font-weight: 400;
  color: #8E939C;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .cell-contact-name span a {
  color: inherit;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .cell-contact-name span:hover a {
  color: inherit;
  text-decoration: none;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-contact .avatar-image .avatar-block {
  margin-right: 10px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-lease-start > div {
  display: flex;
  align-items: center;
  gap: 6px;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-lease-start > div p {
  margin-bottom: 0;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-lease-start > div span {
  width: 16px;
  height: 16px;
  background: #E8EAEE;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.suites-grid .dx-datagrid-rowsview .dx-row > td .cell-lease-start > div svg {
  color: #686F7B;
  width: 10px;
  height: 10px;
}
.suites-grid .dx-datagrid-rowsview .dx-row:nth-last-child(-n+2) {
  border-bottom: 1px solid #E8EAEE;
}
.suites-grid .dx-datagrid-rowsview .dx-row:nth-child(even) {
  background: #FAFAFB;
}

.dx-datagrid-focus-overlay {
  border: 2px solid transparent;
}

.proposals-grid .dx-datagrid-headers {
  border-bottom: 0;
}
.proposals-grid .dx-datagrid-headers .dx-row {
  height: 52px;
  border-top: 0;
}
.proposals-grid .dx-datagrid-headers .dx-row > td {
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.8px;
}
.proposals-grid .dx-datagrid-headers .dx-row td:first-child {
  padding-left: 30px !important;
}
.proposals-grid .dx-datagrid-rowsview .dx-row {
  height: 48px;
  border-top: 0;
}
.proposals-grid .dx-datagrid-rowsview .dx-row > td {
  font-size: 14px;
}
.proposals-grid .dx-datagrid-rowsview .dx-row > td:nth-child(n+1) {
  padding-top: 14px;
  padding-bottom: 14px;
}
.proposals-grid .dx-datagrid-rowsview .dx-row td:first-child {
  padding-left: 30px !important;
}

.my-card-lease {
  padding-bottom: 61px;
}
@media (max-height: 1000px) {
  .my-card-lease {
    padding-bottom: 0;
  }
}

.wrapper-for-empty-message .dx-empty-message {
  margin-left: 40px;
}

.dx-dropdowneditor-icon {
  background: transparent !important;
  border: 0;
  color: #29313e;
  font-family: "icomoon", sans-serif;
  font-size: 14px;
}
.dx-dropdowneditor-icon:before {
  content: "";
  background: url("/assets/img/arrow-down-action.svg") no-repeat center;
  width: 12px;
  height: 12px;
  top: calc(50% + 3px);
  left: auto;
  right: 14px;
}

.dx-datebox-calendar .dx-dropdowneditor-icon:before {
  content: "";
  background: url("/assets/img/calendar.svg") no-repeat center;
  width: 20px;
  height: 20px;
  top: calc(50% - 1px);
}

@media (max-width: 425px) {
  .wrapper-for-empty-message .dx-empty-message {
    margin-top: -23px;
  }
}
.dx-accordion-item-body {
  font-size: 16px;
}

.dx-popup-wrapper > .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 15px 30px 12px;
  font-weight: 600;
}

.image-viewer-container .dx-popup-content, .document-viewer-container .dx-popup-content {
  padding: 0;
}

.driver-stage-no-animation:before {
  content: "";
  position: fixed;
  z-index: 100002;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.dx-field {
  font-family: "Avenir Next Cyr", sans-serif !important;
}

.certificate-of-insurance-dialog > .dx-overlay-content {
  overflow-y: auto;
  border-radius: 0;
}
.certificate-of-insurance-dialog > .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 15px 30px 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.certificate-of-insurance-dialog > .dx-overlay-content .dx-popup-content {
  padding: 20px 30px 0;
}

.input-field.input-field-with-prefix .dx-numberbox.dx-widget.dx-invalid .dx-invalid-message .dx-overlay-wrapper {
  left: -30px !important;
}

.dx-list-group-header {
  padding: 10px !important;
}

.selectable-input-wrapper .input-field {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  padding-right: 0 !important;
  border-right: 1px solid #DCDFE5;
}
.selectable-input-wrapper .dx-numberbox {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  min-width: 100px;
  width: 100px;
}
.selectable-input-wrapper .dx-selectbox {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  width: 100%;
}
.selectable-input-wrapper .dx-selectbox input {
  text-transform: uppercase;
}

.dx-selectbox-popup-wrapper .dx-list-item.dx-list-item-selected,
.dx-selectbox-popup-wrapper .dx-list-item.dx-state-hover {
  background-color: transparent !important;
  color: #5461F0 !important;
}

.dx-selectbox-popup-wrapper .dx-list-item.dx-state-active {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.dx-selectbox-popup-wrapper .dx-list-item {
  background-color: transparent;
  color: #424B5A;
  padding: 3px 0;
}

.dx-selectbox-popup-wrapper .dx-list-item .dx-list-item-content {
  font-family: "Avenir Next Cyr", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.dx-selectbox-popup-wrapper .dx-list {
  background-color: transparent;
}
.dx-selectbox-popup-wrapper .dx-list:hover .dx-list-item {
  color: #424B5A !important;
}
.dx-selectbox-popup-wrapper .dx-list:hover .dx-list-item.dx-state-hover, .dx-selectbox-popup-wrapper .dx-list:hover .dx-list-item.dx-state-active {
  color: #5461F0 !important;
}

.dx-tagbox-popup-wrapper .dx-list .dx-list-item .dx-item-content {
  position: relative;
  padding-left: 40px !important;
}
.dx-tagbox-popup-wrapper .dx-list .dx-list-item .dx-item-content:before, .dx-tagbox-popup-wrapper .dx-list .dx-list-item .dx-item-content:after {
  content: "";
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -7px;
  width: 14px;
  height: 14px;
}
.dx-tagbox-popup-wrapper .dx-list .dx-list-item .dx-item-content:before {
  background-color: white;
  border-radius: 7px;
}
.dx-tagbox-popup-wrapper .dx-list .dx-list-item .dx-item-content:after {
  opacity: 0;
  background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.99994 3.82372L7.33298 0L8 0.588137L2.99994 5L0 2.35297L0.666549 1.76483L2.99994 3.82372Z' fill='%23424B5A'/%3E%3C/svg%3E%0A") 50% 50% no-repeat;
}
.dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-state-hover, .dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-state-active, .dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-list-item-selected {
  color: #5461F0 !important;
}
.dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-state-hover .dx-item-content:after, .dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-state-active .dx-item-content:after, .dx-tagbox-popup-wrapper .dx-list .dx-list-item.dx-list-item-selected .dx-item-content:after {
  opacity: 1;
}

.dx-selectbox-popup-wrapper .dx-popup-content {
  padding: 0;
  padding-top: 6px;
  background: transparent;
}
.dx-selectbox-popup-wrapper .dx-popup-content .dx-list {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  overflow: hidden;
  background-color: #F3F4F6;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
}
.dx-selectbox-popup-wrapper .dx-toolbar:last-child {
  border-top: 1px solid #e4e5e7;
  padding: 0;
  padding-bottom: 6px;
  background: transparent;
}
.dx-selectbox-popup-wrapper .dx-toolbar:last-child .dx-toolbar-items-container {
  height: unset !important;
  padding: 10px 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  background-color: #F3F4F6;
}
.dx-selectbox-popup-wrapper .dx-overlay-content {
  background-color: transparent;
  border-radius: 0;
  overflow: unset !important;
  border: 0;
  box-shadow: none;
}
.dx-selectbox-popup-wrapper .dx-overlay-content .dx-popup-content .dx-list-item-content {
  padding: 10px 20px;
}

.dx-selectbox-popup-wrapper:not(.dx-popup-cancel-visible):not(.dx-popup-done-visible) .dx-popup-content {
  padding: 6px 0;
  background: transparent;
}
.dx-selectbox-popup-wrapper:not(.dx-popup-cancel-visible):not(.dx-popup-done-visible) .dx-popup-content .dx-list {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.05);
  background-color: #F3F4F6;
}
.dx-selectbox-popup-wrapper:not(.dx-popup-cancel-visible):not(.dx-popup-done-visible) .dx-overlay-content {
  background-color: transparent;
  border-radius: 0;
  overflow: unset !important;
  border: 0;
  box-shadow: none;
}
.dx-selectbox-popup-wrapper:not(.dx-popup-cancel-visible):not(.dx-popup-done-visible) .dx-overlay-content .dx-popup-content .dx-list-item-content {
  padding: 10px 20px;
}

.analytics-filters-accordion .dx-accordion-wrapper .dx-accordion-item {
  border: 0;
}
.analytics-filters-accordion .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .dx-accordion-item-title {
  color: #59b680;
}
.analytics-filters-accordion .dx-accordion-wrapper .dx-accordion-item .dx-accordion-item-title {
  font-weight: 500;
}

.tenant-square-footage-control {
  margin: -20px;
}
.tenant-square-footage-control .dx-datagrid-table, .tenant-square-footage-control .dx-datagrid-rowsview {
  border: 0 !important;
}
.tenant-square-footage-control .dx-datagrid-rowsview .dx-row {
  height: auto !important;
  border: 0 !important;
}
.tenant-square-footage-control .dx-datagrid-rowsview .dx-row > td {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.tenant-square-footage-control .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
  display: inline-block !important;
}

.dx-switch {
  width: 40px;
  height: 13px;
}
.dx-switch.dx-switch-on-value .dx-switch-container, .dx-switch.dx-state-hover .dx-switch-container {
  background-color: #ceecda;
}
.dx-switch.dx-switch-on-value .dx-switch-container .dx-switch-handle:before, .dx-switch.dx-state-hover .dx-switch-container .dx-switch-handle:before {
  background: #59b680;
}
.dx-switch .dx-switch-container {
  padding: 0;
  height: 11px;
  overflow: visible;
  border: 0;
  background: #d5d5d5;
  border-radius: 6px;
}
.dx-switch .dx-switch-container .dx-switch-on, .dx-switch .dx-switch-container .dx-switch-off {
  color: transparent;
}
.dx-switch .dx-switch-container .dx-switch-handle {
  margin-top: -1px;
  flex-basis: 13px;
  width: 13px;
  height: 13px;
}
.dx-switch .dx-switch-container .dx-switch-handle:before {
  background: #a5a5a5;
  border-radius: 50%;
}

dx-button.broker-invitation-submit-button {
  margin: 0 !important;
  padding: 5px 25px;
  text-transform: uppercase;
}
dx-button.broker-invitation-submit-button .dx-icon {
  margin-right: 12px;
  color: #ffffff;
  font-size: 14px;
}
dx-button.broker-invitation-submit-button.btn-outline-secondary {
  border: 0;
  box-shadow: inset 0 0 0 1px #a6a6a6;
}

.building-unit-budgets {
  transform: translate(50px, 50px);
}

.building-budget.dialog.dx-popup-wrapper .dx-overlay-content,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content {
  border: none;
  border-radius: 10px;
  background-color: #fff;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-content,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-content {
  height: auto;
  position: relative;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 25px 30px;
  border-bottom: 1px solid #E8EAEE;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content {
  color: #424B5A;
  font-size: 18px;
  font-weight: 500;
  padding-left: 40px;
  letter-spacing: 0.5px;
  text-transform: none;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content:before,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-before .dx-toolbar-label .dx-item-content:before {
  content: "";
  background-image: url("/assets/img/budget.svg");
  position: absolute;
  left: 0;
  top: 7px;
  width: 20px;
  height: 20px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton {
  width: 26px;
  height: 26px;
  background: #E8EAEE;
  border-radius: 8px;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close {
  height: 22px;
}
.building-budget.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close :before,
.building-unit-budgets.dialog.dx-popup-wrapper .dx-overlay-content .dx-popup-title.dx-toolbar .dx-toolbar-after .dx-item-content .dx-closebutton .dx-button-content .dx-icon-close :before {
  color: #686F7B;
  font-size: 12px;
}

.create-marker-dialog.dx-popup-wrapper > .dx-overlay-content {
  border-radius: 22px;
}
.create-marker-dialog.dx-popup-wrapper > .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 14px 22px;
  font-weight: 600;
}
.create-marker-dialog .dx-toolbar-before .dx-item-content {
  display: flex;
  gap: 15px;
  align-items: center;
}
.create-marker-dialog .dx-toolbar-before .dx-item-content > div {
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.8px;
  color: #8E939C;
  text-transform: uppercase;
}
.create-marker-dialog .dx-toolbar-before .dx-item-content:before {
  content: "1";
  background: #59B680;
  border-radius: 50%;
  font-size: 10px;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
}
.create-marker-dialog .dx-popup-title {
  border: none;
  height: auto;
}
.create-marker-dialog .dx-fieldset {
  padding: 0 22px 20px;
  margin: 0;
}
.create-marker-dialog .d-flex {
  gap: 14px;
}
.create-marker-dialog .d-flex dx-button {
  border-radius: 10px;
}
.create-marker-dialog .d-flex dx-button .dx-button-content {
  padding: 10px 45px;
  text-transform: uppercase;
  font-size: 10px;
}

.invite-member-dialog .dx-state-focused.dx-overlay-content {
  border-radius: 22px !important;
}

.improvements-preview-popover .dx-overlay-content {
  border-radius: 18px;
}
.improvements-preview-popover .dx-overlay-content .dx-popover-arrow {
  display: none !important;
}
.improvements-preview-popover .dx-overlay-content .dx-popup-content {
  padding: 0 !important;
  overflow: hidden !important;
}
.improvements-preview-popover .dx-overlay-content .dx-popup-content .popover-inner {
  width: 100%;
  background-color: white;
}

.floor-plan-modal .dx-overlay-content {
  border-radius: 22px;
}

.image-viewer-container .dx-item-content {
  display: flex;
  min-width: fit-content;
}
.image-viewer-container .dx-toolbar-label > div:before {
  content: url("../assets/icons/svg/image-icon.svg");
  position: relative;
  top: 4px;
  margin-right: 20px;
}
.image-viewer-container .dx-icon-close:before {
  background-color: #E8EAEE;
  padding: 6px !important;
  border-radius: 8px;
}

.add-project-notes-container .dx-popup-content {
  background-color: #fff;
}
.add-project-notes-container .dx-widget.real-estate-input {
  max-width: 100%;
  height: 100px;
  padding: 3px 11px 2px 11px;
}
.my-dashboard .dx-item-content.dx-toolbar-item-content {
  padding: 14px 22px;
  border-bottom: 1px solid #E8EAEE;
}

.manage-team-container .dx-popup-content {
  background-color: #fff;
}

.key-statistics-report .dx-overlay-content {
  border-radius: 22px;
  background-color: #fff;
}
.key-statistics-report .dx-popup-content {
  background-color: #fff;
  border-radius: 18px;
  border-top: 1px solid #E8EAEE;
}

.dialog-tooltip .dx-overlay-content {
  background-color: #FFF;
  border-radius: 8px;
}

.request-financial-tooltip .dx-overlay-content {
  border-radius: 22px;
  background-color: #fff;
  border: 1px solid #E8EAEE;
}
.request-financial-tooltip .dx-popup-content {
  background-color: #fff;
  border-radius: 18px;
}

.tour-schedule-info-dialog .dx-overlay-content {
  border-radius: 22px;
  background-color: #fff;
  border: 1px solid #E8EAEE;
}
.tour-schedule-info-dialog .dx-state-focused {
  border: none;
}

.site-plan-dialog-container .dx-overlay-content {
  background-color: #fff;
}

.dx-overlay-content {
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 22px;
}

.lease-commencement-data-piker .dx-texteditor-input, .beneficial-occupancy-data-picker .dx-texteditor-input {
  background-color: #F3F4F6 !important;
  color: #424B5A;
  font-weight: 500;
}
.lease-commencement-data-piker input, .beneficial-occupancy-data-picker input {
  padding-right: 0 !important;
}

.beneficial-occupancy-tag-box input {
  width: 0 !important;
}
.beneficial-occupancy-tag-box .dx-texteditor-input-container .dx-tag-container .dx-native-click {
  display: flex;
  align-items: center;
}

.b-card {
  display: block;
  margin: 0 0 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(77, 77, 77, 0.1);
}
.b-card:hover {
  text-decoration: none;
}
.b-card-header {
  display: flex;
  margin-bottom: 12px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid #E6E6E6;
}
.b-card-header-title {
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
}
.b-card > .b-controls-back {
  margin: 20px 0;
  font-size: 16px;
}
.b-card--full {
  min-height: 100%;
}

.b-card-lease {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #DFDFDF;
}
.b-card-lease-head {
  padding: 12px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #EDF8F2;
  border-radius: 4px 4px 0 0;
}
.b-card-lease-head > .b-header {
  margin: 0;
  color: #29313E;
  font-size: 15px;
  text-transform: capitalize;
}
.b-card-lease-body .b-list-status-header {
  color: #29313E;
  font-weight: 500;
  padding: 14px 20px;
  border-bottom: 1px solid #DFDFDF;
}

.b-card--user {
  margin: 0 0 5px;
  padding: 10px 7px;
}

.b-user-ava {
  width: 100%;
  display: flex;
  align-items: center;
}
.b-user-ava.big .b-user-ava-media {
  width: 54px;
  height: 54px;
}
.b-user-ava-media {
  width: 30px;
  height: 30px;
  flex-shrink: 0;
  margin-bottom: 0;
  overflow: hidden;
  border-radius: 50%;
}
.b-user-ava-media > img {
  width: 100%;
  object-fit: cover;
}
.b-user-ava-name {
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
  padding-left: 15px;
}

.c-card {
  padding: 0 20px 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E2E2E2;
}
.c-card-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  margin: 0 0 26px;
  padding-left: 10px;
  border-left: 4px solid #4D4D4D;
}

.b-building-card {
  display: flex;
  white-space: nowrap;
  align-items: center;
}
.b-building-card-media {
  width: 7rem;
  flex-shrink: 0;
  margin: 10px auto;
}
.b-building-card-title {
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 3px 0;
  white-space: pre-wrap;
}
.b-building-card-subtitle {
  color: #979797;
  font-weight: 400;
}
.b-building-card-inner {
  display: flex;
  font-size: 14px;
  flex-direction: column;
}

.my-styling .b-controls-back,
.my-styling .b-user-ava {
  padding-left: 15px;
}

.b-controls-back .b-controls-back-header {
  color: #59b680;
}

@media (max-width: 425px) {
  .c-card {
    padding: 23px 10px;
  }
}
.b-list {
  margin: 0;
}
.b-list > li + li {
  margin-top: 10px;
}
@media (min-width: 768px) {
  .b-list > li + li {
    margin-top: 30px;
  }
}
.b-list > .b-list-item {
  display: block;
}
.b-list > .b-list-item:hover, .b-list > .b-list-item.active {
  text-decoration: none;
}
.b-list > .b-list-item:hover .b-list-header, .b-list > .b-list-item:hover .b-list-content, .b-list > .b-list-item.active .b-list-header, .b-list > .b-list-item.active .b-list-content {
  color: #4ec280;
}
.b-list > .b-list-item + .b-list-item {
  margin-top: 10px;
}
.b-list-header {
  margin: 0;
  transition: color 0.4s ease-in-out;
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
}
.b-list-header-selected {
  color: #4ec280;
}
.b-list-header-selected > span {
  border-bottom: 1px solid #4ec280;
}
.b-list-header [class^=icon] {
  margin-right: 6px;
}
.b-list-content {
  margin: 0;
  transition: color 0.4s ease-in-out;
  color: #808080;
  font-size: 14px;
}
.b-list-content-link {
  display: block;
  color: #59B680;
}

.b-list-description {
  padding: 0;
  list-style: none;
  margin: 0 0 12px;
}
.b-list-description > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.b-list-description > li > .key {
  color: #5D5D5D;
  font-size: 18px;
  font-weight: 500;
  padding-right: 10px;
}
.b-list-description > li > .value {
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 600;
}
.b-list-description > li + li {
  margin-top: 17px;
}

.b-list--row {
  display: flex;
}
.b-list--row > li {
  flex: 1 1 auto;
}
.b-list--row > li + li {
  margin-top: 0;
  margin-left: 10px;
}

.b-list-status {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;
}
.b-list-status .name {
  width: calc(75% - 17px);
  overflow: hidden;
  padding-right: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.b-list-status .name-row {
  color: #4ec280;
  font-size: 18px;
  font-weight: 500;
  text-decoration: underline;
  text-transform: capitalize;
}
.b-list-status .status {
  width: 20%;
}
.b-list-status .status-row {
  color: #A6A6A6;
  font-size: 13px;
}
.b-list-status .status-title {
  width: 25%;
}
.b-list-status-header {
  display: flex;
  color: #808080;
  font-size: 16px;
  margin-bottom: 20px;
}
.b-list-status > li {
  display: flex;
  align-items: center;
}
.b-list-status > li + li {
  margin-top: 25px;
}

.my-card-lease .b-list-status .name {
  width: 82%;
}

.my-flex-block .b-list-header {
  text-transform: none;
}

.my-dash-blocks .d-block {
  max-height: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-dash-blocks .d-block {
  max-height: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-dash-blocks .d-block:hover {
  text-overflow: initial;
  overflow: auto;
  word-break: break-word;
  white-space: unset;
}

@media (max-width: 1440px) {
  .my-card-lease .b-list-status .name {
    width: 85%;
  }
}
@media (max-width: 1024px) {
  .my-card-lease .b-list-status .name {
    width: 87%;
  }
}
@media (max-width: 414px) {
  .my-second-dash-blocks .b-list {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .my-second-dash-blocks .b-list > li + li {
    margin-top: 0;
  }

  .my-second-dash-blocks .b-list li {
    width: 31%;
  }
}
@media (max-width: 378px) {
  .b-list-status .status {
    width: 23% !important;
  }
}
.b-tab-content {
  padding: 10px;
}
@media (min-width: 768px) {
  .b-tab-content {
    padding: 20px 0;
  }
}

.w-tabs {
  display: block;
}
.w-tabs .nav-tabs {
  background-color: #fff;
  border-bottom-color: #E6E6E6;
}
.w-tabs .nav-tabs .nav-item .nav-link {
  border: none;
  padding: 10px;
  color: #808080;
  border-bottom: 2px solid transparent;
}
.w-tabs .nav-tabs .nav-item .nav-link.active {
  color: #4D4D4D;
  border-bottom-color: #59B680;
}

.my-styling .b-tab-content {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 20px;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 0px 3px 0px #7575757a;
  border-radius: 3px;
  padding-left: 15px;
  padding-right: 15px;
}

.my-styling .dx-tabpanel-container {
  padding-bottom: 30px;
}

.b-indicator {
  color: #808080;
  text-transform: capitalize;
}
.b-indicator.completed:before {
  background-color: #59B680;
}
.b-indicator.in-progress:before {
  background-color: #1D8CE1;
}
.b-indicator.on-hold:before {
  background-color: #C26DFF;
}
.b-indicator:before {
  width: 6px;
  height: 6px;
  content: "";
  margin-right: 1px;
  border-radius: 50%;
  display: inline-block;
  vertical-align: middle;
  background-color: inherit;
}

.step {
  width: 21px;
  height: 21px;
  padding: 1px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  position: relative;
  border-radius: 50%;
  color: white;
  justify-content: center;
  background-color: darkgray;
}

.step-name {
  font-weight: 700;
  opacity: 0.5;
  width: 190px;
}

.step-sub-text {
  opacity: 0.5;
  font-size: 16px;
}

.step-date {
  opacity: 0.5;
}

.step-active {
  opacity: 1;
}

.dashes {
  background-image: url("/assets/img/vertical-dashes.svg");
  background-repeat: repeat-y;
  background-position-x: -19px;
}

.vertical-dash {
  position: absolute;
  transform: rotate(90deg);
  margin-top: 53px;
  left: -2px;
}

.dashes-position {
  padding-bottom: 1rem;
}
.dashes-position:first-child {
  margin-top: 1rem;
}
.dashes-position:last-child {
  background: none;
}

.b-controls {
  display: flex;
  padding: 5px 15px;
  align-items: center;
}
.b-controls-back {
  display: flex;
  color: inherit;
  font-size: 14px;
  align-items: center;
}
.b-controls-back:hover {
  text-decoration: none;
}
.b-controls-back [class^=icon] {
  margin-right: 8px;
}
.b-controls-settings {
  flex: 1 1 auto;
  text-align: right;
}
.b-controls-settings > .btn {
  color: inherit;
  font-size: 14px;
}
.b-controls-settings > .btn + .btn {
  margin-left: 15px;
}

a {
  transition: color 0.4s ease-in-out, border-color 0.4s ease-in-out, background-color 0.4s ease-in-out;
}

button {
  cursor: pointer;
  outline: none !important;
}

.b-onboarding-body-toolbar .b-btn-question:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.b-btn {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}
.b-btn-icon {
  vertical-align: middle;
}
.b-btn--small {
  padding: 5px;
}
.b-btn-primary {
  color: #fff;
  border-color: #59b680;
  background-color: #59b680;
}
.b-btn-primary:hover {
  color: #fff;
  border-color: #439967;
  background-color: #439967;
}
.b-btn-reject {
  color: #fff;
  border-color: #FF9292;
  background-color: #FF9292;
}
.b-btn-reject:hover {
  color: #fff;
  border-color: #ff5f5f;
  background-color: #ff5f5f;
}
.b-btn-default {
  color: #fff;
  border-color: #858585;
  background-color: #858585;
}
.b-btn-default:hover {
  color: #fff;
  border-color: #6c6c6c;
  background-color: #6c6c6c;
}

.b-btn-outline {
  font-size: 12px;
  padding: 3px 6px;
  border: 1px solid;
}
.b-btn-outline-primary {
  color: #686F7B;
  border-color: #DCDFE5;
}
.b-btn-outline-primary:hover {
  border-color: #B3B7BD;
}
.b-btn-outline-reject {
  color: #FF9292;
  border-color: #FF9292;
}
.b-btn-outline-reject:hover {
  color: #ff5f5f;
  background-color: #ffffff;
  border-color: #ff5f5f;
}
.b-btn-outline-light {
  color: #fff;
  border-color: #fff;
}
.b-btn-outline-light:hover {
  color: #cccccc;
  border-color: #cccccc;
}

.b-link {
  border: none;
  color: #A6A6A6;
  font-size: 13px;
  cursor: pointer;
  padding: 0 0 3px;
  vertical-align: top;
  display: inline-block;
  border-bottom: 1px solid;
  text-transform: uppercase;
  background-color: transparent;
}
.b-link:hover {
  color: #59B680;
}

.b-fancy-link {
  color: #59B680;
  font-size: 13px;
  line-height: 1;
  position: relative;
  transition: all 0.2s;
  padding-bottom: 5px;
  vertical-align: top;
  letter-spacing: 0.6px;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 1px solid #59B680;
}
.b-fancy-link:hover {
  text-decoration: none;
  color: #3b875b;
  border-bottom-color: transparent;
}

.b-btn-icon {
  font-size: 14px;
  border: transparent;
  background-color: transparent;
}
.b-btn-icon-info {
  background-color: #E8EAEE;
  width: 18px;
  height: 18px;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.b-btn-icon-info .icon-info {
  display: flex;
  width: 12px;
  height: 12px;
}
.b-btn-icon-info .icon-info:before {
  content: none;
}
.b-btn-icon-info:hover {
  background-color: #DCDFE5;
}

.b-download {
  color: #4D4D4D;
  margin: 0 0 5px;
  font-size: 14px;
  flex-wrap: wrap;
  align-items: center;
  display: inline-flex;
}
.b-download:hover {
  text-decoration: none;
}
.b-download [class^=icon] {
  margin-right: 10px;
}
.b-download-decor {
  width: 100%;
  color: #59B680;
  font-size: 13px;
  padding-left: 20px;
}

.b-link-fancy {
  padding: 0;
  border: none;
  font-size: 13px;
  position: relative;
  padding-bottom: 3px;
  display: inline-block;
  color: #fff !important;
  text-transform: uppercase;
  background-color: transparent;
}
.b-link-fancy:after {
  left: 0;
  bottom: 0;
  width: 16%;
  content: "";
  height: 2px;
  min-width: 30px;
  position: absolute;
  background-color: #fff;
}
.b-link-fancy:hover {
  opacity: 0.6;
  text-decoration: none;
}

.b-btn-question {
  border: none;
  padding: 6px 0;
  font-size: 27px;
  font-weight: 500;
  color: #59b680;
  background-color: transparent;
}
.b-btn-question:hover {
  color: #439967;
}
.b-btn-question > [class^=icon] {
  font-size: 22px;
  vertical-align: middle;
}
.b-btn-question > .icon-rotate-ccw {
  font-size: 40px;
}
.b-btn-question.restart > [class^=icon] {
  margin-right: 13px;
}
.b-btn-question-text {
  display: inline-block;
  vertical-align: middle;
  text-transform: capitalize;
  border-bottom: 1px dashed #59b680;
}

.controls-back {
  z-index: 1;
  top: 15px;
  left: 20px;
  width: 21px;
  color: #fff;
  height: 21px;
  border: none;
  display: none;
  flex-shrink: 0;
  font-size: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #4EC280;
}
@media (max-width: 880px) {
  .controls-back {
    display: flex;
  }
}

.my-div1 .b-btn-icon:hover {
  color: #59b680 !important;
}

.b-btn-icon:focus, .btn-send-message:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.b-btn-sm {
  padding: 4px 8px;
  font-size: 10px;
  text-transform: uppercase;
}

.b-btn-save {
  outline: none;
  background: #59B680;
  border: 1px solid #59B680;
  border-radius: 18px;
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.b-btn-save:hover {
  color: #FFFFFF;
  background: #4BAA72;
  border: 1px solid #4BAA72;
}
.b-btn-save:focus {
  background: #479266;
  border: 1px solid #479266;
}
.b-btn-save:disabled {
  background: #9BD3B3;
  border: 1px solid #9BD3B3;
}
.b-btn-cancel {
  outline: none;
  border: 1px solid #DCDFE5;
  border-radius: 18px;
  background: #ffffff;
  color: #686F7B;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.b-btn-cancel:hover {
  border: 1px solid #B3B7BD;
}
.b-btn-cancel:focus {
  border: 1px solid #686F7B;
  color: #424B5A;
}
.b-btn-cancel:disabled {
  border: 1px solid #DCDFE5;
  color: #B3B7BD;
}
.b-btn-xl {
  max-width: 323px;
  width: 100%;
  height: 52px;
}

.b-dropdown {
  margin-bottom: 10px;
  transition: all 0.4s;
  overflow: hidden;
}
.b-dropdown.collapsed {
  height: 45px;
}
.b-dropdown-link {
  padding-bottom: 5px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  color: #808080;
  font-size: 17px;
  font-weight: 500;
}
.b-dropdown-link:hover {
  color: #4ec280;
  text-decoration: none;
}
.b-dropdown-link:hover .b-dropdown-icon {
  transform: rotate(180deg);
  color: #4ec280;
}
.b-dropdown-link[aria-expanded=true] {
  color: #4ec280;
}
.b-dropdown-link[aria-expanded=true]:hover .b-dropdown-link-title:after {
  transform: scale(0.8, 1);
  background-color: rgba(78, 194, 128, 0.5);
}
.b-dropdown-link[aria-expanded=true] .b-dropdown-link-title:after {
  background-color: #4ec280;
}
.b-dropdown-link[aria-expanded=true] .b-dropdown-link-subtitle {
  display: none;
}
.b-dropdown-link[aria-expanded=true] .b-dropdown-icon {
  transform: rotate(180deg);
  color: #4ec280;
}
.b-dropdown-link-title {
  display: block;
  position: relative;
  text-transform: uppercase;
}
.b-dropdown-link-title:after {
  content: "";
  height: 2px;
  width: 100%;
  display: block;
  position: absolute;
  bottom: -2px;
  left: 0;
  z-index: 25;
  transition: background-color ease-in-out 0.3s, transform ease-in-out 0.3s;
}
.b-dropdown-link-subtitle {
  display: block;
  font-size: 14px;
  font-weight: 400;
}
.b-dropdown-link .b-dropdown-icon {
  color: #808080;
}
.b-dropdown-icon {
  transition: transform 0.4s ease-in-out;
  font-family: "icomoon" !important;
}
.b-dropdown-icon:before {
  content: "";
}
.b-dropdown-content {
  padding: 10px 15px 0;
  transition: height 0.4s;
  /*&.collapsed {
    height: 0;
    overflow: hidden;
  }*/
}

.b-image-stats {
  color: #fff;
}
.b-image-stats .b-list-content-text {
  color: #fff;
}

.w-image {
  margin: 0 0 20px;
  overflow: hidden;
  position: relative;
}
.w-image:after {
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(to bottom, rgba(55, 55, 55, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8c373737", endColorstr="#00ffffff", GradientType=0);
}
.w-image img {
  height: 200px;
  object-fit: cover;
}
.w-image-controls {
  top: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 30px;
  text-align: right;
  position: absolute;
}
.w-image-controls > .b-link {
  color: #fff;
  font-size: 14px;
}
.w-image-controls > .b-link + .b-link {
  margin-left: 30px;
}
.w-image-stats {
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  padding: 30px 25px;
  flex-direction: column;
  justify-content: space-between;
}
.w-image-stats .w-search {
  min-width: 37%;
  align-self: flex-end;
}

.b-input {
  outline: none;
  padding: 7px 12px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
}

.b-label {
  color: #4D4D4D;
  font-size: 13px;
  margin: 0 0 10px;
}

.d-flex {
  outline: none;
}

input {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
}

input::placeholder {
  color: #A0CEE2 !important;
}

label {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
}

.b-radio {
  margin: 0;
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  max-width: 40rem;
  position: relative;
  text-align: center;
  display: inline-block;
  color: #59b680;
  vertical-align: middle;
  margin-bottom: 1%;
}
.b-radio > input[type=radio] {
  top: 0;
  left: 0;
  opacity: 0;
  position: absolute;
}
.b-radio > input[type=radio]:checked ~ .b-radio-media:before {
  color: #fff;
  content: "";
}
.b-radio > input[type=radio]:checked ~ .b-radio-text {
  color: #fff;
  background-color: #59b680;
}
.b-radio-media {
  left: 25px;
  speak: none;
  line-height: 1;
  font-size: 17px;
  font-style: normal;
  position: absolute;
  font-weight: normal;
  top: calc(50% - 2px);
  font-variant: normal;
  text-transform: none;
  transform: translateY(-50%);
  font-family: "icomoon" !important;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.b-radio-text {
  display: block;
  cursor: pointer;
  font-size: 21px;
  font-weight: 400;
  user-select: none;
  padding: 10px 30px;
  border-radius: 30px;
  border: 1px solid #59b680;
}

.b-radio {
  margin-right: 15px;
}

.b-search {
  width: 100%;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  padding-left: 40px;
  border: 1px solid #E6E6E6;
}

.b-select-sort {
  width: 100%;
  cursor: pointer;
  padding: 7px 15px;
  outline: none !important;
  border: 1px solid #E6E6E6;
  text-transform: capitalize;
  background-color: transparent;
}

.is-invalid {
  border-color: #a84948;
}

.error-msg {
  font-size: 14px;
  padding-left: 1.5rem;
  color: #a84948;
  position: relative;
  background-image: url("/assets/img/warning.svg");
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 3px;
}

.w-search {
  display: flex;
  position: relative;
  align-items: center;
}
.w-search > [class^=icon] {
  left: 15px;
  color: #A6A6A6;
  font-size: 15px;
  position: absolute;
}

.w-select-sort {
  position: relative;
}
.w-select-sort > select {
  appearance: none;
}
.w-select-sort > [class^=icon] {
  top: 50%;
  right: 15px;
  font-size: 9px;
  position: absolute;
  pointer-events: none;
  transform: translateY(-50%);
}

.w-form .b-btn {
  min-width: 100px;
}
.w-form .dx-field-value {
  width: 100%;
  float: none;
}
.w-form .dx-radiobutton {
  display: flex;
  width: fit-content;
}
.w-form .dx-radiobutton + .dx-radiobutton {
  margin: 6px 0 0;
}
.w-form .dx-radiobutton .dx-radio-value-container {
  padding-right: 14px;
}
.w-form .dx-radiobutton .dx-radio-value-container .dx-radiobutton-icon {
  width: 26px;
  height: 26px;
}
.w-form .dx-radiobutton .dx-radio-value-container .dx-radiobutton-icon:before {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: none;
  background: #E8EAEE;
}
.w-form .dx-radiobutton .dx-radio-value-container .dx-radiobutton-icon.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
  margin-top: -16px;
  margin-left: 11px;
  width: 5px;
  height: 5px;
  background-color: #424B5A;
}
.w-form .dx-radiobutton.dx-radiobutton-checked .dx-item-content {
  color: #424B5A;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: 3px;
  padding: 0 !important;
}
.w-form .dx-radiobutton:not(.dx-radiobutton-checked) .dx-item-content {
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #8E939C !important;
  position: relative;
  top: 3px;
  padding: 0 !important;
}

@media (max-width: 425px) {
  .my-heading .b-radio-media {
    left: 12px;
  }
}
.b-table {
  width: 100%;
  background-color: #fff;
}
.b-table-sort {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.b-table-sort [class^=icon] {
  font-size: 12px;
  margin-left: 10px;
}
.b-table > thead {
  color: #808080;
  font-size: 13px;
}
.b-table > thead > tr > th {
  padding: 8px;
  font-weight: 500;
}
.b-table > tbody {
  color: #4D4D4D;
  font-size: 14px;
}
.b-table > tbody > tr > td {
  padding: 10px 8px;
}

.b-table-metrics-cell {
  font-size: 15px;
  font-weight: 600;
  text-align: right;
}
.b-table-metrics-title {
  color: #5D5D5D;
  font-size: 18px;
  font-weight: 500;
}
.b-table-metrics thead th {
  padding: 12px 0 0;
  border: none;
  color: #7f7f7f;
  font-size: 16px;
  font-weight: 500;
}
.b-table-metrics thead th:not(:first-child):not(:last-child) {
  padding: 12px 20px 0;
}
@media (max-width: 580px) {
  .b-table-metrics thead th:not(:first-child):not(:last-child) {
    padding: 6px;
  }
}
.b-table-metrics tbody tr:last-of-type td {
  border: none;
}
.b-table-metrics tbody td {
  padding: 12px 0;
  vertical-align: middle;
  border-top: none;
  border-bottom: 1px solid #DFDFDF;
  line-height: 1.4;
}
@media (max-width: 580px) {
  .b-table-metrics tbody td {
    padding: 6px;
    white-space: nowrap;
  }
}
.b-table-metrics tbody td:not(:first-child):not(:last-child) {
  padding: 12px 20px;
}
@media (max-width: 580px) {
  .b-table-metrics tbody td:not(:first-child):not(:last-child) {
    padding: 6px;
  }
}

.b-table-result {
  font-size: 16px;
}
.b-table-result td {
  padding: 3px;
  min-width: 100px;
  border: none;
  text-align: right;
  vertical-align: middle;
}
@media (max-width: 767px) {
  .b-table-result td {
    padding: 3px 6px;
  }
}
.b-table-result td:first-child {
  font-size: 18px;
  text-align: left;
  white-space: nowrap;
}
.b-table-result th {
  min-width: 100px;
  vertical-align: middle;
}
.b-table-result .cell--divider {
  height: 7px;
  background-color: #fff;
}
.b-table-result .cell--divider > td {
  padding: 0;
}
.b-table-result .cell--head {
  border: none;
  color: #7f7f7f;
  font-weight: 500;
  padding: 12px 3px;
  white-space: nowrap;
}
@media (max-width: 767px) {
  .b-table-result .cell--head {
    padding: 10px 6px;
  }
}
.b-table-result .cell--value {
  font-size: 15px;
  font-weight: 600;
}
.b-table-result .cell--title {
  font-weight: 500;
}
.b-table-result .cell--total-title {
  font-weight: 700;
}
.b-table-result .cell--color {
  background-color: #F7F7F7;
}
.b-table-result .cell--color-gradient {
  background: rgba(78, 194, 128, 0.2);
  background: linear-gradient(to bottom, rgba(78, 194, 128, 0.2) 0%, rgba(103, 176, 200, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#4ec280", endColorstr="#67b0c8", GradientType=0 );
}

.b-table-comparison {
  border: 1px solid #dfdfdf;
  font-size: 16px;
  font-weight: 500;
}
.b-table-comparison.table-fixed {
  table-layout: fixed;
}
.b-table-comparison th, .b-table-comparison td {
  background: #ffffff;
  border: none;
  vertical-align: middle;
}
.b-table-comparison th:first-child, .b-table-comparison td:first-child {
  min-width: 270px;
  max-width: 350px;
}
.b-table-comparison th {
  padding: 15px 10px;
  vertical-align: middle !important;
}
.b-table-comparison td {
  padding: 10px 20px;
  min-width: 140px;
  text-transform: capitalize;
}
.b-table-comparison td:first-child {
  background: #73bede;
  color: #ffffff;
  font-size: 15px;
  font-weight: 400;
}
.b-table-comparison .group-title--row td {
  background: #eeeeee;
  border-right: 1px solid #dfdfdf;
  font-size: 17px;
  font-weight: 600;
  white-space: nowrap;
}
.b-table-comparison .group-title--row td:first-child {
  background: #51a6ca;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
}
.b-table-comparison .group-title--row td:last-child {
  border-right: 0;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(even) {
  border-right-color: #ffffff;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(2), .b-table-comparison .group-title--row.headers--row td:nth-child(3) {
  background: #ffecd6;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(4), .b-table-comparison .group-title--row.headers--row td:nth-child(5) {
  background: #e3f1ff;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(6), .b-table-comparison .group-title--row.headers--row td:nth-child(7) {
  background: #d2ffdc;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(8), .b-table-comparison .group-title--row.headers--row td:nth-child(9) {
  background: #ffcfce;
}
.b-table-comparison .group-title--row.headers--row td:nth-child(10), .b-table-comparison .group-title--row.headers--row td:nth-child(11) {
  background: #e5e5e5;
}
.b-table-comparison .date--cell {
  padding: 30px 20px;
  border-right: 1px solid #dfdfdf;
  color: #5d5d5d;
  font-weight: 700;
  text-align: center;
}
.b-table-comparison .date--cell:first-child {
  background: #ededed;
  text-align: left;
}
.b-table-comparison .date--cell:first-child .b-title {
  margin: 0;
}
.b-table-comparison .date--cell:last-child {
  border-right: 0;
}
.b-table-comparison .timeline--cell {
  border-bottom: 1px solid #dfdfdf;
  white-space: nowrap;
}
.b-table-comparison .timeline--cell:first-child {
  border-right: 1px solid #dfdfdf;
}
.b-table-comparison .regular--cell {
  border-right: 1px solid #dfdfdf;
}
.b-table-comparison .regular--cell:last-of-type {
  border-right: none;
}
.b-table-comparison .multiple-options-header {
  background: white !important;
  border-top: 1px solid #dfdfdf !important;
  border-right: 1px solid #dfdfdf !important;
  font-size: 17px;
  font-weight: 600 !important;
  white-space: nowrap;
  color: #212529 !important;
  text-transform: unset !important;
  text-align: center;
}

.b-auth-logo {
  margin-bottom: 40px;
  display: inline-block;
}

.b-auth-text {
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 990px) {
  .b-auth-text {
    max-width: 80%;
  }
}

.w-auth {
  display: flex;
  min-height: calc(100vh - 190px);
  margin-bottom: -1px;
}
@media (max-width: 767.98px) {
  .w-auth {
    min-height: 100vh;
  }
}
@media (max-width: 1000px) {
  .w-auth {
    flex-direction: column;
  }
}
.w-auth-media {
  padding: 50px;
  display: flex;
  flex: 0 1 39%;
  position: relative;
  align-items: center;
}
.w-auth-media:before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: "";
  position: absolute;
  background-color: rgba(70, 143, 100, 0.7);
}
.w-auth-media > .container {
  z-index: 1;
  color: #fff;
  position: relative;
}
.w-auth-content {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: center;
}
.w-auth--sing-in .w-auth-media {
  background: url("/assets/img/sign-in.jpg") no-repeat top center;
  background-size: cover;
}
.w-auth--sing-up .w-auth-media {
  background: url("/assets/img/sign-up.jpg") no-repeat top center;
  background-size: cover;
}
.w-auth--reset-email .w-auth-media {
  background: url("https://picsum.photos/800/1000/?image=56") no-repeat top center;
  background-size: cover;
}

.b-message {
  max-width: 75%;
  display: flex;
  min-width: 300px;
  margin: 0 0 18px 10px;
  width: fit-content;
}
.b-message--self {
  margin-left: auto;
  justify-content: flex-end;
}
.b-message--self .b-message-ava {
  margin: 0 0 0 22px;
}
.b-message--self .b-message-body {
  margin-right: 16px;
  justify-content: flex-end;
}
.b-message--self .b-message-body-date {
  margin-left: 0;
}
.b-message--self .b-message-body-content {
  background-color: #e4f0f5;
  border-radius: 20px 20px 0px 20px;
}
.b-message-ava {
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  overflow: hidden;
  margin: 0 14px 0 0;
  border-radius: 50%;
}
.b-message-body {
  display: flex;
  width: fit-content;
  color: #4d4d4d;
  font-size: 14px;
  flex-wrap: wrap;
  min-height: 90px;
  position: relative;
  border-radius: 7px;
  align-items: center;
}
.b-message-body-author {
  color: #858585;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 2px;
  margin-right: 6px;
  text-transform: capitalize;
}
.b-message-body-content {
  flex: 0 1 content;
  min-width: 35%;
  padding: 15px 15px;
  background-color: #daffea;
  border-radius: 20px 20px 20px 0;
}
.b-message-body-date {
  margin-right: 7%;
  color: #a6a6a6;
  display: block;
  font-size: 12px;
  padding: 0 10px;
}
.b-message-body-text {
  color: #4d4d4d;
  font-size: 15px;
  font-weight: 500;
}

.w-chat {
  padding: 18px 0 0 0;
}
.w-chat-body {
  overflow-y: auto;
  padding-right: 15px;
  flex-direction: column;
}
.w-chat-controls {
  margin-top: 15px;
  position: relative;
  align-items: center;
}
.w-chat-controls > .b-btn {
  right: 15px;
  min-width: 80px;
  position: absolute;
}
.w-chat-controls > .b-input {
  min-height: 70px;
  border-color: #e8e8e8;
  background-color: #f7f7f7;
}

.b-collapse .dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: transparent;
}
.b-collapse .dx-accordion-item {
  border: none;
}
.b-collapse .dx-accordion-item-title {
  display: flex;
  font-size: 14px;
  align-items: center;
  color: #59b680;
  justify-content: center;
  text-transform: capitalize;
}
.b-collapse .dx-accordion-item-title:before {
  order: 2;
  line-height: 1;
  font-size: 12px;
  color: #59b680;
}

.b-list-status {
  margin: 0;
}
.b-list-status-header {
  margin: 0;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-title {
  padding-left: 0;
}
@media (max-width: 1200px) {
  .b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-title {
    padding-left: 20px;
  }
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item + .dx-accordion-item {
  border-top: 1px solid #f2f2f2;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .b-lease-terms-header {
  color: #59b680;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .dx-accordion-item-title:before {
  color: #59b680;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .name-row {
  text-decoration: none;
  color: #59b680;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .b-btn-icon {
  color: #59b680;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item .status-row-indicator {
  width: 8px;
  min-width: 8px;
  height: 8px;
  margin-right: 5px;
  border-radius: 50%;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item .status-row {
  display: flex;
  align-items: center;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item .b-btn-icon {
  color: #d6d6d6;
  font-size: 18px;
}
.b-list-status .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-closed {
  background-color: #fff;
}

.b-dx-select.dx-selectbox {
  font-size: 13px;
  border-color: #DFDFDF;
}
@media (max-width: 580px) {
  .b-dx-select.dx-selectbox {
    width: 100%;
  }
}
.b-dx-select.dx-selectbox.select--year {
  width: 96px;
}
@media (max-width: 580px) {
  .b-dx-select.dx-selectbox.select--year {
    width: 100%;
  }
}
.b-dx-select.dx-selectbox .dx-dropdowneditor-icon {
  color: #59b680;
  font-size: 11px;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.b-dx-select.dx-selectbox .dx-dropdowneditor-icon:before {
  margin: 0;
  content: "";
  transform: translate(-50%, -50%);
}

.w-pagination > .page-item + .page-item {
  margin-left: 4px;
}
.w-pagination > .page-item > .page-link {
  margin: 0;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 4px;
}
.w-pagination > .page-item > .page-link.page-link-divider {
  border: none;
  margin: 0 4px;
  letter-spacing: 0;
}

.w-building {
  padding: 30px 0;
}

app-colabo {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
@media (max-width: 880px) {
  app-colabo app-timeline {
    display: none;
  }
}
app-colabo .container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}
app-colabo .b-card {
  padding: 10px;
}

.colabo-100 {
  display: flex;
  min-height: 100%;
  flex-direction: column;
}
.colabo-100 .container--wide {
  max-width: 100%;
}
.colabo-100 .container {
  padding: 0px;
}

.colabo-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0 1rem 0 1rem;
}
@media (max-width: 1200px) {
  .colabo-content {
    padding: 0;
  }
}
@media (max-width: 1200px) {
  .colabo-content .container {
    padding: 0;
  }
}

app-chat-tree {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-tree-view {
  flex: 1;
  transition: opacity 0.4s;
}
.chat-tree-view .dx-treeview-node-container {
  padding-top: 10px;
}
.chat-tree-view .dx-treeview-node-container .badge {
  top: -8px;
  left: 45px;
}
.chat-tree-view .dx-treeview-node-container .tree-item-name {
  max-width: 250px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.chat-tree-view .dx-treeview-node-container .tree-item-name .name {
  padding-top: 2px;
  padding-bottom: 2px;
  display: inline-block;
  font-size: 18px;
}
.chat-tree-view .dx-treeview-node-container .dx-treeview-node-container .tree-item-name .name {
  font-size: 16px;
}
.chat-tree-view .dx-treeview-item:hover .name {
  border-color: white;
}
.chat-tree-view .dx-treeview-node {
  padding-left: 0;
}
.chat-tree-view .dx-treeview-node .dx-treeview-node {
  padding-left: 15px;
}
.chat-tree-view .dx-treeview-node .dx-treeview-node.dx-state-selected .dx-treeview-item-content {
  font-weight: 700;
}
.chat-tree-view .dx-treeview-node .dx-treeview-node.dx-state-selected .dx-treeview-item-content .tree-item-name .name {
  display: inline-block;
}
.chat-tree-view img {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.16);
  margin: 5px;
}
.chat-tree-view .dx-treeview-toggle-item-visibility:before {
  font-family: FontAwesome;
  content: "";
  display: inline-block;
  font-size: 0.9rem;
  color: #59b680;
}
.chat-tree-view .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened:before {
  font-family: FontAwesome;
  content: "";
  display: inline-block;
  font-size: 0.9rem;
  color: #59b680;
}
.chat-tree-view .dx-treeview-item-content {
  padding-right: 20px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.chat-tree-view .dx-treeview-toggle-item-visibility {
  top: 0;
  right: 0;
  left: auto;
}
.chat-tree-view .dx-item.dx-treeview-item {
  padding: 0;
}
.chat-tree-view .dx-item.dx-treeview-item .dx-template-wrapper > [class^=icon] {
  font-size: 24px;
}

.colabo-columns {
  padding-bottom: 61px;
  padding-top: 18px;
  max-height: calc(100vh - 74px);
  display: flex;
  flex-grow: 1;
}

app-timeline + .colabo-columns {
  max-height: calc(100vh - 169px);
  padding-top: 0;
}

.collapse-button {
  width: 20px;
  height: 20px;
  padding: 3px;
  border: none;
  flex-shrink: 0;
  font-size: 9px;
  font-weight: bold;
  border-radius: 50%;
  background-color: #fff;
}
@media (max-width: 880px) {
  .collapse-button {
    display: none;
  }
}
.collapse-button-mob {
  display: none;
}
@media (max-width: 880px) {
  .collapse-button-mob {
    display: block;
  }
}
.collapse-button span {
  display: inline-block;
  transition: transform 0.4s;
}

.col-channels {
  width: 300px;
  display: flex;
  flex-direction: column;
  transition: all 0.4s;
}
.col-channels.collapsed {
  width: 60px;
}
.col-channels.collapsed .b-header {
  width: 0;
}
.col-channels.collapsed .collapse-button span {
  transform: rotate(180deg);
}
.col-channels.collapsed .chat-tree-view {
  opacity: 0;
}
.col-channels-header {
  margin: 20px;
  display: none;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 880px) {
  .col-channels-header {
    display: flex;
  }
}
.col-channels-header .b-header {
  overflow: hidden;
  position: relative;
  transition: all 0.4s;
  font-size: 18px;
}
.col-channels-header .b-header-notification {
  top: 0;
  width: 18px;
  color: #fff;
  right: -20px;
  height: 18px;
  display: flex;
  font-size: 12px;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #ff5e5e;
}
.col-channels-header .badge {
  margin: 0 0 -1px 5px;
  padding: 0 5px;
  width: auto;
  position: static;
  border-radius: 8px;
}
.col-channels-tree {
  max-height: calc(100% - 53px);
  flex-grow: 1;
}
.col-channels .chat-tree-wrapper {
  padding: 20px 20px 6px;
}

.col-chat {
  display: flex;
  flex: 0 1 40%;
  min-width: 300px;
  margin-left: 5px;
  flex-direction: column;
}
.col-chat-header, .col-chat-tabs {
  flex: 1;
}
.col-chat-chat {
  flex: 20;
  display: flex;
  flex-direction: column;
  background: white;
}
.col-chat-chat app-chat-view {
  min-height: 100%;
}
.col-chat-chat .dx-list .dx-empty-message {
  border-top: 0;
}

.col-chat-tabs {
  font-size: 1rem;
  border-bottom: none;
}
.col-chat-tabs .dx-tabs-wrapper .dx-tab {
  padding: 15px;
  width: 100%;
  margin-left: 0 !important;
  max-width: 300px;
}
.col-chat-tabs .dx-tabs-wrapper .dx-tab:after {
  content: none;
}
.col-chat-tabs .dx-tabs-wrapper .dx-tab .tab-title {
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.075rem;
  color: #8E939C;
  text-transform: uppercase;
}
.col-chat-tabs .dx-tabs-wrapper .dx-tab.dx-tab-selected {
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  background: #F3F4F6;
}
.col-chat-tabs .dx-tabs-wrapper .dx-tab.dx-tab-selected .tab-title {
  color: #424B5A;
}
.col-chat-tabs .badge {
  top: 8px;
  left: auto;
  right: 0;
}
@media (max-width: 880px) {
  .col-chat-tabs {
    padding-left: 63px;
  }
}

.col-lease {
  flex: 1 1 40%;
  min-width: 300px;
}

.b-step {
  display: flex;
  align-items: center;
}
.b-step-media {
  width: 20px;
  color: #fff;
  height: 20px;
  display: flex;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 500;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #59b680;
}
.b-step-text {
  font-size: 14px;
  padding-left: 10px;
}

.w-col-channels {
  z-index: 100;
  border: 1px solid #dfdfdf;
  border-radius: 4px 0 0 4px;
  background-color: #FAFAFB;
}
@media (max-width: 880px) {
  .w-col-channels {
    top: 0;
    bottom: 0;
    left: -130%;
    border: none;
    border-radius: 0;
    position: absolute;
    transition: all ease 0.2s;
  }
}
.w-col-channels.active {
  left: 0;
  width: 100%;
  max-width: 80%;
}
.w-col-channels.active:after {
  top: 0;
  bottom: 0;
  width: 25%;
  left: 100%;
  content: "";
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
}
.w-col-channels .chat-tree-view .dx-treeview-toggle-item-visibility:before {
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  content: "";
}
.w-col-channels .dx-treeview-toggle-item-visibility.dx-treeview-toggle-item-visibility-opened:before {
  color: #fff;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  content: "";
}
.w-col-channels .dx-treeview-item-without-checkbox.dx-state-selected > .dx-treeview-item {
  background: inherit;
}
.w-col-channels .dx-treeview-node {
  color: #fff;
}
.w-col-channels .dx-treeview-node + .dx-treeview-node {
  margin-top: 5px;
}

.w-col-chat {
  margin-left: 18px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
}
@media (max-width: 880px) {
  .w-col-chat {
    margin-left: 0;
    margin-top: 5px;
    min-height: calc(100% - 5px);
  }
}
.w-col-chat.full--screen {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  bottom: 0;
  display: flex;
  position: fixed;
}
.w-col-chat .w-chat {
  padding: 33px 17px;
}
@media (max-width: 880px) {
  .w-col-chat .w-chat {
    padding: 15px;
  }
}

.controls-back {
  z-index: 1;
  top: 15px;
  left: 20px;
  width: 21px;
  color: #fff;
  height: 21px;
  border: none;
  display: none;
  flex-shrink: 0;
  font-size: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #4EC280;
}
@media (max-width: 880px) {
  .controls-back {
    display: flex;
  }
}

.controls-back.right {
  left: calc(100% - 40px);
}

@media (max-width: 375px) {
  .controls-back.right {
    left: calc(100% - 33px);
    top: 12px !important;
  }
}
.w-col-lease {
  padding: 16px 20px;
  background-color: #fff;
  border: 1px solid #dfdfdf;
  border-left: none;
  border-radius: 0 4px 4px 0;
}
@media (max-width: 1200px) {
  .w-col-lease {
    padding: 0px 5px;
  }
}
@media (max-width: 880px) {
  .w-col-lease {
    border-right: 0;
    border-radius: 0;
    border: none;
    padding: 0;
  }
}
.w-col-lease .b-header {
  font-size: 22px;
  margin: 0 0 20px;
}
@media (max-width: 880px) {
  .w-col-lease .b-list-status .name {
    white-space: normal;
    text-overflow: inherit;
  }
}
@media (max-width: 1200px) {
  .w-col-lease .b-list-status-header {
    margin-left: 20px;
  }
}
.w-col-lease .b-list-status .dx-accordion-item-body {
  padding-left: 20px;
  padding-right: 20px;
}
@media (max-width: 1200px) {
  .w-col-lease .b-header {
    margin-left: 20px;
  }
}

.w-mobile-timeline {
  display: none;
  position: relative;
}
@media (max-width: 880px) {
  .w-mobile-timeline {
    display: block;
  }
}
.w-mobile-timeline .b-collapse .dx-accordion-item-title {
  color: #4D4D4D;
  position: relative;
  padding: 24px 15px;
  justify-content: flex-end;
}
.w-mobile-timeline .b-collapse .dx-accordion-item-title:before {
  color: #4D4D4D;
}
.w-mobile-timeline .b-collapse .dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title {
  border-bottom: 1px solid #ddd;
}
.w-mobile-timeline .b-step {
  top: 23px;
  left: 10px;
  position: absolute;
}

.badge {
  padding: 0;
  height: 1rem;
  width: 1rem;
  position: absolute;
  top: -8px;
  left: -8px;
  z-index: 25;
  border-radius: 50%;
  color: white;
  font-size: 12px;
  font-weight: normal;
  line-height: 1rem;
  text-align: center;
}

.badge-danger {
  background-color: rgba(255, 94, 94, 0.8);
}

@media (max-width: 412px) {
  .collapse-button {
    padding: 5px;
  }
}
.tenant-square-footage-popup {
  background-color: rgba(0, 0, 0, 0.643) !important;
}
.tenant-square-footage-popup .dx-overlay-content {
  border-radius: 6px !important;
  box-shadow: none !important;
}
.tenant-square-footage-popup .dx-overlay-content .dx-popup-content {
  padding: 0 !important;
  height: 100% !important;
}
.tenant-square-footage-popup .dx-overlay-content .dx-popup-content .dx-button-content .dx-icon {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}
.tenant-square-footage-popup .dx-overlay-content .dx-popup-content .dx-button-content .dx-button-text {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.tenant-square-footage-popup .dx-overlay-content .dx-popup-content .dx-texteditor.dx-editor-outlined {
  border: 0 !important;
  border-radius: 0 !important;
}
.tenant-square-footage-popup dx-number-box input, .tenant-square-footage-popup dx-select-box input {
  text-align: center !important;
}
.tenant-square-footage-popup .dx-dropdowneditor-button-visible .dx-texteditor-input {
  padding-right: 9px !important;
}
.tenant-square-footage-popup app-tenant-square-footage-phase-in-result td {
  font-weight: 600;
}
.tenant-square-footage-popup app-tenant-square-footage-phase-in-result .table-responsive.with-margin {
  margin: 0;
}

.burn-down-schedule-dialog {
  background-color: rgba(0, 0, 0, 0.643) !important;
}
.burn-down-schedule-dialog .dx-overlay-content {
  border-radius: 6px !important;
  box-shadow: none !important;
}
.burn-down-schedule-dialog .dx-overlay-content .dx-popup-content {
  padding: 0 !important;
  height: 100% !important;
}
.burn-down-schedule-dialog .dx-overlay-content .dx-popup-content .dx-button-content .dx-icon {
  width: 12px !important;
  height: 12px !important;
  font-size: 12px !important;
  line-height: 12px !important;
}
.burn-down-schedule-dialog .dx-overlay-content .dx-popup-content .dx-button-content .dx-button-text {
  font-size: 12px !important;
  font-weight: 500 !important;
}
.burn-down-schedule-dialog .dx-overlay-content .dx-popup-content .dx-texteditor.dx-editor-outlined {
  border: 0 !important;
  border-radius: 0 !important;
}
.burn-down-schedule-dialog app-security-deposit-burn-down-schedule-result td {
  font-weight: 600;
}

.b-card-lease-head .b-header .controls-back.right {
  order: 1;
  top: auto;
  left: auto;
  position: relative;
  margin: 0 0 15px auto;
}
.b-card-lease-head .b-header-controls {
  display: flex;
}
.b-card-lease-head .b-header-controls > .btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.3;
  padding: 6px 12px;
  text-transform: capitalize;
}
.b-card-lease-head .b-header-controls > .btn [class*=dx-icon] {
  font-size: 21px;
}

.b-lease-terms-header {
  color: #4d4d4d;
  font-size: 16px;
  font-weight: 500;
}
.b-lease-terms-controls {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.b-lease-terms-controls .b-btn {
  margin-left: 10px;
}
.b-lease-terms-controls-dropdown {
  display: flex;
  color: #a6a6a6;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 1.3px;
  position: relative;
  align-items: center;
  border-radius: 25px;
  padding: 3px 10px 3px 10px;
  text-transform: uppercase;
  height: 22px;
}
.b-lease-terms-controls-dropdown:hover {
  text-decoration: none;
  color: #8d8d8d;
}
.b-lease-terms-controls-dropdown:before {
  width: 8px;
  height: 8px;
  content: "";
  margin-right: 7px;
  border-radius: 50%;
  background-color: #ccc;
}
.b-lease-terms-controls-dropdown.approved:before {
  background-color: #9DE082;
}
.b-lease-terms-controls-dropdown.pending:before {
  background-color: #F4CB77;
}
.b-lease-terms-controls-dropdown.draft:before {
  background-color: #95D7E4;
}
.b-lease-terms-controls-dropdown.reject:before {
  background-color: #FF9292;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item {
  border-top: none !important;
  border: none;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item-body {
  padding: 15px 48px;
}
@media (max-width: 375px) {
  .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-body {
    padding: 5px 20px !important;
  }
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item .dx-accordion-item-title {
  display: flex;
  align-items: center;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item .dx-accordion-item-title:before {
  width: 17px;
  min-width: 22px;
  height: 22px;
  display: flex;
  margin-left: 0;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  background-image: url("/assets/img/plus-icon.svg");
  font-size: 13px;
  border-radius: 6px;
  background-color: #E8EAEE;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .dx-accordion-item {
  border-color: #DCDFE5;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .dx-accordion-item-title {
  border-color: #DCDFE5;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover .dx-accordion-item-title:before {
  background-color: #DCDFE5;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
  background-color: #FAFAFB;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-accordion-item-opened .dx-accordion-item-title {
  border-bottom-color: #E8EAEE;
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-accordion-item-opened .dx-accordion-item-title:before {
  background-image: url("/assets/img/minus-icon.svg");
}
.b-lease-terms .dx-accordion-wrapper .dx-accordion-item.dx-accordion-item-opened .dx-accordion-item-title:hover {
  color: #424B5A;
}
.b-lease-terms .dx-item.dx-accordion-item.dx-accordion-item-closed {
  background-color: #fff;
  border-bottom: 1px solid #E8EAEE !important;
  border-top: none !important;
}
.b-lease-terms .dx-item.dx-accordion-item.dx-accordion-item-opened {
  border-bottom: 1px solid #e6e6e6;
}
.b-lease-terms .dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title,
.b-lease-terms .dx-accordion-item.dx-accordion-item-opened.dx-state-hover > .dx-accordion-item-title,
.b-lease-terms .dx-accordion-item.dx-item-selected.dx-accordion-item-opened.dx-state-hover > .dx-accordion-item-title {
  border-bottom: 1px solid #e6e6e6;
}
.b-lease-terms .dx-template-wrapper {
  padding: 15px 20px !important;
}
.b-lease-terms .dx-template-wrapper .dx-accordion-item-closed {
  border-bottom: 1px solid #D8D8D8;
}

.w-columns {
  height: 79vh;
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;
}
@media (max-width: 1200px) {
  .w-columns {
    padding: 0 15px;
  }
}
@media (max-width: 880px) {
  .w-columns {
    padding: 0;
    height: auto;
  }
}
.w-columns .b-card {
  padding: 10px;
}
.w-columns > .b-header {
  width: 100%;
}
.w-columns > .b-card + .b-card {
  margin-left: 5px;
}
.w-columns-channels {
  margin: 0;
  flex: 1;
  min-width: 150px;
}
.w-columns-chat {
  margin: 0;
  flex: 2;
}
.w-columns-lease {
  margin: 0;
  min-width: 460px;
  flex: 2;
}
.w-columns-lease-attribute {
  max-width: calc(60% - 30px);
  flex: 1 1 calc(60% - 30px);
}
.w-columns-comments {
  flex: 0 1 40%;
  display: flex;
  flex-direction: column;
  max-width: 40%;
  border-radius: 4px;
  margin: 0 0 0 15px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
}
@media (max-width: 880px) {
  .w-columns-comments {
    display: none;
  }
}
.w-columns-comments.full--screen {
  top: 0;
  left: 0;
  right: 0;
  margin: 0;
  height: 100%;
  display: block;
  max-width: 100%;
  position: fixed;
}
.w-columns-comments.full--screen .w-columns-comments-head {
  padding-left: 50px;
}
.w-columns-comments.full--screen .w-columns-comments-body {
  padding-bottom: 130px;
}
.w-columns-comments-head {
  height: 56px;
  display: flex;
  position: relative;
  padding: 18px 20px;
  align-items: center;
  border-bottom: 1px solid #F5F5F5;
}
.w-columns-comments-head > .controls-back {
  top: auto;
  left: auto;
  margin-right: 15px;
  position: relative;
}
.w-columns-comments-head > .b-header {
  margin: 0 15px 0 0;
  overflow: hidden;
  font-weight: 600;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media (max-width: 880px) {
  .w-columns-comments-head > .b-header {
    text-transform: capitalize;
  }
}
.w-columns-comments-head > .b-badge {
  color: #29313E;
  line-height: 1;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px;
  margin-left: auto;
  border-radius: 20px;
  background-color: #D8D8D8;
  text-transform: uppercase;
}
.w-columns-comments-head > .b-badge.b-badge-warning {
  color: #C69739;
  background-color: #FEEAC2;
}
.w-columns-comments-body {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.w-columns-comments-form {
  margin-top: auto;
}
.w-columns-comments-form .message--form {
  position: relative;
}
.w-columns-comments-form .message--form .dx-texteditor.dx-editor-outlined {
  border: none;
  display: flex;
  border-radius: 0;
  min-height: 70px;
  padding-right: 60px;
  flex-direction: column;
  border-top: 1px solid #E9E9E9;
}
.w-columns-comments-form .message--form .dx-texteditor.dx-editor-outlined .dx-texteditor-container {
  display: flex;
  flex: 1 1 100%;
  flex-direction: column;
}
.w-columns-comments-form .message--form .dx-texteditor.dx-editor-outlined .dx-texteditor-container .dx-texteditor-input {
  flex: 1 1 100%;
}
.w-columns-comments-form .message--form .b-btn {
  top: 50%;
  right: 20px;
  font-size: 22px;
  position: absolute;
  color: #59b680;
  transform: translateY(-50%);
}

@media (max-width: 880px) {
  .b-message {
    margin: 0 0 38px 0;
    flex-direction: column;
  }
}
@media (max-width: 880px) {
  .b-message-ava {
    margin-bottom: 15px;
  }
}

.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
.dx-field-value-static {
  width: 100%;
}

@media (max-width: 425px) {
  .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-body {
    padding: 15px 30px !important;
  }
}
@media (max-width: 412px) {
  .b-lease-terms-controls {
    width: 32% !important;
  }

  .badge {
    opacity: 1;
  }
}
@media (max-width: 425px) {
  .w-columns-comments-head {
    padding: 18px 3px;
  }

  .w-columns-comments-head .controls-back {
    margin-right: 6px;
  }

  .w-columns-comments-head .b-header {
    margin-right: 10px;
  }

  .w-columns-comments-head .b-badge {
    max-width: 200px;
  }

  .b-lease-terms-header {
    max-width: 145px;
  }
}
colabo-messages {
  flex: 1;
  display: flex;
}
colabo-messages .messages-container {
  flex: 1;
  display: flex;
}
colabo-messages .messages-container .b-card {
  padding: 10px;
}
colabo-messages .col-chat {
  flex: 2;
}

app-chat-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-scroll {
  flex: 20;
  margin: 5px 5px 0 5px;
  background-color: rgba(255, 255, 255, 0.5);
  overflow: hidden;
}

.chat-input-container {
  flex: 1;
  margin-top: 5px;
}

.item-container {
  padding-right: 15px;
}

.chat-button-icon {
  font-size: 24px;
  color: #59b680;
  margin-top: 3px;
}

.send-button {
  width: 50px;
  margin: 5px 0px 0px 5px;
}

.chat-editor,
.messages-editor {
  padding: 0;
  margin-right: 5px;
  resize: none;
  border: 1px solid #dddddd;
  width: calc(100% - 41px);
  min-height: 36px;
}
.chat-editor .dx-htmleditor-content,
.messages-editor .dx-htmleditor-content {
  display: flex;
  align-items: center;
  scrollbar-width: none;
}
.chat-editor .dx-htmleditor-content::-webkit-scrollbar,
.messages-editor .dx-htmleditor-content::-webkit-scrollbar {
  display: none;
}

.chat-editor .dx-htmleditor-content {
  padding: 15px 50px 15px 20px;
}

.messages-editor .dx-htmleditor-content {
  padding: 15px 50px 15px 50px;
}
.messages-editor .dx-htmleditor-content p:first-child {
  overflow: hidden;
  padding-left: 1px;
}

.dateseparator {
  background-color: powderblue;
  color: white;
  text-shadow: 1px 1px black;
  line-height: 1.5;
  padding: 1px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
}

.daterow {
  padding: 25px 25% 25px 25%;
  float: left;
  width: 100%;
}

.messagetime {
  color: skyblue;
  padding: 0 5px 0 5px;
}

.mymessage .messagetime {
  float: right;
}

.menupoints {
  float: right;
  color: #447486;
  margin: 0 -12px -5px 0;
  margin-bottom: -5px;
  cursor: pointer;
}

.chat .item {
  white-space: normal;
  border: none;
  width: calc(100% - 80px);
  float: left;
  padding: 0px 0px 0px 5px;
}
.chat .username {
  color: darkblue;
  font-size: 14px;
}
.chat .content {
  background-color: #ffffe6;
  line-height: 1.7;
  padding: 5px;
  margin: 1px;
  border-radius: 3px;
  text-align: left;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
}
.chat .mymessage .content {
  text-align: right;
}
.chat .datetime {
  text-align: center;
  padding: 0 4px;
  color: cornflowerblue;
  font-size: 12px;
}
.chat .message {
  width: 100%;
  word-wrap: break-word;
}
.chat .mymessage {
  text-align: right;
  float: right !important;
}
.chat .mymessage .content {
  background-color: #e3fed5;
}
.chat .image-url {
  width: 92%;
  height: 92%;
  margin: 0 12px;
}
.chat-avatar {
  height: 50px;
  width: 50px;
}

.messagecontent p {
  margin: 0;
  padding: 0;
}

.w-accordion .dx-accordion-item-body {
  padding: 0;
}
.w-accordion .dx-accordion-item {
  padding: 17px 20px 10px;
  min-height: 55px;
  background: #f9f9fa;
  border: 0 !important;
  border-radius: 4px;
}
.w-accordion .dx-accordion-item + .dx-accordion-item {
  margin-top: 20px;
}
.w-accordion .dx-accordion-item-title {
  padding: 0 0 15px;
  color: #4D4D4D;
  font-size: 16px;
  font-weight: 500;
}
.w-accordion .dx-accordion-item-title:before {
  transform: rotate(-90deg);
}
.w-accordion .dx-accordion-item.dx-state-hover > .dx-accordion-item-title, .w-accordion .dx-accordion-item.dx-state-active > .dx-accordion-item-title {
  background-color: transparent;
}
.w-accordion .dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title:before {
  transform: rotate(180deg);
}

.lease-abstract .w-accordion .dx-accordion-item {
  box-shadow: none;
  background: #fafafa;
  border: 0 !important;
  border-bottom: 1px solid #dedede !important;
  border-radius: 0;
}
.lease-abstract .w-accordion .dx-accordion-item:first-child {
  /*border-top: 1px solid #dedede !important;*/
}
.lease-abstract .w-accordion .dx-accordion-item + .dx-accordion-item {
  margin-top: 0;
}
.lease-abstract .w-accordion .dx-accordion-item-title:before {
  transform: rotate(0);
}
.lease-abstract .w-accordion .dx-accordion-item.dx-state-hover {
  background: #f5f5f5;
}
.lease-abstract .w-accordion .dx-accordion-item.dx-state-active {
  background: rgba(96, 96, 96, 0.2);
}
.lease-abstract .w-accordion .dx-accordion-item.dx-accordion-item-opened {
  background: #ffffff;
}
.lease-abstract .w-accordion .dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title {
  color: #59b680;
}
.lease-abstract .w-accordion .dx-accordion-item.dx-accordion-item-opened > .dx-accordion-item-title:before {
  transform: rotate(0);
  color: #59b680;
}

@media (max-width: 425px) {
  .w-accordion .dx-accordion-item {
    padding: 13px 10px;
  }
}
.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
.carousel-inner, .carousel-item, .carousel .item {
  width: 100%;
  height: 100%;
}
.carousel img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 18px;
}
.carousel video {
  display: block;
  width: 100%;
  height: 100%;
}
.carousel-indicators {
  position: absolute;
  top: auto;
  bottom: 15px;
  margin: 0;
  padding: 0;
}
.carousel-indicators > li {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #e8eaee;
  opacity: 1;
}
.carousel-indicators > li.active {
  background-color: #ffffff;
}
.carousel-control {
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 30px;
  height: 30px;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.carousel-control.left {
  right: auto;
  left: 20px;
}
.carousel-control.right {
  left: auto;
  right: 20px;
}
.carousel-control > span {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  width: 30px;
  height: 30px;
  border-radius: 9px;
  background: unset;
  background-color: #f3f4f6;
  transition: background-color 0.2s ease-in-out;
  border: 0;
}
.carousel-control > span.carousel-control-prev-icon:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTguNzkyMDYgMTBMMTQuMzA3NiAxNS4wNTU4TDEyLjczMjEgMTYuNUw1LjY0MDk1IDEwTDEyLjczMjEgMy41TDE0LjMwNzYgNC45NDQyMkw4Ljc5MjA2IDEwWiIgZmlsbD0iIzY4NkY3QiIvPgo8L3N2Zz4=");
  background-position: center center;
  background-size: cover;
}
.carousel-control > span.carousel-control-next-icon:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTEyLjIzMzMgMTBMNi43MTc3NyA0Ljk0NDIyTDguMjkzMzMgMy41TDE1LjM4NDQgMTBMOC4yOTMzMyAxNi41TDYuNzE3NzcgMTUuMDU1OEwxMi4yMzMzIDEwWiIgZmlsbD0iIzQyNEI1QSIvPgo8L3N2Zz4=");
  background-position: center center;
  background-size: cover;
}
.carousel-control:hover > span {
  background-color: #dcdfe5;
}
.sm .carousel-control {
  margin-top: -8px;
  width: 16px;
  height: 16px;
}
.sm .carousel-control > span {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #e8eaee;
  padding: 4px 0;
  border: 0;
}
.sm .carousel-control > span.carousel-control-prev-icon:before {
  width: 8px;
  height: 8px;
  margin-left: -1px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBpZD0iY2hldnJvbiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0zLjI1IDRMNS43NSAxLjVWNi41TDMuMjUgNFoiIGZpbGw9IiM0MjRCNUEiLz4KPC9nPgo8L3N2Zz4K");
  background-position: center center;
  background-size: cover;
}
.sm .carousel-control > span.carousel-control-next-icon:before {
  width: 8px;
  height: 8px;
  margin-right: -1px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBpZD0iY2hldnJvbiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01Ljc1IDRMMy4yNSA2LjVMMy4yNSAxLjVMNS43NSA0WiIgZmlsbD0iIzQyNEI1QSIvPgo8L2c+Cjwvc3ZnPgo=");
  background-position: center center;
  background-size: cover;
}

carousel.controls-hidden .carousel-control {
  opacity: 0;
  pointer-events: none;
}

.trigger-tooltip {
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #f9f9fa;
  border-radius: 50%;
}
.trigger-tooltip svg {
  height: 8px;
}

.v2-default-tooltip.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  padding: 6px 8px;
  border-radius: 8px;
}
.v2-default-tooltip.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content .tooltip-menu {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  background-color: #686F7B;
  color: #FFFFFF;
}

.dark-bcg-tooltip.dx-tooltip-wrapper .dx-overlay-content .dx-popup-content {
  background-color: #686F7B;
  color: #FFFFFF;
  border-radius: 8px;
}

.tour-schedule-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
}
.tour-schedule-calendar .tour-schedule-calendar-header {
  margin-bottom: 24px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tour-schedule-calendar .tour-schedule-calendar-header-buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tour-schedule-calendar .tour-schedule-calendar-header-button {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  background: none;
}
.tour-schedule-calendar .tour-schedule-calendar-header-button > i {
  width: 16px;
  height: 16px;
}
.tour-schedule-calendar .tour-schedule-calendar-header-button > i > svg {
  display: block;
  width: 16px;
  height: 16px;
  color: #424B5A;
}
.tour-schedule-calendar .tour-schedule-calendar-header-button:disabled {
  cursor: default;
}
.tour-schedule-calendar .tour-schedule-calendar-header-button:disabled > i > svg {
  color: #B3B7BD;
}
.tour-schedule-calendar .tour-schedule-calendar-header-current-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #424b5a;
}
.tour-schedule-calendar .tour-schedule-calendar-content-legend {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tour-schedule-calendar .tour-schedule-calendar-content-legend > div {
  width: 34px;
  min-width: 34px;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #424B5A;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-row {
  width: 100%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-row:not(:last-child) {
  margin-bottom: 15px;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell {
  width: 34px;
  min-width: 34px;
  height: 34px;
  background-color: transparent;
  border-radius: 50%;
  border: none;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell > span {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #8E939C;
  transition: color 0.2s ease-in-out;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell:disabled {
  cursor: default;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--today > span:after {
  content: "";
  position: absolute;
  left: calc(50% - 3px/2);
  bottom: -1.5px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: #8E939C;
  transition: background-color 0.2s ease-in-out;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--available {
  background-color: #F1F9F4;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--available > span {
  font-weight: 500;
  color: #356D4D;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--available > span:after {
  background-color: #356D4D;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--selected {
  background-color: #59B680;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--selected > span {
  font-weight: 500;
  color: #FFFFFF;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--selected > span:after {
  background-color: #FFFFFF;
}
.tour-schedule-calendar .tour-schedule-calendar-content-dates-cell--hidden {
  opacity: 0;
  pointer-events: none;
}
.tour-schedule-calendar .tour-schedule-calendar-footer {
  margin-top: 24px;
}
.tour-schedule-calendar .tour-schedule-calendar-footer-timezone {
  display: flex;
  align-items: center;
}
.tour-schedule-calendar .tour-schedule-calendar-footer-timezone > i {
  margin-right: 7px;
  width: 16px;
  height: 16px;
}
.tour-schedule-calendar .tour-schedule-calendar-footer-timezone > i > svg {
  display: block;
  width: 16px;
  height: 16px;
  color: #424B5A;
}
.tour-schedule-calendar .tour-schedule-calendar-footer-timezone > span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #8E939C;
}

.modal-dialog .modal-content.tour-schedule-dialog {
  padding: 0;
  border-radius: 10px;
}

app-tour-schedule-dialog {
  height: 100%;
  overflow: hidden;
}

.tour-schedule-dialog-container {
  display: flex;
  height: 100%;
  padding: 0;
  overflow: hidden;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #424b5a;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header {
  padding: 15.5px 30px;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: solid 1px #dcdfe5;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #424B5A;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header--noborder {
  padding: 16px 30px;
  border: none !important;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header-title {
  flex: 1;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .close-button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: none;
  background-color: #e8eaee;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .close-button:hover {
  background-color: #dcdfe5;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .close-button:active {
  background-color: #dcdfe5;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .close-button > i {
  width: 16px;
  height: 16px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .close-button > i > svg {
  width: 16px;
  height: 16px;
  float: left;
  fill: #686f7b;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .info-icon {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8eaee;
  cursor: pointer;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header .info-icon > i > svg {
  width: 12px;
  height: 12px;
  fill: #686f7b;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-header p {
  margin-bottom: 0;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-content {
  flex: 1;
  flex-direction: column;
  display: flex;
  padding: 30px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-content-wrapper {
  max-height: 230px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-pane-footer {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  color: #8e939c;
}
.tour-schedule-dialog-container .tour-schedule-dialog-left-pane {
  width: 261px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-middle-pane {
  width: 620px;
  border-left: solid 1px #dcdfe5;
  border-right: solid 1px #dcdfe5;
}
.tour-schedule-dialog-container .tour-schedule-dialog-right-pane {
  width: 300px;
  overflow: hidden;
}
.tour-schedule-dialog-container .tour-schedule-dialog-right-pane .tour-schedule-dialog-pane-header {
  gap: 0;
  justify-content: space-between;
}
.tour-schedule-dialog-container .today {
  font-weight: bold;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar {
  flex: 1;
  padding: 30px 6px 30px 30px;
  display: flex;
  overflow: hidden;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-wrapper {
  width: 310px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-selected-date {
  padding-right: 16px;
  padding-bottom: 26px;
  font-size: 14px;
  font-weight: 500;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-time-slots {
  margin-left: 30px;
  height: 100%;
  min-width: 220px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-time-slots-list {
  flex: 1;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-time-slot-button {
  padding: 12px;
  width: 100%;
  min-height: 52px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: center;
  text-transform: uppercase;
  color: #686f7b;
  background-color: #ffffff;
  border: 1px solid #dcdfe5;
  border-radius: 18px;
  outline: inherit;
  cursor: pointer;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-time-slot-button:hover {
  border-color: #b3b7bd;
}
.tour-schedule-dialog-container .tour-schedule-dialog-calendar-time-slot-button--active {
  color: #215c3a;
  background-color: #F1F9F4;
  border-color: #9BD3B3;
}
.tour-schedule-dialog-container .time-slot-option-button {
  background: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  width: 220px;
  min-height: 45px;
  color: #424b5a;
  border: 1px solid #dcdfe5;
  border-radius: 13px;
}
.tour-schedule-dialog-container .time-slot-option-button--active {
  background-color: #F1F9F4;
}
.tour-schedule-dialog-container .approve-button {
  color: white;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  width: 300px;
  min-height: 50px;
  background-color: #59b680;
  border: 1px solid #dcdfe5;
  border-radius: 18px;
}
.tour-schedule-dialog-container .approve-button:disabled {
  background-color: #8e939c;
  cursor: default;
}
.tour-schedule-dialog-container .greeting {
  color: #424b5a;
  font-size: 18px;
  padding-bottom: 70px;
}
.tour-schedule-dialog-container .approve-time-slot-options {
  max-width: 93%;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.tour-schedule-dialog-container .approve-time-slot-buttons {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.tour-schedule-dialog-container .building-info {
  display: flex;
  align-items: center;
  gap: 14px;
}
.tour-schedule-dialog-container .building-info img {
  border-radius: 10px;
  object-fit: cover;
}
.tour-schedule-dialog-container .building-info-address {
  flex: 1;
}
.tour-schedule-dialog-container .building-info-address p {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0;
  color: #424b5a;
}
.tour-schedule-dialog-container .building-info-unit-name {
  font-size: 14px;
  font-weight: 400;
  color: #8e939c;
}
.tour-schedule-dialog-container .tour-length-info {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.tour-schedule-dialog-container .tour-length-info > i {
  margin-right: 6px;
  width: 20px;
  height: 20px;
}
.tour-schedule-dialog-container .tour-length-info > i > svg {
  width: 20px;
  height: 20px;
  color: #424B5A;
}
.tour-schedule-dialog-container .tour-length-info > span {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8E939C;
}
.tour-schedule-dialog-container .picked-time-slots-button {
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  min-height: 45px;
  width: 100%;
  border-radius: 13px;
  background-color: #59b680;
  color: white;
}
.tour-schedule-dialog-container .picked-time-slots-badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  padding-bottom: 10px;
}
.tour-schedule-dialog-container .picked-time-slots-date-heading {
  font-size: 14px;
  font-weight: 500;
  color: #424b5a;
  padding-bottom: 10px;
}
.tour-schedule-dialog-container .picked-time-slots-badges {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 7px;
  padding-bottom: 10px;
}
.tour-schedule-dialog-container .picked-time-slots-no-data {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
.tour-schedule-dialog-container .picked-time-slots-no-data > i {
  width: 24px;
  height: 24px;
}
.tour-schedule-dialog-container .picked-time-slots-no-data > i > svg {
  width: 24px;
  height: 24px;
  color: #424B5A;
}
.tour-schedule-dialog-container .picked-time-slots-no-data p {
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #8E939C;
}
.tour-schedule-dialog-container .picked-time-slots-no-data .subtitle {
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #8E939C;
}
.tour-schedule-dialog-container .picked-time-slots-badge {
  padding: 0 10px;
  height: 22px;
  display: flex;
  align-items: center;
  gap: 2px;
  background-color: #D1E8DC;
  border-radius: 100px;
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #215C3A;
}
.tour-schedule-dialog-container .picked-time-slots-badge > i {
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.tour-schedule-dialog-container .picked-time-slots-badge > i > svg {
  display: block;
  width: 12px;
  height: 12px;
  color: #356D4D;
}
.tour-schedule-dialog-container .picked-time-slots-badge--gray {
  color: #424B5A;
  background-color: #E8EAEE;
}
.tour-schedule-dialog-container .picked-time-slots-too-many {
  text-align: center;
}
.tour-schedule-dialog-container .picked-time-slots-confirm-button {
  min-height: 52px;
  width: 100%;
  color: white;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.075rem;
  line-height: 24px;
  text-align: center;
  background-color: #59b680;
  border: none;
  border-radius: 18px;
  text-transform: uppercase;
}
.tour-schedule-dialog-container .picked-time-slots-confirm-button:hover:not(:disabled) {
  background-color: #4BAA72;
}
.tour-schedule-dialog-container .picked-time-slots-confirm-button:disabled {
  background-color: #9BD3B3;
}
.tour-schedule-dialog-container .picked-time-slots-cancel-button {
  margin-top: 10px;
  min-height: 52px;
  width: 100%;
  color: #686F7B;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.075rem;
  line-height: 24px;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #DCDFE5;
  border-radius: 18px;
  text-transform: uppercase;
  transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;
}
.tour-schedule-dialog-container .picked-time-slots-cancel-button:hover {
  border-color: #B3B7BD;
}
.tour-schedule-dialog-container .picked-time-slots-cancel-button:active {
  color: #424B5A;
  border-color: #8E939C;
}
.tour-schedule-dialog-container .tour-cancel {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #8e939c;
  font-size: 14px;
  text-decoration: underline;
  user-select: none;
}
.tour-schedule-dialog-container .tour-cancel > i {
  width: 12px;
  height: 12px;
}
.tour-schedule-dialog-container .tour-cancel > i svg {
  width: 12px;
  height: 12px;
  display: block;
}
.tour-schedule-dialog-container .counterpart-cell {
  margin-top: 15px;
  display: flex;
  align-items: center;
}
.tour-schedule-dialog-container .counterpart-cell-images-container {
  display: inline-block;
  position: relative;
  width: 43px;
  min-width: 43px;
  height: 43px;
  min-height: 43px;
}
.tour-schedule-dialog-container .counterpart-cell-user-avatar {
  display: block;
  width: 29px;
  height: 29px;
  background-color: #f3f4f6;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  border: 2px solid white;
}
.tour-schedule-dialog-container .counterpart-cell-user-avatar_empty i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 0.76px solid #b3b7bd;
}
.tour-schedule-dialog-container .counterpart-cell-user-avatar_empty i > svg {
  width: 16.88px;
  height: 16.88px;
  fill: #424b5a;
}
.tour-schedule-dialog-container .counterpart-cell-company-logo {
  display: block;
  position: absolute;
  top: 15.5px;
  left: 15.5px;
  width: 26px;
  height: 26px;
  background-color: #f3f4f6;
  border-radius: 6px;
  overflow: hidden;
}
.tour-schedule-dialog-container .counterpart-cell-company-logo_empty {
  border: 0.62px solid #b3b7bd;
}
.tour-schedule-dialog-container .counterpart-cell-company-logo_empty i {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.tour-schedule-dialog-container .counterpart-cell-company-logo_empty i > svg {
  width: 14.86px;
  height: 14.86px;
  fill: #424b5a;
}
.tour-schedule-dialog-container .counterpart-cell-info {
  margin-left: 14px;
}
.tour-schedule-dialog-container .counterpart-cell-info-user {
  display: block;
  color: #424b5a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.tour-schedule-dialog-container .counterpart-cell-info-name {
  display: inline-block;
  color: #8e939c;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}
.tour-schedule-dialog-container .counterpart-cell-info-status {
  display: inline-block;
  margin-bottom: -5px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #e8eaee;
}
.tour-schedule-dialog-container .counterpart-cell-info-status i > svg {
  margin-top: 4px;
  margin-left: 4.5px;
  width: 9px;
  height: 9px;
  fill: #686f7b;
}

.tour-scheduled-info {
  background-color: #fff;
}
.tour-scheduled-info-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #DCDFE5;
}
.tour-scheduled-info-header > h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #424B5A;
}
.tour-scheduled-info-header > button {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e8eaee;
  border-radius: 8px;
  border: none;
  outline: none;
  transition: background-color 0.2s ease-in-out;
}
.tour-scheduled-info-header > button:hover {
  background-color: #dcdfe5;
}
.tour-scheduled-info-header > button:active {
  background-color: #dcdfe5;
}
.tour-scheduled-info-header > button > i {
  width: 16px;
  height: 16px;
}
.tour-scheduled-info-header > button > i > svg {
  width: 16px;
  height: 16px;
  float: left;
  fill: #686f7b;
}
.tour-scheduled-info-content {
  padding: 30px;
}
.tour-scheduled-info-content .building-info {
  display: flex;
  flex-direction: row;
  gap: 7px;
}
.tour-scheduled-info-content .building-info i svg {
  width: 20px;
  height: 20px;
}
.tour-scheduled-info-content .building-info-unit-name {
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #686F7B;
}
.tour-scheduled-info-content .building-info-unit-name:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 1px;
  background-color: #686F7B;
}
.tour-scheduled-info-content .company-info {
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.tour-scheduled-info-content .company-info > img {
  width: 34px;
  height: 34px;
  border-radius: 8px;
}
.tour-scheduled-info-content .company-info-party {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.tour-scheduled-info-content .company-info-party-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #424B5A;
}
.tour-scheduled-info-content .company-info-party-existing-tenant-badge {
  margin-top: 3px;
  padding: 1px 5px;
  display: inline-block;
  border-radius: 5px;
  border: 0.5px solid #59b680;
  background-color: #f9f9fa;
  color: #59b680;
  font-size: 9px;
  font-weight: 400;
  line-height: 14px;
  letter-spacing: 0.45px;
  text-transform: uppercase;
}
.tour-scheduled-info-content .tour-info {
  border: 1px solid #f3f4f6;
  background-color: #FAFAFB;
  padding: 20px;
  margin-top: 15px;
  border-radius: 10px;
}
.tour-scheduled-info-content .tour-info-caption {
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #8E939C;
}
.tour-scheduled-info-content .tour-info-date {
  margin-top: 12px;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.03em;
  color: #424B5A;
}
.tour-scheduled-info-content .tour-info-time-slot {
  margin-top: 12px;
  display: flex;
  align-items: center;
  gap: 7px;
}
.tour-scheduled-info-content .tour-info-time-slot > i {
  width: 20px;
  height: 20px;
}
.tour-scheduled-info-content .tour-info-time-slot > i > svg {
  display: block;
  width: 20px;
  height: 20px;
}
.tour-scheduled-info-content .tour-info-time-slot span {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #686F7B;
}
.tour-scheduled-info-content .tour-info-edit-button {
  margin-top: 12px;
  padding: 6px 11px;
  display: flex;
  align-items: center;
  gap: 4px;
  border: none;
  outline: none;
  background: #ffffff;
  border-radius: 9px;
}
.tour-scheduled-info-content .tour-info-edit-button > i {
  width: 12px;
  height: 12px;
}
.tour-scheduled-info-content .tour-info-edit-button > i > svg {
  display: block;
  width: 12px;
  height: 12px;
}
.tour-scheduled-info-content .tour-info-edit-button > span {
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #424B5A;
}