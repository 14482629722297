@import "./variables";

.dx-widget, .dx-overlay-wrapper,
.dx-widget input, .dx-widget textarea {
  font-family: $avenir-font;
}

.dx-treelist {
  overflow: hidden;
  border: 1px solid #d8d8d8;
  border-radius: 4px;

  .dx-treelist-header-panel {
    padding: 12px 20px;
    border-color: #d8d8d8;

    .dx-toolbar {
      margin: 0;

      .dx-toolbar-after .dx-toolbar-item {
        padding: 0 0 0 10px;

        .dx-button {
          border-radius: 50%;
          border: 0;

          &.dx-button-mode-contained {
            background-color: #9b9b9b;
            color: #ffffff;

            &.dx-state-hover {
              background-color: $color-primary;
            }
          }

          .dx-button-content {
            padding: 4px;

            .dx-icon {
              color: #ffffff;
              font-size: 14px;
            }
          }
        }

        .dx-treelist-search-panel.dx-texteditor.dx-editor-outlined {
          width: 250px !important;
          border: none;

          .dx-icon-search:before {
            color: $color-primary;
          }

          .dx-texteditor-input {
            background: #dbffe5;
          }

          .dx-placeholder {
            color: #747474;
          }
        }
      }
    }
  }

  .dx-treelist-headers {
    border-color: #d8d8d8;

    .dx-treelist-table .dx-row > td {
      padding: 10px;
      border: none;
      font-size: 15px;
      text-transform: uppercase;

      &.dx-treelist-select-all {
        padding-left: 44px;
      }

      [class^="icon-"] {
        margin-left: 5px;
      }
    }
  }

  .dx-treelist-rowsview {
    border-top: none;

    tbody {
      tr {
        td {
          padding: 0 10px 10px;
          border: 0;
          font-size: 16px;

          .dx-treelist-empty-space {
            width: 25px;

            &.dx-treelist-collapsed span:before {
              content: '\f016';
            }

            &.dx-treelist-expanded span:before {
              content: '\f014';
              color: $color-primary;
            }
          }

          &:first-child {
            font-weight: 500;
          }
        }

        .dx-treelist-icon-container.dx-editor-inline-block {
          padding-left: 10px;
          padding-right: 25px;

          .dx-checkbox-icon {
            width: 18px;
            height: 18px;
            box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.25);
            border-radius: 3px;
            border: 1px solid #cacaca;
            font-size: 10px;
          }
        }

        &[aria-level="0"] {
          td {
            padding: 13px 10px;
            background: #fafafa;
            border-top: 1px solid #d8d8d8;
            font-size: 18px;
          }
        }

        &[aria-expanded="true"] {
          td {
            background: $color-white;

            &:first-child {
              color: $color-primary;
            }
          }
        }

        &:first-child {
          td {
            border-top: 0;
          }
        }
      }
    }
  }
}

.dx-treelist-filter-row {
  border-bottom: none !important;
}

.dx-treelist-borders > .dx-treelist-pager,
.dx-treelist-borders > .dx-treelist-headers,
.dx-treelist-borders > .dx-treelist-filter-panel {
  border-top: none;
}

.dx-treelist-content .dx-treelist-table {
  border: none;
}

.dx-treelist .dx-treelist-pager {
  border-top: none;
  padding: 5px;
}

.dx-treelist .mode-button {
  padding: 8px;
  margin: -3px -5px -7px -5px;
  min-width: 34px;
  height: 34px;
}

.documents .dx-row td:focus {
  outline: transparent !important;
}

.dx-header-filter-indicator {
  padding-left: 10px;
}

.button-group-last {
  margin-right: 10px;
}

.popup-button-ok {
  float: right;
}

.popup-button-cancel {
  float: right;
  margin-right: 10px;
}

.popup-box {
  height: 100%;
}

.treeview-item-source {
  color: $color-primary;
  height: 30px;
  margin: -4px -5px -4px -5px;
  padding: 5px;
  background-color: white;
}

.dx-treeview-item-without-checkbox.dx-state-focused > .dx-treeview-item * .treeview-item-source {
  color: $color-primary;
}

.dx-tabs {
  border: none;
  border-bottom: 1px solid #d8d8d8;

  .dx-scrollable-container {
    overflow-x: auto;

    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border: 1px solid #fff;
    }

    &::-webkit-scrollbar-button {
      display: none;
    }
  }

  .dx-tabs-wrapper {
    display: flex;

    .dx-tab {
      background-color: transparent;

      &:after {
        content: '';
        height: 2px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0;
        z-index: 25;
        transition: background-color ease-in-out .3s, transform ease-in-out .3s;
      }

      &:hover:after {
        transform: scale(.8, 1);
        background-color: rgba($color-green, .5);
      }

      &.dx-tab-selected {
        background-color: transparent;

        &:after {
          top: auto;
          right: 0;
          left: 0;
          background-color: $color-green;
          border: 0;
        }

        &:hover:after {
          background-color: rgba($color-green, .5);
        }
      }

      & + .dx-tab {
        margin-left: 8px;
      }

      .dx-tab-text {
        color: #4d4d4d;
        font-size: 16px;
        font-weight: 500;
      }
    }
  }
}

.tabs-rounded {
  width: auto !important;
  border-bottom: 0;

  .dx-tabs-wrapper .dx-tab {
    padding: 7px 25px 8px;
    transition: background-color ease-in-out .3s;
    border-bottom: 0;
    border-radius: 18px;
    line-height: 1;

    &:after {
      display: none;
    }

    &:hover, &:focus {
      background-color: rgba($color-green, .1);
    }

    &.dx-tab-selected {
      background-color: $color-green;

      .dx-tab-text {
        color: $color-white;
      }
    }

    .dx-tab-text {
      font-size: 15px;
      line-height: 20px;
      letter-spacing: 0.125px;
      text-transform: none !important;
      color: #2C2C2C;
    }
  }
}

.dx-tabs-scrollable {
  margin: 0 25px;

  .dx-tabs-wrapper {
    border-top: 0;
    border-bottom: 0
  }
}

.uploader-no-padding .dx-fileuploader-wrapper,
.uploader-no-padding .dx-fileuploader-input-wrapper {
  padding: 0;
  border: none;
}

.mode-menu-item {
  padding-left: 19px;
}

.mode-menu-item.checked {
  padding-left: 5px;
}

.dx-invalid {
  border-color: $color-error;
  background-image: none;
}

.dx-invalid.dx-texteditor.dx-editor-filled .dx-texteditor-container:after,
.dx-invalid.dx-texteditor.dx-editor-underlined .dx-texteditor-container:after,
.dx-invalid.dx-texteditor.dx-editor-outlined .dx-texteditor-container:after {
  display: none;
}

.dx-invalid.dx-texteditor {
  border-color: $color-error;
}

.dx-invalid-message {
  display: none;
}

.avatar-image {
  display: flex;
  align-items: center;

  .avatar-block {
    margin-right: 15px;
    flex-shrink: 0;
  }

  > a {
    white-space: normal;
  }
}

@media(max-width: 1200px) {
  .avatar-image {
    flex-direction: column;

    .avatar-block {
      margin: 0 0 5px;
    }

    > a {
      text-align: center;
    }
  }
}

.tenants-grid .dx-row .dx-data-row .dx-column-lines > td {
  border: none;
}

.lease-accordion .dx-accordion-item-title-caption {
  font-size: 14px;
}

.lease-accordion .dx-accordion-item {
  border-right: none;
  border-left: none;
}

.dx-checkbox {
  position: relative;
  bottom: 3px;
  margin-right: 10px;

  &.dx-state-focused .dx-checkbox-icon,
  &.dx-state-hover .dx-checkbox-icon {
    border: none;
  }

  .dx-checkbox-container {
    text-overflow: clip;
    height: auto;
  }

  .dx-checkbox-icon {
    width: 25px;
    height: 25px;
    border-radius: 8px;
    border: none;
    background: #E8EAEE;
  }

  & + label {
    font-size: 14px;
    font-weight: 400;
    color: #8E939C;
  }

  &.dx-checkbox-has-text {
    .dx-checkbox-text {
      font-size: 14px;
      font-weight: 400;
      color: #8E939C;
    }

    &.dx-checkbox-checked {
      .dx-checkbox-text {
        font-weight: 500;
        color: #424B5A;
      }
    }
  }

  &.dx-checkbox-checked {
    .dx-checkbox-icon {
      &:before {
        content: '';
        background: url("/assets/img/check-icon.svg") no-repeat center;
        width: 20px;
        height: 20px;
        margin-top: -9px;
        margin-left: -10px;
      }
    }

    & + label {
      color: #424B5A;
    }
  }
}

.dx-textarea .dx-placeholder {
  color: #8E939C;

  &.dx-widget.dx-invalid .dx-invalid-message .dx-overlay-wrapper {
    top: 11px !important;
  }

}

.dx-widget.dx-treeview {
  width: auto !important;
}

.dx-widget.tree-list {
  font-weight: normal;
  font-size: 14px;
}

.dx-widget.real-estate-input {
  max-width: 323px;
  width: 100%;
  background: #F3F4F6;
  border: none;
  padding: 5px 0 5px 5px;
  opacity: 1;

  &.dx-texteditor.dx-editor-outlined {
    border-radius: 10px;
  }

  &.dx-datebox {
    .dx-texteditor-container {
      flex-direction: row-reverse;
      padding-left: 5px;
    }
  }

  .dx-texteditor-container {
    border-radius: 14px;

    .dx-texteditor-input {
      background: #F3F4F6;
      border-radius: 10px;
      font-size: 14px;
      font-weight: 500;
      color: #424B5A;
    }
  }

  &.dx-numberbox.rounded-10 {
    border-radius: 10px;
  }

  &.dx-tagbox {
    .dx-tag-container .dx-tag-content {
      border-radius: 15px;
      background-color: #D1EBDC;
      color: #356D4D;
      padding: 3.5px 25px 3.5px 10px;
      display: flex;
      align-items: center;

      .dx-tag-remove-button {
        right: 4px;
        top: -1px;

        &:after {
          content: '';
          background: url("/assets/img/close-tag.svg") no-repeat center;
          transform: none;
          width: 12px;
          height: 12px;
          top: calc(50% - 5px);
          margin-top: 0;
          filter: invert(37%) sepia(12%) saturate(1481%) hue-rotate(93deg) brightness(97%) contrast(93%);
        }

        &:before {
          content: none;
        }
      }
    }

    &.dx-state-focused, &:not(.dx-texteditor-empty):active {
      border: 1px solid #9BD3B3;
    }
  }
}

.dx-overlay-wrapper {
  .dx-overlay-content:not(.dx-popup-normal) {
    border-radius: 14px;
    background: #F3F4F6;

    .dx-list-item-content {
      padding: 9px 22px !important;
      background: #F3F4F6;
      font-size: 14px;
      font-weight: 500;
      color: #424B5A;
    }
  }
}

.dx-widget.v2-checkbox {
  .dx-checkbox-icon {
    width: 26px;
    height: 26px;
    border: none;
    border-radius: 8px;
    background-color: #E8EAEE;

    &:before {
      font-size: 12px;
      color: #424B5A;
    }
  }

  .dx-checkbox-text {
    padding-left: 36px;
    margin-left: -26px;
  }
}

.dx-widget.no-invalid-message {
  &.dx-invalid {
    .dx-invalid-message {
      display: none;
    }
  }
}

.dx-widget {
  &.dx-invalid {
    &:not(.dx-radiogroup) {
      border: 1px solid #F09C94;
      border-radius: 10px;
    }

    .dx-item:not(.dx-state-disabled) {
      .dx-radiobutton-icon:before {
        border: 1px solid #F09C94 !important;
      }
    }

    .dx-invalid-message {
      display: block;
      visibility: visible;

      .dx-overlay-wrapper, .dx-overlay-content {
        transform: none !important;
        background: transparent;
        color: #E75A4D;
        padding: 10px 0;
        text-transform: uppercase;
        letter-spacing: 0.055rem;
        font-size: 10px;
        font-weight: 500;
        white-space: nowrap;
        max-width: fit-content !important;
      }
    }
  }
}

.dx-overlay-wrapper.unit-action-tooltip {
  display: none;

  @media(max-width: 1150px) {
    display: block;
  }

  .dx-overlay-content {
    border: none;
    border-radius: 8px;
    background: #686F7B;

    .dx-popup-content {
      padding: 6px 8px;

      span {
        color: #FFFFFF;
        font-size: 11px;
        font-weight: 400;
      }
    }

    .dx-popover-arrow {
      display: none;
    }
  }
}

.dx-overlay-wrapper.no-border {
  .dx-overlay-content {
    border: none;
    border-radius: 10px;
  }
}

.dx-overlay-wrapper {
  .html-editor-menu.dx-overlay-content.dx-context-menu.dx-menu-base {
    .dx-submenu {
      border-radius: 14px;
      border: 1px solid #F3F4F6;
      background: #F3F4F6;
      padding: 9px 22px;
      display: flex;
      align-items: center;
      justify-items: center;

      .dx-menu-item-content {
        padding: 9px 0;

        i {
          color: #424B5A;
          font-size: 16px;
        }

        .dx-menu-item-text {
          font-size: 14px;
          font-weight: 500;
          color: #424B5A;
          padding-left: 33px;
          font-family: $avenir-font;
        }
      }
    }
  }
}

.list-space-dialog.dx-popup-wrapper {
  .dx-overlay-content {
    border: none;
    border-radius: 20px;
    background-color: #fff;

    .dx-popup-title.dx-toolbar {
      padding: 30px;
      border-bottom: 1px solid #E8EAEE;

      .dx-toolbar-items-container {
        height: 20px;
      }

      .dx-toolbar-before {
        .dx-toolbar-label .dx-item-content {
          padding-left: 40px;
          color: #424B5A;
          font-size: 18px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.03em;

          &:before {
            content: "";
            background-image: url("/assets/img/list-space.svg");
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      .dx-toolbar-after {
        .dx-item-content {
          .dx-closebutton {
            margin: -3px 0 0 0;
            width: 26px;
            height: 26px;
            background: #E8EAEE;
            border-radius: 8px;

            .dx-button-content {
              display: flex;
              justify-content: center;
              align-items: center;

              .dx-icon-close {
                height: 22px;

                :before {
                  color: #686F7B;
                }
              }
            }
          }
        }
      }
    }
  }
}

.landlord-cancel-lease {
  .dx-toolbar-label {
    .dx-item-content {
      div {
        padding-left: 40px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          background-image: url(../assets/img/trash.svg);
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          width: 20px;
          height: 20px;
          top: 2px;
        }
      }
    }
  }

  .dx-overlay-content {
    border-radius: 20px;
  }

  .dx-toolbar-item-content {
    font-weight: 500;
    font-size: 18px;
    text-transform: capitalize;
    color: #424B5A;
  }
}

.main-actions {
  gap: 20px;

  .dx-button {
    width: 323px;
    height: 52px;
    border-radius: 18px;
    padding: 0;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 1.3px;
    text-transform: uppercase;
    box-shadow: none;

    &.dx-button-cancel {
      border: 1px solid #DCDFE5;
      background: #fff;
      color: #686F7B;
      box-shadow: none;

      &:hover {
        background-color: #fff;
        border: 1px solid #B3B7BD;
      }

    }

    &.dx-button-mode-contained.dx-button-success {
      color: #fff;
      border-color: #59b680;
      background-color: #59b680;
      min-width: 120px;

      &:hover {
        color: #fff;
        border-color: #439967;
        background-color: #4BAA72;
      }
    }
  }
}

.column-input .dx-checkbox-checked .dx-checkbox-icon {
  color: #4EC280;
  background: #ffffff;
}

.dx-header-row {
  font-weight: 500;
  font-size: 13px;
}

.column-input .dx-header-row {
  font-weight: 500;
  font-size: 13px;
  background: #73BEDE;
  color: white;
  height: 84px;
}

.dx-datagrid .dx-header-row > td {
  border-left: none;
  border-right: none;
}

.dx-tooltip-wrapper {

  .dx-overlay-content {
    border: none;
    border-radius: 10px;
    transition: all .15s ease-in-out;

    .dx-popover-arrow {
      display: none;
    }

    .dx-popup-content {
      white-space: normal;
      padding: 15px;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      text-align: left;

      .tooltip-menu {
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        text-align: left;
      }
    }
  }

  &.small-tooltip {
    .dx-overlay-content .dx-popup-content {
      padding: 6px 8px;
      font-size: 11px;
    }
  }

  &.white-tooltip {
    .dx-overlay-content {
      background-color: #F3F4F6;

      .dx-popup-content {
        background: inherit;
        border-radius: 14px;
        color: #424B5A
      }
    }
  }

  &.tooltip-arrow {
    .dx-overlay-content .dx-popover-arrow {
      display: block;
      left: calc(50% - 12px) !important;
      height: 13px;
      width: 24px;

      &:after {
        content: none;
      }

      &:before {
        content: "";
        position: absolute;
        background-color: #686F7B;
        bottom: 5px;
        left: calc(50% - 10px);
        clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
        transform: rotate(-45deg);
        border-radius: 0 0 0 0.3em;
        width: 20px;
        height: 20px;
      }
    }
  }
}

.only-safari {
  background-color: white !important;
  width: fit-content !important;
  height: fit-content !important;
  border: 1px solid gray;
}

#eversignContainerId iframe {
  display: block;
  margin: auto;
  border: 0;
}

.active-hover.dx-tooltip-wrapper {
  .dx-popup-content {
    padding: 20px;
  }
}

.nsm-content {
  padding: 0 !important;
  margin: 0 !important;
}

.dx-datagrid-headers .dx-datagrid-table .dx-row > td {
  border-bottom: none;
}

.dx-datagrid-content .dx-datagrid-table .dx-row > td.dx-datagrid-group-space {
  padding-left: 8px;
}

.dx-texteditor-input {
  border-radius: 0;
}

.column-input .dx-datagrid-filter-row td {
  background: #51A6CA;
}

.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table tbody .dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu,
.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container .dx-texteditor-input,
.column-input .dx-datagrid .dx-datagrid-nowrap .dx-datagrid-content .dx-datagrid-table .dx-row .dx-editor-cell .dx-texteditor-container {
  background: #51A6CA !important;
  color: #A0CEE2;
}

.dx-icon-filter-operation-default {
  display: none !important;
}

.column-input .dx-datagrid-headers .dx-texteditor-input,
.column-input .dx-datagrid-rowsview .dx-texteditor-input {
  border-bottom: 1px solid #A0CEE2;
}

.dx-datagrid-headers .dx-texteditor-input,
.dx-datagrid-rowsview .dx-texteditor-input {
  padding: 7px;
  padding-bottom: 1px;
  margin-bottom: 6px;
  margin-left: 3px;
  width: 80%;
}

.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-texteditor-input,
.dx-datagrid-filter-row .dx-editor-cell .dx-editor-with-menu .dx-placeholder:before {
  padding-left: 10px;
}

.dx-datagrid-filter-panel .dx-datagrid-filter-panel-text,
.dx-datagrid-filter-panel .dx-icon-filter {
  display: none;
}

.dx-datagrid .dx-column-lines > .dx-col-1 {
  border-right: transparent !important;
}

.terms-form .dx-fieldset {
  margin: 0;
}

.terms-form .dx-button-has-text .dx-button-content {
  padding: 2px 18px 2px;
}

.terms-form .escalation-table .dx-widget {
  margin-bottom: 0;
}

.my-dashboard .dx-toolbar-after {
  width: 100%;
  padding: 0;
  position: static;

  .dx-item-content .dx-toolbar-item-content {
    padding: 14px 22px;
  }

  .dx-texteditor.dx-editor-outlined {
    flex-grow: 1;
    background: transparent;
    border: none;
    border-radius: 0;

    .dx-icon-search {
      height: 42px;
      width: 50px;
      font-size: 20px;
      line-height: 1;
      text-align: left;
    }

    .dx-texteditor-input {
      max-width: 150px;
      background: #F3F4F6;
      border-radius: 10px;
    }

    .dx-placeholder:before {
      padding-left: 50px;
    }
  }
}

.my-dashboard .dx-searchbox .dx-icon-search:before {
  color: #424B5A;
}

.my-dashboard .dx-toolbar .dx-toolbar-items-container {
  height: auto;
}

.my-dashboard .dx-datagrid .dx-row > td {
  vertical-align: middle !important;
  font-size: 14px;
  font-weight: 500;

  &:nth-child(n+2) {
    padding-left: 14px;
    padding-right: 14px;
  }

  &.dx-master-detail-cell {
    background: #f0f0f0;
  }
}

.my-dashboard .dx-datagrid-content .dx-datagrid-table .dx-row .dx-command-expand {
  .dx-datagrid-group-opened, .dx-datagrid-group-closed {
    width: 18px;
  }
}

.my-dashboard .column-input .dx-header-row {
  background: #fafafa;
  color: #8E939C8e;
  height: 68px;
  text-transform: uppercase;

  & > td {
    outline: transparent !important;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.8px;
    color: #8E939C;
  }
}

.my-dashboard .dx-datagrid-rowsview .dx-row {
  border: none;
  min-height: 62px;
  border-top: 1px solid #ddd;
}

.my-dashboard .dx-datagrid-header-panel,
.my-dashboard .dx-datagrid-rowsview,
.my-dashboard .dx-datagrid-pager,
.my-dashboard .dx-datagrid .dx-column-lines > td {
  border: none !important;
}

.my-dashboard .dx-datagrid-header-panel .dx-toolbar {
  margin: 0;
}

.my-dashboard .dx-datagrid-pager {
  .dx-page-sizes .dx-page-size,
  .dx-pages .dx-page {

    width: 35px;
    text-align: center
  }
}

.suites-grid {
  .dx-datagrid-headers {
    border-bottom: 0;

    .dx-header-row {
      background: #FAFAFB;
    }

    .dx-row {
      & > td {
        padding: 13px 10px !important;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1.3px;
        color: #8E939C;
        text-transform: uppercase;

        &:nth-child(2) {
          padding-left: 30px !important;
        }

        &:first-child {
          padding-left: 22px !important;
        }

        &:last-child {
          padding-right: 22px !important;
        }
      }
    }
  }

  .dx-datagrid-rowsview .dx-row {
    & > td {
      padding: 15px 10px !important;
      font-size: 14px;
      font-weight: 500;
      color: #424B5A;

      &:nth-child(2) {
        padding-left: 30px !important;
      }

      &:first-child {
        padding-left: 22px !important;
      }

      &:last-child {
        padding-right: 22px !important;
      }

      .cell-suite {
        width: fit-content;

        &-content {
          width: fit-content;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: nowrap;
          white-space: nowrap;

          > a {
            margin-left: 10px;
          }
        }

        .link-popover {
          font-size: 0;
          line-height: 0;
          display: block;
          height: 21px;
          cursor: pointer;

          > img {
            height: 21px;
            filter: invert(68%) sepia(6%) saturate(2747%) hue-rotate(92deg) brightness(92%) contrast(89%);
          }
        }
      }

      .cell-size {
        white-space: nowrap;
      }

      .cell-base-rate {
        a {
          color: $color-primary;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
        }

        .cell-base-rate-tooltip {
          margin-left: 9px;
          width: 16px;
          height: 16px;
          background: #E8EAEE;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }

      .cell-status {
        .b-btn {
          margin-top: 5px;
          padding: 7px 15px;
          text-transform: uppercase;
          white-space: nowrap;
        }

        .cell-status-text {
          font-size: 10px;
          font-weight: 500;
          text-transform: uppercase;
          padding: 3px 10px;
          border-radius: 15px;
          width: fit-content;
          background: #D1EBDC;
          color: #356D4D;

          &.vacant {
            background: #E8EAEE;
            color: #424B5A;
          }

          &.renewal, &.restructure {
            background: #DBDDFC;
            color: #333B99;
          }

          &.expired {
            background: #FCD0CC;
            color: #8F372F;
          }
        }
      }

      .cell-contact {
        padding-right: 15px;

        .cell-contact-name {
          gap: 14px;

          p {
            margin-bottom: 3px;
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            color: #424B5A;
            white-space: nowrap;
          }

          span {
            font-size: 14px;
            font-weight: 400;
            color: #8E939C;

            a {
              color: inherit;
            }

            &:hover {
              a {
                color: inherit;
                text-decoration: none;
              }
            }
          }
        }

        .avatar-image .avatar-block {
          margin-right: 10px;
        }
      }

      .cell-lease-start {
        > div {
          display: flex;
          align-items: center;
          gap: 6px;

          p {
            margin-bottom: 0;
          }

          span {
            width: 16px;
            height: 16px;
            background: #E8EAEE;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          svg {
            color: #686F7B;
            width: 10px;
            height: 10px;
          }
        }
      }
    }

    &:nth-last-child(-n + 2) {
      border-bottom: 1px solid #E8EAEE;
    }

    &:nth-child(even) {
      background: #FAFAFB;
    }
  }
}

.dx-datagrid-focus-overlay {
  border: 2px solid transparent;
}

//Suites datagrid end

//Proposals datagrid start

.proposals-grid {
  .dx-datagrid-headers {
    border-bottom: 0;

    .dx-row {
      height: 52px;
      border-top: 0;

      & > td {

        font-size: 11px;
        font-weight: 500;
        letter-spacing: 0.8px;
      }

      td:first-child {
        padding-left: 30px !important;

      }
    }
  }

  .dx-datagrid-rowsview .dx-row {
    height: 48px;
    border-top: 0;

    & > td {

      font-size: 14px;

      &:nth-child(n+1) {
        padding-top: 14px;
        padding-bottom: 14px;
      }

      &:last-child {

      }
    }

    td:first-child {
      padding-left: 30px !important;

    }
  }
}

//Proposals datagrid end

.my-card-lease {
  padding-bottom: 61px;

  @media(max-height: 1000px) {
    padding-bottom: 0;
  }
}

.wrapper-for-empty-message .dx-empty-message {
  margin-left: 40px;
}

.dx-dropdowneditor-icon {
  background: transparent !important;
  border: 0;
  color: #29313e;
  font-family: 'icomoon', sans-serif;
  font-size: 14px;

  &:before {
    content: '';
    background: url("/assets/img/arrow-down-action.svg") no-repeat center;
    width: 12px;
    height: 12px;
    top: calc(50% + 3px);
    left: auto;
    right: 14px;
  }
}

.dx-datebox-calendar .dx-dropdowneditor-icon:before {
  content: '';
  background: url("/assets/img/calendar.svg") no-repeat center;
  width: 20px;
  height: 20px;
  top: calc(50% - 1px);
}

@media(max-width: 425px) {
  .wrapper-for-empty-message .dx-empty-message {
    margin-top: -23px;
  }
}

.dx-accordion-item-body {
  font-size: 16px;
}

.dx-popup-wrapper > .dx-overlay-content .dx-popup-title.dx-toolbar {
  padding: 15px 30px 12px;
  font-weight: 600;
}

.image-viewer-container, .document-viewer-container {
  .dx-popup-content {
    padding: 0;
  }
}

.driver-stage-no-animation {
  &:before {
    content: '';
    position: fixed;
    z-index: 100002;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
  }
}

.dx-field {
  font-family: "Avenir Next Cyr", sans-serif !important;
}

.certificate-of-insurance-dialog > .dx-overlay-content {
  overflow-y: auto;
  border-radius: 0;

  .dx-popup-title.dx-toolbar {
    padding: 15px 30px 12px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .dx-popup-content {
    padding: 20px 30px 0;
  }
}

.input-field {
  &.input-field-with-prefix {
    .dx-numberbox {
      &.dx-widget.dx-invalid .dx-invalid-message .dx-overlay-wrapper {
        left: -30px !important;
      }
    }
  }
}

.dx-list-group-header {
  padding: 10px !important;
}

.selectable-input-wrapper {
  .input-field {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    padding-right: 0 !important;
    border-right: 1px solid #DCDFE5;
  }

  .dx-numberbox {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    min-width: 100px;
    width: 100px;
  }

  .dx-selectbox {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    width: 100%;

    input {
      text-transform: uppercase;
    }
  }
}

.dx-selectbox-popup-wrapper .dx-list-item.dx-list-item-selected,
.dx-selectbox-popup-wrapper .dx-list-item.dx-state-hover {
  background-color: transparent !important;
  color: #5461F0 !important;
}

.dx-selectbox-popup-wrapper .dx-list-item.dx-state-active {
  background-color: rgba(black, 3%) !important;
}

.dx-selectbox-popup-wrapper .dx-list-item {
  background-color: transparent;
  color: #424B5A;
  padding: 3px 0;
}

.dx-selectbox-popup-wrapper .dx-list-item .dx-list-item-content {
  font-family: 'Avenir Next Cyr', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0;
  text-align: left;
}

.dx-selectbox-popup-wrapper .dx-list {
  background-color: transparent;

  &:hover {
    .dx-list-item {
      color: #424B5A !important;

      &.dx-state-hover,
      &.dx-state-active {
        color: #5461F0 !important;
      }
    }
  }
}

.dx-tagbox-popup-wrapper .dx-list .dx-list-item {
  .dx-item-content {
    position: relative;
    padding-left: 40px !important;

    &:before, &:after {
      content: '';
      position: absolute;
      left: 20px;
      top: 50%;
      margin-top: -7px;
      width: 14px;
      height: 14px;
    }

    &:before {
      background-color: white;
      border-radius: 7px;
    }

    &:after {
      opacity: 0;
      background: url("data:image/svg+xml,%3Csvg width='8' height='5' viewBox='0 0 8 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.99994 3.82372L7.33298 0L8 0.588137L2.99994 5L0 2.35297L0.666549 1.76483L2.99994 3.82372Z' fill='%23424B5A'/%3E%3C/svg%3E%0A") 50% 50% no-repeat;
    }
  }

  &.dx-state-hover,
  &.dx-state-active,
  &.dx-list-item-selected {
    color: #5461F0 !important;

    .dx-item-content:after {
      opacity: 1;
    }
  }
}

.dx-selectbox-popup-wrapper {
  .dx-popup-content {
    padding: 0;
    padding-top: 6px;
    background: transparent;

    .dx-list {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;
      background-color: #F3F4F6;
      box-shadow: 0 2px 20px 0 rgba(black, 5%);
    }
  }

  .dx-toolbar:last-child {
    border-top: 1px solid mix(#F3F4F6, black, 94%);
    padding: 0;
    padding-bottom: 6px;
    background: transparent;

    .dx-toolbar-items-container {
      height: unset !important;
      padding: 10px 20px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      overflow: hidden;
      background-color: #F3F4F6;
    }
  }

  .dx-overlay-content {
    background-color: transparent;
    border-radius: 0;
    overflow: unset !important;
    border: 0;
    box-shadow: none;

    .dx-popup-content .dx-list-item-content {
      padding: 10px 20px;
    }
  }
}

.dx-selectbox-popup-wrapper:not(.dx-popup-cancel-visible):not(.dx-popup-done-visible) {
  .dx-popup-content {
    padding: 6px 0;
    background: transparent;

    .dx-list {
      border-radius: 10px;
      overflow: hidden;
      box-shadow: 0 2px 20px 0 rgba(black, 5%);
      background-color: #F3F4F6;
    }
  }

  .dx-overlay-content {
    background-color: transparent;
    border-radius: 0;
    overflow: unset !important;
    border: 0;
    box-shadow: none;

    .dx-popup-content .dx-list-item-content {
      padding: 10px 20px;
    }
  }
}

.analytics-filters-accordion .dx-accordion-wrapper {
  .dx-accordion-item {
    border: 0;

    &.dx-state-hover {
      .dx-accordion-item-title {
        color: $color-primary;
      }
    }

    .dx-accordion-item-title {
      font-weight: 500;
    }
  }
}

.tenant-square-footage-control {
  margin: -20px;

  .dx-datagrid-table, .dx-datagrid-rowsview {
    border: 0 !important;
  }

  .dx-datagrid-rowsview .dx-row {
    height: auto !important;
    border: 0 !important;

    > td {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

  .dx-datagrid-rowsview .dx-select-checkboxes-hidden > tbody > tr > td > .dx-select-checkbox {
    display: inline-block !important;
  }
}

.dx-switch {
  width: 40px;
  height: 13px;

  &.dx-switch-on-value .dx-switch-container, &.dx-state-hover .dx-switch-container {
    background-color: #ceecda;

    .dx-switch-handle:before {
      background: #59b680;
    }
  }

  .dx-switch-container {
    padding: 0;
    height: 11px;
    overflow: visible;
    border: 0;
    background: #d5d5d5;
    border-radius: 6px;

    .dx-switch-on, .dx-switch-off {
      color: transparent;
    }

    .dx-switch-handle {
      margin-top: -1px;
      flex-basis: 13px;
      width: 13px;
      height: 13px;

      &:before {
        background: #a5a5a5;
        border-radius: 50%;
      }
    }
  }
}

dx-button.broker-invitation-submit-button {
  margin: 0 !important;
  padding: 5px 25px;
  text-transform: uppercase;

  .dx-icon {
    margin-right: 12px;
    color: #ffffff;
    font-size: 14px;
  }

  &.btn-outline-secondary {
    border: 0;
    box-shadow: inset 0 0 0 1px #a6a6a6;
  }
}

.building-unit-budgets {
  transform: translate(50px, 50px);
}

.building-budget.dialog.dx-popup-wrapper,
.building-unit-budgets.dialog.dx-popup-wrapper {
  .dx-overlay-content {
    border: none;
    border-radius: 10px;
    background-color: #fff;

    .dx-popup-content {
      height: auto;
      position: relative;
    }

    .dx-popup-title.dx-toolbar {
      padding: 25px 30px;
      border-bottom: 1px solid #E8EAEE;

      .dx-toolbar-before {
        .dx-toolbar-label .dx-item-content {
          color: #424B5A;
          font-size: 18px;
          font-weight: 500;
          padding-left: 40px;
          letter-spacing: 0.5px;
          text-transform: none;

          &:before {
            content: "";
            background-image: url("/assets/img/budget.svg");
            position: absolute;
            left: 0;
            top: 7px;
            width: 20px;
            height: 20px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }

      .dx-toolbar-after {
        .dx-item-content {
          .dx-closebutton {
            width: 26px;
            height: 26px;
            background: #E8EAEE;
            border-radius: 8px;

            .dx-button-content {
              display: flex;
              justify-content: center;
              align-items: center;

              .dx-icon-close {
                height: 22px;

                :before {
                  color: #686F7B;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

.create-marker-dialog {

  &.dx-popup-wrapper > .dx-overlay-content {
    border-radius: 22px;

    .dx-popup-title.dx-toolbar {
      padding: 14px 22px;
      font-weight: 600;
    }
  }

  .dx-toolbar-before {
    .dx-item-content {
      display: flex;
      gap: 15px;
      align-items: center;;

      > div {
        font-weight: 400;
        font-size: 13px;
        letter-spacing: 0.8px;
        color: #8E939C;
        text-transform: uppercase;
      }
    }

    .dx-item-content:before {
      content: '1';
      background: #59B680;
      border-radius: 50%;
      font-size: 10px;
      color: #FFFFFF;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 22px;
      height: 22px;
    }
  }

  .dx-popup-title {
    border: none;
    height: auto;
  }

  .dx-fieldset {
    padding: 0 22px 20px;
    margin: 0;
  }

  .d-flex {
    gap: 14px;

    dx-button {
      border-radius: 10px;

      .dx-button-content {
        padding: 10px 45px;
        text-transform: uppercase;
        font-size: 10px;
      }
    }
  }
}

.invite-member-dialog {
  .dx-state-focused.dx-overlay-content {
    border-radius: 22px !important;
  }
}

.improvements-preview-popover {
  .dx-overlay-content {
    border-radius: 18px;

    .dx-popover-arrow {
      display: none !important;
    }

    .dx-popup-content {
      padding: 0 !important;
      overflow: hidden !important;

      .popover-inner {
        width: 100%;
        background-color: white;
      }
    }
  }
}

.floor-plan-modal {
  .dx-overlay-content {
    border-radius: 22px;
  }
}

.image-viewer-container {
  .dx-item-content {
    display: flex;
    min-width: fit-content;

  }

  .dx-toolbar-label > div:before {
    content: url('../assets/icons/svg/image-icon.svg');
    position: relative;
    top: 4px;
    margin-right: 20px;
  }

  .dx-icon-close:before {
    background-color: #E8EAEE;
    padding: 6px !important;
    border-radius: 8px;
  }
}

.add-project-notes-container {

  .dx-popup-content {
    background-color: #fff;
  }

  .dx-widget.real-estate-input {
    max-width: 100%;
    height: 100px;
    padding: 3px 11px 2px 11px;
  }

  .dx-texteditor-input {
  }
}

.my-dashboard {
  .dx-item-content.dx-toolbar-item-content {
    padding: 14px 22px;
    border-bottom: 1px solid #E8EAEE;
  }
}

.manage-team-container {
  .dx-popup-content {
    background-color: #fff;
  }
}

.key-statistics-report {
  .dx-overlay-content {
    border-radius: 22px;
    background-color: #fff;
  }

  .dx-popup-content {
    background-color: #fff;
    border-radius: 18px;
    border-top: 1px solid #E8EAEE;
  }
}

.dialog-tooltip {
  .dx-overlay-content {
    background-color: #FFF;
    border-radius: 8px;
  }
}

.request-financial-tooltip {
  .dx-overlay-content {
    border-radius: 22px;
    background-color: #fff;
    border: 1px solid #E8EAEE;
  }

  .dx-popup-content {
    background-color: #fff;
    border-radius: 18px;
  }
}

.tour-schedule-info-dialog {
  .dx-overlay-content {
    border-radius: 22px;
    background-color: #fff;
    border: 1px solid #E8EAEE;
  }

  .dx-state-focused {
    border: none;
  }
}

.site-plan-dialog-container {
  .dx-overlay-content {
    background-color: #fff;
  }
}

.dx-overlay-content {
  background-color: #fff;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.1);
  border-radius: 22px;
}

.lease-commencement-data-piker, .beneficial-occupancy-data-picker {
  .dx-texteditor-input {
    background-color: #F3F4F6 !important;
    color: #424B5A;
    font-weight: 500;
  }

  input {
    padding-right: 0 !important;
  }
}

.beneficial-occupancy-tag-box {
  input {
    width: 0!important;
  }
  .dx-texteditor-input-container .dx-tag-container .dx-native-click {
    display: flex;
    align-items: center;
  }
}

