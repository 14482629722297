.alert-role-badge {
  background-color: #E4F4EA;
  color: #4EC280;
  padding: 0.25em 0.5em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.alert-button-badge {
  padding: 3px 9px;
  border: 1px solid #DCDFE5;
  background-color: #F3F4F6;
  border-radius: 5px;
  color: #424B5A;
  font-size: 12px;
  font-weight: 500;
  line-height: 19px;
  margin-left: 0;
  white-space: nowrap;
}
