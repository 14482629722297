.b-image-stats {
  color: #fff;

  .b-list-content-text {
    color: #fff;
  }
}

.w-image {
  margin: 0 0 20px;
  overflow: hidden;
  // border-radius: 4px;
  position: relative;

  &:after {
    top: 0;
    left: 0;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(to bottom, rgba(55, 55, 55, 0.55) 0%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#8c373737', endColorstr='#00ffffff', GradientType=0);
  }

  img {
    height: 200px;
    object-fit: cover;
  }

  &-controls {
    top: 0;
    right: 0;
    z-index: 1;
    width: 100%;
    padding: 30px;
    text-align: right;
    position: absolute;

    &>.b-link {
      color: #fff;
      font-size: 14px;
    }

    &>.b-link+.b-link {
      margin-left: 30px;
    }
  }

  &-stats {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    padding: 30px 25px;
    flex-direction: column;
    justify-content: space-between;

    .w-search {
      min-width: 37%;
      align-self: flex-end;
    }
  }
}