@import "../../../src/scss/mixin/responsive";

.b-table {
  width: 100%;
  background-color: #fff;

  &-sort {
    display: flex;
    cursor: pointer;
    align-items: center;

    [class^="icon"] {
      font-size: 12px;
      margin-left: 10px;
    }
  }

  & > thead {
    color: #808080;
    font-size: 13px;

    & > tr {

      & > th {
        padding: 8px;
        font-weight: 500;
      }
    }
  }

  & > tbody {
    color: #4D4D4D;
    font-size: 14px;

    & > tr {

      & > td {
        padding: 10px 8px;
      }
    }
  }
}
.b-table-metrics {
  &-cell {
    font-size: 15px;
    font-weight: 600;
    text-align: right;
  }

  &-title {
    color: #5D5D5D;
    font-size: 18px;
    font-weight: 500;
  }

  thead {
    th {
      padding: 12px 0 0;
      border: none;
      color: #7f7f7f;
      font-size: 16px;
      font-weight: 500;

      &:not(:first-child):not(:last-child) {
        padding: 12px 20px 0;

        @include respond-to-max(580) {
          padding: 6px;
        }
      }
    }
  }

  tbody {
    tr:last-of-type {
      td {
        border: none;
      }
    }

    td {
      padding: 12px 0;
      vertical-align: middle;
      border-top: none;
      border-bottom: 1px solid #DFDFDF;
      line-height: 1.4;

      @include respond-to-max(580) {
        padding: 6px;
        white-space: nowrap;
      }

      &:not(:first-child):not(:last-child) {
        padding: 12px 20px;

        @include respond-to-max(580) {
          padding: 6px;
        }
      }
    }
  }
}
.b-table-result {
  font-size: 16px;

  td {
    padding: 3px;
    min-width: 100px;
    border: none;
    text-align: right;
    vertical-align: middle;

    @include respond-to-max(767) {
      padding: 3px 6px;
    }

    &:first-child {
      font-size: 18px;
      text-align: left;
      white-space: nowrap;
    }
  }

  th {
    min-width: 100px;
    vertical-align: middle;
  }

  .cell--divider {
    height: 7px;
    background-color: #fff;

    & > td {
      padding: 0;
    }
  }

  .cell--head {
    border: none;
    color: #7f7f7f;
    font-weight: 500;
    padding: 12px 3px;
    white-space: nowrap;

    @include respond-to-max(767) {
      padding: 10px 6px;
    }
  }

  .cell--value {
    font-size: 15px;
    font-weight: 600;
  }

  .cell--title {
    font-weight: 500;
  }

  .cell--total-title {
    font-weight: 700;
  }

  .cell--color {
    background-color: #F7F7F7;
  }

  .cell--color-gradient {
    background: rgba(78,194,128,0.2);
    background: linear-gradient(to bottom, rgba(78,194,128,0.2) 0%, rgba(103,176,200,0.2) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4ec280', endColorstr='#67b0c8', GradientType=0 );
  }
}
.b-table-comparison {
  border: 1px solid #dfdfdf;
  font-size: 16px;
  font-weight: 500;

  &.table-fixed {
    table-layout: fixed;
  }

  th, td {
    background: #ffffff;
    border: none;
    vertical-align: middle;

    &:first-child {
      min-width: 270px;
      max-width: 350px;
    }
  }

  th {
    padding: 15px 10px;
    vertical-align: middle !important;
  }

  td {
    padding: 10px 20px;
    min-width: 140px;
    text-transform: capitalize;

    &:first-child {
      background: #73bede;
      color: #ffffff;
      font-size: 15px;
      font-weight: 400;
    }
  }

  .group-title--row {
    td {
      background: #eeeeee;
      border-right: 1px solid #dfdfdf;
      font-size: 17px;
      font-weight: 600;
      white-space: nowrap;

      &:first-child {
        background: #51a6ca;
        font-size: 16px;
        font-weight: 500;
        text-transform: uppercase;
      }

      &:last-child {
        border-right: 0;
      }
    }

    &.headers--row {
      td {
        &:nth-child(even) {
          border-right-color: #ffffff;
        }

        &:nth-child(2), &:nth-child(3) {
          background: #ffecd6;
        }

        &:nth-child(4), &:nth-child(5) {
          background: #e3f1ff;
        }

        &:nth-child(6), &:nth-child(7) {
          background: #d2ffdc;
        }

        &:nth-child(8), &:nth-child(9) {
          background: #ffcfce;
        }

        &:nth-child(10), &:nth-child(11) {
          background: #e5e5e5;
        }
      }
    }
  }

  .date--cell {
    padding: 30px 20px;
    border-right: 1px solid #dfdfdf;
    color: #5d5d5d;
    font-weight: 700;
    text-align: center;

    &:first-child {
      background: #ededed;
      text-align: left;

      .b-title {
        margin: 0;
      }
    }

    &:last-child {
      border-right: 0;
    }
  }

  .timeline--cell {
    border-bottom: 1px solid #dfdfdf;
    white-space: nowrap;

    &:first-child {
      border-right: 1px solid #dfdfdf;
    }
  }

  .regular--cell {
    border-right: 1px solid #dfdfdf;

    &:last-of-type {
      border-right: none;
    }
  }

  .multiple-options-header {
    background: white !important;
    border-top: 1px solid #dfdfdf !important;
    border-right: 1px solid #dfdfdf !important;
    font-size: 17px;
    font-weight: 600 !important;
    white-space: nowrap;
    color: #212529 !important;
    text-transform: unset !important;
    text-align: center;
  }
}
