.b-indicator {
  color: #808080;
  text-transform: capitalize;

  &.completed:before {
    background-color: #59B680;
  }
  &.in-progress:before {
    background-color: #1D8CE1;
  }
  &.on-hold:before {
    background-color: #C26DFF;
  }

  &:before {
    width: 6px;
    height: 6px;
    content: '';
    margin-right: 1px;
    border-radius: 50%;
    display: inline-block;
    vertical-align: middle;
    background-color: inherit;
  }
}
