@import 'src/scss/variables';

colabo-messages {
  flex: 1;
  display: flex;
  .messages-container {
    flex: 1;
    display: flex;
    .b-card {
      padding: 10px;
    }
  }
  .col-chat {
    flex: 2;
  }
}

app-chat-panel {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-scroll {
  flex: 20;
  margin: 5px 5px 0 5px;
  background-color: rgba($color: #ffffff, $alpha: 0.5);
  overflow: hidden;
}

.chat-input-container {
  flex: 1;
  margin-top: 5px;
}

.item-container {
  padding-right: 15px;
}

.chat-button-icon {
  font-size: 24px;
  color: $color-primary;
  margin-top: 3px;
}

.send-button {
  width: 50px;
  margin: 5px 0px 0px 5px;
}

.chat-editor,
.messages-editor {
  padding: 0;
  margin-right: 5px;
  resize: none;
  border: 1px solid rgb(221, 221, 221);
  width: calc(100% - 41px);
  min-height: 36px;

  .dx-htmleditor-content {
    display: flex;
    align-items: center;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.chat-editor {
  .dx-htmleditor-content {
    padding: 15px 50px 15px 20px;
  }
}

.messages-editor {
  .dx-htmleditor-content {
    padding: 15px 50px 15px 50px;
    
    p:first-child {
      overflow: hidden;
      padding-left: 1px;
    }
  }
}

.dateseparator {
  background-color: powderblue;
  color: white;
  text-shadow: 1px 1px black;
  line-height: 1.5;
  padding: 1px;
  border-radius: 3px;
  text-align: center;
  width: 100%;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
}

.daterow {
  padding: 25px 25% 25px 25%;
  float: left;
  width: 100%;
}

.messagetime {
  color: skyblue;
  padding: 0 5px 0 5px;
}

.mymessage .messagetime {
  float: right;
}

.menupoints {
  float: right;
  color: rgb(68, 116, 134);
  margin: 0 -12px -5px 0;
  margin-bottom: -5px;
  cursor: pointer;
}

.chat {
  .item {
    white-space: normal;
    border: none;
    width: calc(100% - 80px);
    float: left;
    padding: 0px 0px 0px 5px;
  }

  .username {
    color: darkblue;
    font-size: 14px;
  }

  .content {
    background-color: #ffffe6;
    line-height: 1.7;
    padding: 5px;
    margin: 1px;
    border-radius: 3px;
    text-align: left;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16);
  }

  .mymessage .content {
    text-align: right;
  }

  .datetime {
    text-align: center;
    padding: 0 4px;
    color: cornflowerblue;
    font-size: 12px;
  }

  .message {
    width: 100%;
    word-wrap: break-word;
  }

  .mymessage {
    text-align: right;
    float: right !important;
  }

  .mymessage .content {
    background-color: #e3fed5;
  }

  .image-url {
    width: 92%;
    height: 92%;
    margin: 0 12px;
  }
  &-avatar {
    height: 50px;
    width: 50px;
  }
}

.messagecontent {
  p {
    margin: 0;
    padding: 0;
  }
}
