@import "../variables";

.b-dropdown {
  margin-bottom: 10px;
  transition: all .4s;
  overflow: hidden;

  &.collapsed {
    height: 45px;
  }

  &-link {
    padding-bottom: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    cursor: pointer;
    color: #808080;
    font-size: 17px;
    font-weight: 500;

    &:hover {
      color: $color-green;
      text-decoration: none;

      .b-dropdown-icon {
        transform: rotate(180deg);
        color: $color-green;
      }
    }

    &[aria-expanded="true"] {
      color: $color-green;

      &:hover .b-dropdown-link-title:after {
        transform: scale(.8, 1);
        background-color: rgba($color-green, .5);
      }

      .b-dropdown-link-title:after {
        background-color: $color-green;
      }

      .b-dropdown-link-subtitle {
        display: none;
      }

      .b-dropdown-icon {
        transform: rotate(180deg);
        color: $color-green;
      }
    }

    &-title {
      display: block;
      position: relative;
      text-transform: uppercase;

      &:after {
        content: '';
        height: 2px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -2px;
        left: 0;
        z-index: 25;
        transition: background-color ease-in-out .3s, transform ease-in-out .3s;
      }
    }

    &-subtitle {
      display: block;
      font-size: 14px;
      font-weight: 400;
    }

    .b-dropdown-icon {
      color: #808080;
    }
  }

  &-icon {
    transition: transform .4s ease-in-out;
    font-family: 'icomoon' !important;

    &:before {
      content: "\e909";
    }
  }

  &-content {
    padding: 10px 15px 0;
    transition: height .4s;

    /*&.collapsed {
      height: 0;
      overflow: hidden;
    }*/
  }
}
