@import "../../../src/scss/mixin/responsive";

.b-tab {

  &-content {
    padding: 10px;

    @include respond-to-min(768) {
      padding: 20px 0;
    }
  }
}

.w-tabs {
  display: block;

  .nav-tabs {
    background-color: #fff;
    border-bottom-color: #E6E6E6;

    .nav-item {

      .nav-link {
        border: none;
        padding: 10px;
        color: #808080;
        border-bottom: 2px solid transparent;

        &.active {
          color: #4D4D4D;
          border-bottom-color: #59B680;
        }
      }
    }
  }
}

.my-styling .b-tab-content{
margin-left: 3%;
margin-right: 3%;
margin-top: 20px;
border: 1px solid #e6e6e6;
box-shadow: 0px 0px 3px 0px #7575757a;
border-radius: 3px;
padding-left: 15px;
padding-right: 15px;
}

.my-styling .dx-tabpanel-container{
  padding-bottom: 30px;
}
