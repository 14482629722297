//bootstrap reset start (not really working though)
$theme-colors: (
    "primary": #5461f0,
    "success": #59b680,
    "danger": #f52b18
);
//bootstrap reset end

$color-white: #ffffff;
$color-black: #000000;

$color-text-error: #e75a4d;

$color-blue: #5461f0;
$color-blue-two: #3140ed;
$color-blue-three: #1425e1;
$color-blue-dark: #0f1ca9;
$color-blue-light: #c7cbfa;
$color-blue-shadow: #9ba3f7;

$color-green: #59b680;
$color-green-two: #449c69;
$color-green-three: #388056;
$color-green-dark: #215c3a;
$color-green-light: #d1ebdc;
$color-green-border: #8ecda8;
$color-green-border-two: #9bd3b3;

$color-red: #f52b18;
$color-red-dark: #a3281a;
$color-red-light: #fabcb6;

$color-cyan-dark: #205A60;
$color-cyan-light: #CDF0F3;

$color-yellow-dark: #884A14;
$color-yellow-light: #FFE9D6;

$color-pink-dark: #651556;
$color-pink-light: #F6D7F1;

$color-grey-one: #f9f9fa;
$color-grey-two: #f3f4f6;
$color-grey-three: #e8eaee;
$color-grey-four: #dcdfe5;
$color-grey-five: #b3b7bd;
$color-grey-six: #8e939c;
$color-grey-seven: #686f7b;
$color-grey-eight: #424b5a;

