// @todo: Add bootstrap variables here.
$theme-colors: (
    "primary": #59b680,
    "secondary":#a6a6a6,
    "dark": #4d4d4d,
);

$color-dark-secondary: #4f4f4f;
$color-snow: snow;
$color-white: white;
$color-darkgray: darkgray;
$color-primary: #59b680;
$color-reject: #FF9292;
$color-text-gray: gray;
$color-text-light-gray:#dadada;
$color-light-green: #e1faeb;
$color-blue:#2484b7;
$color-blue-secondary: #43b8e2;
$color-blue-dark: #23394f;
$color-black: black;
$color-error: #a84948;
$color-green: #4ec280;
$color-blue-light: #73bede;
$color-blue-shadow: #51a6ca;
$color-grey: #494949;
$color-red: #ff5e5e;
$color-orange: orange;

$text-color: #424b5a;
$text-opacity-primary: rgba(0,0,0,0.7);
$text-opacity-secondary: rgba(0,0,0,0.4);
$border-color: rgba(0,0,0,0.1);

// $lato-font: 'Lato', sans-serif;
$avenir-font: 'Avenir Next Cyr', sans-serif;

// Disables validation icons for all bootstrap controls
$enable-validation-icons: false;
