.w-pagination {

  & > .page-item {

    & + .page-item {
      margin-left: 4px;
    }

    & > .page-link {
      margin: 0;
      font-size: 14px;
      padding: 4px 8px;
      border-radius: 4px;

      &.page-link-divider {
        border: none;
        margin: 0 4px;
        letter-spacing: 0;
      }
    }
  }
}
