@import "../../../src/scss/mixin/responsive";
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:900&display=swap');
@import "../../scss/variables";

.b-title {
  color: #4d4d4d;
  font-size: 22px;
  font-weight: 600;
  margin: 0 0 15px;
}

.uppercase .b-header {
  text-transform: uppercase;
}

.b-header {
  display: flex;
  color: #4D4D4D;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 15px;
  align-items: center;
  justify-content: space-between;

  &.fancy {
    margin-bottom: 20px;
    padding: 20px 15px 0;
    // border-bottom: 1px solid #E6E6E6;
  }

  @include respond-to-min(768) {
    font-size: 16px;
    font-weight: 500;
  }

  &-controls {
    padding-left: 15px;

    .b-search {
      border-color: transparent;
    }

    & > button + button {
      margin-left: 20px;
    }
  }

  &-icon {
    display: flex;
    color: #A6A6A6;
    font-size: 12px;
    align-items: center;
    margin: 0 auto 0 32px;

    & > [class^="icon"] {
      font-size: 15px;
      margin-right: 5px;
    }
  }

  .w-search {
    max-width: 120px;
  }
}

.green-icon{
  color: #6ABE8E;
}

.green-header {
  display: flex;
  color: #6ABE8E !important;
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 15px;
  align-items: center;
  justify-content: space-between;

  &.fancy {
    margin-bottom: 20px;
    border-bottom: 1px solid #E6E6E6;
    padding: 20px 20px;
    text-transform: uppercase;
  }
}

.b-header2 {
  color: #4D4D4D;
  font-size: 22px;
  margin: 0 0 20px;
}

.bottom-border {
  border-bottom: 1px solid #E6E6E6;
  padding-bottom: 20px !important;
  text-transform: uppercase;
}
