.rent-schedule-preview-popover {
  .dx-overlay-content {

    .dx-popover-arrow {
      display: none !important;
    }

    .dx-popup-content {
      padding: 0 !important;
      overflow: hidden !important;

      .popover-inner {
        width: 100%;
        background-color: white;

        table {
          width: 100%;
          font-size: 14px;

          tr:nth-child(even) td {
            background: #eeeeee;
          }

          th, td {
            padding: 10px 15px;
          }

          th {
            font-weight: 400;
            white-space: nowrap;
          }
        }

        .comment {
          padding: 10px 15px;
        }
      }
    }
  }
}

.dx-custom-gray-popover {
  .dx-overlay-content {
    border: 0 !important;
    box-shadow: none !important;
    background-color: transparent !important;

    .dx-popover-arrow {
      display: none !important;
    }

    .dx-popup-content {
      padding: 0 !important;
      overflow: hidden !important;

      .popover-inner {
        width: 100%;
        background-color: white;

        table {
          width: 100%;
          font-size: 14px;

          tr:nth-child(even) td {
            background: #eeeeee;
          }

          th, td {
            padding: 10px 15px;
          }

          th {
            font-weight: 600;
            white-space: nowrap;
          }
        }

        .comment {
          padding: 10px 15px;
        }
      }
    }
  }
}
