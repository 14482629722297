 @import "./variables";

.b-divider {
  margin: 30px 0;
}

.w-desktop {
  display: flex;
  position: relative;

  .b-controls-back {
    color: #A6A6A6;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 30px 0;
  }

  .w-aside {
    min-height: calc(100vh - 265px);
    width: 380px;
    transition: width .4s ease-in-out;
    overflow: hidden;

    &-container {
      padding: 0 30px;
      height: 100%;
      width: 380px;
      overflow-x: hidden;
      overflow-y: auto;
      background-color: #ffffff;
    }

    .b-title {
      font-size: 24px;
    }
  }

  .w-content {
    flex: 1 0 calc(100% - 380px);
    width: calc(100% - 380px);
  }

  .w-toggle {
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 120px;
    left: 380px;
    z-index: 100;
    transform: translate(-50%, -50%) rotate(-90deg);
    transition: transform .4s ease-in-out, left .4s ease-in-out;
    background-color: #ffffff;
    border: 0;
    border-radius: 50%;
    color: $color-green;
    font-size: 14px;
  }

  &.collapsed {
    .w-aside {
      width: 30px;
    }

    .w-toggle {
      left: 30px;
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }
}
.w-page {
  padding: 0 15px;
  position: relative;

  &-building {
    margin-top: -80px;
  }
}

.body-100 {
  height: 100%;
  display: flex;
  display: -webkit-flex;
  padding: 0;
  margin: 0;
  .container {
    padding: 0px;
    margin: 0;
    max-width: 100%;
    width: 100%;
  }
  .row {
    margin-left: 0px;
    margin-right: 0px;
  }
  .stepper {
    max-width: 100% !important;
  }
  .navbar-brand {
    margin-left: 15px;
  }
  .all-screen {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
      .container--wide {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        app-doc-edit {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
        }
      }
  }
}

.static-footer {
  footer {
    position: static !important;
  }
}

.all-screen {
  background: rgb(247, 247, 247);
}

.all-screen .container--wide {
  width: 100%;
  max-width: 100%;
}

header .container--wide {
  width: 100%;
  max-width: 100%;
}

.container--wide {
  width: 100%;
  max-width: 1365px;
}

.overlay {
  z-index: 2999 !important;
}

.calendly-badge-widget{
  display: none;
  right: 130px;
}

@media(max-width: 1600px) {
  .w-desktop {
    .w-aside {
      width: 330px;

      &-container {
        width: 330px;
      }
    }

    .w-toggle {
      left: 330px;
    }
  }
}
