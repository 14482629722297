@import "../../colors";

.tour-scheduled-info {
  background-color: #fff;

  &-header {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #DCDFE5;

    > h3 {
      margin: 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      letter-spacing: 0.03em;
      color: #424B5A;
    }

    > button {
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $color-grey-three;
      border-radius: 8px;
      border: none;
      outline: none;
      transition: background-color 0.2s ease-in-out;

      &:hover {
        background-color: $color-grey-four;
      }
      &:active {
        background-color: $color-grey-four;
      }

      > i {
        width: 16px;
        height: 16px;

        > svg {
          width: 16px;
          height: 16px;
          float: left;
          fill: $color-grey-seven;
        }
      }
    }
  }

  &-content {
    padding: 30px;

    .building-info {
      display: flex;
      flex-direction: row;
      gap: 7px;

      i svg {
        width: 20px;
        height: 20px;
      }

      &-unit-name {
        position: relative;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #686F7B;

        &:after {
          content: '';
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 1px;
          background-color: #686F7B;
        }
      }
    }

    .company-info {
      margin-top: 15px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      > img {
        width: 34px;
        height: 34px;
        border-radius: 8px;
      }

      &-party {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #424B5A;
        }

        &-existing-tenant-badge {
          margin-top: 3px;
          padding: 1px 5px;
          display: inline-block;
          border-radius: 5px;
          border: 0.5px solid $color-green;
          background-color: $color-grey-one;
          color: $color-green;
          font-size: 9px;
          font-weight: 400;
          line-height: 14px;
          letter-spacing: 0.45px;
          text-transform: uppercase;
        }
      }
    }

    .tour-info {
      border: 1px solid $color-grey-two;
      background-color: #FAFAFB;
      padding: 20px;
      margin-top: 15px;
      border-radius: 10px;

      &-caption {
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #8E939C;
      }

      &-date {
        margin-top: 12px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.03em;
        color: #424B5A;
      }

      &-time-slot {
        margin-top: 12px;
        display: flex;
        align-items: center;
        gap: 7px;

        > i {
          width: 20px;
          height: 20px;

          > svg {
            display: block;
            width: 20px;
            height: 20px;
          }
        }

        span {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #686F7B;
        }
      }

      &-edit-button {
        margin-top: 12px;
        padding: 6px 11px;
        display: flex;
        align-items: center;
        gap: 4px;
        border: none;
        outline: none;
        background: $color-white;
        border-radius: 9px;

        > i {
          width: 12px;
          height: 12px;

          > svg {
            display: block;
            width: 12px;
            height: 12px;
          }
        }

        > span {
          font-size: 10px;
          line-height: 14px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          color: #424B5A;
        }
      }
    }
  }
}
