.image-uploader {
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 20px;
  border: 1px dashed #dcdfe5;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    padding: 0;
    position: absolute;
    z-index: 0;
  }

  &-drag-area {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    cursor: pointer;

    &-plus {
      width: 26px;
      height: 26px;
      border-radius: 8px;
      padding: 5px;
      background-color: #e8eaee;
      opacity: 0;

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNy4yODU3MSA3LjI4NTcxVjNIOC43MTQyOVY3LjI4NTcxSDEzVjguNzE0MjlIOC43MTQyOVYxM0g3LjI4NTcxVjguNzE0MjlIM1Y3LjI4NTcxSDcuMjg1NzFaIiBmaWxsPSIjNjg2ZjdiIi8+Cjwvc3ZnPg==") center center no-repeat;
      }
    }

    &:hover {
      .image-uploader-drag-area-plus {
        opacity: 1;
      }
    }
  }

  &-empty-state {
    width: 100%;
    height: 100%;
    padding: 32px 26px 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    gap: 6px;

    &.dragged-over {
      background-color: #f9f9fa;
    }

    &-image {
      display: block;
      width: 25px;
      height: 25px;
      background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjUiIHZpZXdCb3g9IjAgMCAyNCAyNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNNS41NDUyIDIwLjU5OTlMNS41MjcyIDIwLjYxNzlMNS41MDgzIDIwLjU5OTlIMy44OTI4QzMuNjU1OTMgMjAuNTk5NyAzLjQyODg1IDIwLjUwNTQgMy4yNjE0NCAyMC4zMzc4QzMuMDk0MDMgMjAuMTcwMiAzIDE5Ljk0MzEgMyAxOS43MDYyVjUuMjkzNkMzLjAwMTY1IDUuMDU3MjQgMy4wOTYyMSA0LjgzMTAyIDMuMjYzMjYgNC42NjM4QzMuNDMwMzEgNC40OTY1OCAzLjY1NjQ0IDQuNDAxNzkgMy44OTI4IDQuMzk5OUgyMC4xMDcyQzIwLjYwMDQgNC4zOTk5IDIxIDQuODAwNCAyMSA1LjI5MzZWMTkuNzA2MkMyMC45OTg0IDE5Ljk0MjYgMjAuOTAzOCAyMC4xNjg4IDIwLjczNjcgMjAuMzM2QzIwLjU2OTcgMjAuNTAzMiAyMC4zNDM2IDIwLjU5OCAyMC4xMDcyIDIwLjU5OTlINS41NDUyWk0xOS4yIDE1LjE5OTlWNi4xOTk5SDQuOFYxOC43OTk5TDEzLjggOS43OTk5TDE5LjIgMTUuMTk5OVpNMTkuMiAxNy43NDUxTDEzLjggMTIuMzQ1MUw3LjM0NTIgMTguNzk5OUgxOS4yVjE3Ljc0NTFaTTguNCAxMS41OTk5QzcuOTIyNjEgMTEuNTk5OSA3LjQ2NDc3IDExLjQxMDMgNy4xMjcyMSAxMS4wNzI3QzYuNzg5NjQgMTAuNzM1MSA2LjYgMTAuMjc3MyA2LjYgOS43OTk5QzYuNiA5LjMyMjUxIDYuNzg5NjQgOC44NjQ2OCA3LjEyNzIxIDguNTI3MTFDNy40NjQ3NyA4LjE4OTU0IDcuOTIyNjEgNy45OTk5IDguNCA3Ljk5OTlDOC44NzczOSA3Ljk5OTkgOS4zMzUyMyA4LjE4OTU0IDkuNjcyNzkgOC41MjcxMUMxMC4wMTA0IDguODY0NjggMTAuMiA5LjMyMjUxIDEwLjIgOS43OTk5QzEwLjIgMTAuMjc3MyAxMC4wMTA0IDEwLjczNTEgOS42NzI3OSAxMS4wNzI3QzkuMzM1MjMgMTEuNDEwMyA4Ljg3NzM5IDExLjU5OTkgOC40IDExLjU5OTlaIiBmaWxsPSIjNDI0QjVBIi8+Cjwvc3ZnPgo=") center center no-repeat;
    }

    &-action {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
      margin: 0 !important;
      gap: 2px;
      font-family: "Avenir Next Cyr", sans-serif !important;
      font-size: 14px;
      font-weight: 400;

      > span {
        color: #5461f0;
      }
    }

    &-description {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      margin: 0 !important;
      gap: 6px;
      color: #8e939c;
      font-family: "Avenir Next Cyr", sans-serif !important;
      font-size: 10px;
      font-weight: 400;
      letter-spacing: 1.3px;
      text-transform: uppercase;

      &-dot {
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background-color: #424B5A;
      }
    }

    &:hover, &.hovered {
      .image-uploader-empty-state-action > span {
        text-decoration: underline;
        text-underline-offset: 3px;
      }
    }
  }

  &-images {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    justify-content: stretch;
    gap: 2px;
  }

  &-image {
    flex: 1 1 calc(50% - 2px);

    > img {
      width: 100%;
      height: 100%;
      max-height: 150px;
      object-fit: cover;
    }

    &-skeleton {
      display: block;
      width: 100%;
      height: 100%;
      max-height: 150px;
      position: relative;
      z-index: 1;
      overflow: hidden;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        animation-name: animate;
        animation-duration: 1.4s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background: linear-gradient(to right, #dcdfe5 8%, #b3b7bd 18%, #dcdfe5 33%) 0 0;
        background-size: 100vw 100vh;

        @keyframes animate {
          0% {
            background-position: 0 0;
          }

          100% {
            background-position: 100vw 0;
          }
        }
      }
    }

    &-remove {
      width: 26px;
      height: 26px;
      border-radius: 8px;
      padding: 5px;
      background-color: #e8eaee;
      position: absolute;
      z-index: 8;
      cursor: pointer;

      &:before {
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8cGF0aCBkPSJNMTAuNzk5OSA0LjYzOTlIMTMuNTk5OVY1Ljc1OTlIMTIuNDc5OVYxMy4wMzk5QzEyLjQ3OTkgMTMuMTg4NCAxMi40MjA5IDEzLjMzMDkgMTIuMzE1OSAxMy40MzU5QzEyLjIxMDkgMTMuNTQwOSAxMi4wNjg0IDEzLjU5OTkgMTEuOTE5OSAxMy41OTk5SDQuMDc5OUMzLjkzMTM4IDEzLjU5OTkgMy43ODg5NCAxMy41NDA5IDMuNjgzOTIgMTMuNDM1OUMzLjU3ODkgMTMuMzMwOSAzLjUxOTkgMTMuMTg4NCAzLjUxOTkgMTMuMDM5OVY1Ljc1OTlIMi4zOTk5VjQuNjM5OUg1LjE5OTlWMi45NTk5QzUuMTk5OSAyLjgxMTM4IDUuMjU4OSAyLjY2ODk0IDUuMzYzOTIgMi41NjM5MkM1LjQ2ODk0IDIuNDU4OSA1LjYxMTM4IDIuMzk5OSA1Ljc1OTkgMi4zOTk5SDEwLjIzOTlDMTAuMzg4NCAyLjM5OTkgMTAuNTMwOSAyLjQ1ODkgMTAuNjM1OSAyLjU2MzkyQzEwLjc0MDkgMi42Njg5NCAxMC43OTk5IDIuODExMzggMTAuNzk5OSAyLjk1OTlWNC42Mzk5Wk0xMS4zNTk5IDUuNzU5OUg0LjYzOTlWMTIuNDc5OUgxMS4zNTk5VjUuNzU5OVpNOC43OTE3NCA5LjExOTlMOS43ODE4MiAxMC4xMUw4Ljk4OTk4IDEwLjkwMThMNy45OTk5IDkuOTExNzRMNy4wMDk4MiAxMC45MDE4TDYuMjE3OTggMTAuMTFMNy4yMDgwNiA5LjExOTlMNi4yMTc5OCA4LjEyOTgyTDcuMDA5ODIgNy4zMzc5OEw3Ljk5OTkgOC4zMjgwNkw4Ljk4OTk4IDcuMzM3OThMOS43ODE4MiA4LjEyOTgyTDguNzkxNzQgOS4xMTk5Wk02LjMxOTkgMy41MTk5VjQuNjM5OUg5LjY3OTlWMy41MTk5SDYuMzE5OVoiIGZpbGw9IiM2ODZmN2IiLz4KPC9zdmc+") center center no-repeat;
      }
    }
  }
}
