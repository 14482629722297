.b-input {
  outline: none;
  padding: 7px 12px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
}

.b-label {
  color: #4D4D4D;
  font-size: 13px;
  margin: 0 0 10px;
}

.d-flex {
  outline: none;
}

input {
  border: 1px solid $border-color;
  padding: 8px;
  font-size: 14px;
  color: $text-opacity-primary;
  width: 100%;
}

input::placeholder {
  color: #A0CEE2 !important;
}

label {
  font-weight: 500;
  color: $text-opacity-primary;
}


.b-radio {
  margin: 0;
  width: 100%;
  font-size: 21px;
  font-weight: 500;
  max-width: 40rem;
  position: relative;
  text-align: center;
  display: inline-block;
  color: $color-primary;
  vertical-align: middle;
  margin-bottom: 1%;

  &>input[type="radio"] {
    top: 0;
    left: 0;
    opacity: 0;
    position: absolute;

    &:checked~.b-radio-media:before {
      color: #fff;
      content: "\e91b";
    }

    &:checked~.b-radio-text {
      color: #fff;
      background-color: $color-primary;
    }
  }

  &-media {
    left: 25px;
    speak: none;
    line-height: 1;
    font-size: 17px;
    font-style: normal;
    position: absolute;
    font-weight: normal;
    top: calc(50% - 2px);
    font-variant: normal;
    text-transform: none;
    transform: translateY(-50%);
    font-family: 'icomoon' !important;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  &-text {
    display: block;
    cursor: pointer;
    font-size: 21px;
    font-weight: 400;
    user-select: none;
    padding: 10px 30px;
    border-radius: 30px;
    border: 1px solid $color-primary;
  }

  // &+.b-radio {
  //   margin-right: 15px;
  // }
}

.b-radio {
  margin-right: 15px;
}

.b-search {
  width: 100%;
  font-size: 12px;
  padding: 8px 12px;
  border-radius: 4px;
  padding-left: 40px;
  border: 1px solid #E6E6E6;
}

.b-select-sort {
  width: 100%;
  cursor: pointer;
  padding: 7px 15px;
  outline: none !important;
  border: 1px solid #E6E6E6;
  text-transform: capitalize;
  background-color: transparent;
}

.is-invalid {
  border-color: $color-error;
}

.error-msg {
  font-size: 14px;
  padding-left: 1.5rem;
  color: $color-error;
  position: relative;
  background-image: url('/assets/img/warning.svg');
  background-repeat: no-repeat;
  background-position-x: 9px;
  background-position-y: 3px;
}

.w-search {
  display: flex;
  position: relative;
  align-items: center;

  &>[class^="icon"] {
    left: 15px;
    color: #A6A6A6;
    font-size: 15px;
    position: absolute;
  }
}

.w-select-sort {
  position: relative;

  &>select {
    appearance: none;
  }

  &>[class^="icon"] {
    top: 50%;
    right: 15px;
    font-size: 9px;
    position: absolute;
    pointer-events: none;
    transform: translateY(-50%);
  }
}

.w-form {

  .b-btn {
    min-width: 100px;
  }

  .dx-field-value {
    width: 100%;
    float: none;
  }

  .dx-radiobutton {
    display: flex;
    width: fit-content;

    &+.dx-radiobutton {
      margin: 6px 0 0;
    }

    .dx-radio-value-container {
      padding-right: 14px;

      .dx-radiobutton-icon {
        width: 26px;
        height: 26px;


        &:before {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          border: none;
          background: #E8EAEE;
        }

        &.dx-radiobutton-icon-checked .dx-radiobutton-icon-dot {
          margin-top: -16px;
          margin-left: 11px;
          width: 5px;
          height: 5px;
          background-color: #424B5A;
        }
      }
    }

    &.dx-radiobutton-checked {
      .dx-item-content  {
        color: #424B5A;
        font-weight: 500;
        font-size: 14px;
        position: relative;
        top: 3px;
        padding: 0 !important;
      }
    }

    &:not(.dx-radiobutton-checked) .dx-item-content {
      font-size: 14px !important;
      font-weight: 400 !important;
      color: #8E939C !important;
      position: relative;
      top: 3px;
      padding: 0 !important;
    }
  }
}

@media(max-width: 425px) {
  .my-heading .b-radio-media {
    left: 12px;
  }
}
