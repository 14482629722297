@import '../variables';

.btn-inactive {
  cursor: unset !important;

  &.b-btn-primary:hover, &.b-btn-primary:focus {
    background-color: $color-primary !important;
    border-color: $color-primary !important;
    box-shadow: none !important;
  }
}
