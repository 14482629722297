/* This stylesheet generated by Transfonter (https://transfonter.org) on July 4, 2017 2:43 AM */

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Heavy.eot');
  src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'), url('../assets/fonts/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Heavy.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-HeavyItalic.eot');
  src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNextCyr-HeavyItalic'), url('../assets/fonts/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-HeavyItalic.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Bold.eot');
  src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'), url('../assets/fonts/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Bold.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../assets/fonts/AvenirNextCyr-BoldItalic.eot');
	src: local('Avenir Next Cyr Bold Italic'), local('AvenirNextCyr-BoldItalic'),
		url('../assets/fonts/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AvenirNextCyr-BoldItalic.woff') format('woff'),
		url('../assets/fonts/AvenirNextCyr-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Demi.eot');
  src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'), url('../assets/fonts/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Demi.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Demi.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-DemiItalic.eot');
  src: local('Avenir Next Cyr Demi Italic'), local('AvenirNextCyr-DemiItalic'), url('../assets/fonts/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-DemiItalic.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-DemiItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Medium.eot');
  src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'), url('../assets/fonts/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Medium.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-MediumItalic.eot');
  src: local('Avenir Next Cyr Medium Italic'), local('AvenirNextCyr-MediumItalic'), url('../assets/fonts/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-MediumItalic.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Regular.eot');
  src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'), url('../assets/fonts/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Regular.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Italic.eot');
  src: local('Avenir Next Cyr Italic'), local('AvenirNextCyr-Italic'), url('../assets/fonts/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Italic.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../assets/fonts/AvenirNextCyr-Light.eot');
	src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'),
		url('../assets/fonts/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AvenirNextCyr-Light.woff') format('woff'),
		url('../assets/fonts/AvenirNextCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-LightItalic.eot');
  src: local('Avenir Next Cyr Light Italic'), local('AvenirNextCyr-LightItalic'), url('../assets/fonts/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-LightItalic.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('../assets/fonts/AvenirNextCyr-UltraLight.eot');
  src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'), url('../assets/fonts/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-UltraLight.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-UltraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next Cyr Ultra';
  src: url('../assets/fonts/AvenirNextCyr-UltraLightIt.eot');
  src: local('Avenir Next Cyr Ultra Light Italic'), local('AvenirNextCyr-UltraLightIt'), url('../assets/fonts/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-UltraLightIt.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Next Cyr';
  src: url('../assets/fonts/AvenirNextCyr-Thin.eot');
  src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'), url('../assets/fonts/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'), url('../assets/fonts/AvenirNextCyr-Thin.woff') format('woff'), url('../assets/fonts/AvenirNextCyr-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr';
	src: url('../assets/fonts/AvenirNextCyr-ThinItalic.eot');
	src: local('Avenir Next Cyr Thin Italic'), local('AvenirNextCyr-ThinItalic'),
		url('../assets/fonts/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('../assets/fonts/AvenirNextCyr-ThinItalic.woff') format('woff'),
		url('../assets/fonts/AvenirNextCyr-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-BoldItalic.woff2') format('woff2');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Italic.woff2') format('woff2');
//  font-weight: normal;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Bold.woff2') format('woff2');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Demi.woff2') format('woff2');
//  font-weight: bold;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Medium.woff2') format('woff2');
//  font-weight: 500;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-ThinItalic.woff2') format('woff2');
//  font-weight: 100;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-LightItalic.woff2') format('woff2');
//  font-weight: 300;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Thin.woff2') format('woff2');
//  font-weight: 100;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-MediumItalic.woff2') format('woff2');
//  font-weight: 500;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr Ultra';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-UltraLight.woff2') format('woff2');
//  font-weight: 200;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr Ultra';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-UltraLightIt.woff2') format('woff2');
//  font-weight: 200;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-HeavyItalic.woff2') format('woff2');
//  font-weight: 900;
//  font-style: italic;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Heavy.woff2') format('woff2');
//  font-weight: 900;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Light.woff2') format('woff2');
//  font-weight: 300;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-Regular.woff2') format('woff2');
//  font-weight: normal;
//  font-style: normal;
//  font-display: swap;
//}
//
//@font-face {
//  font-family: 'Avenir Next Cyr';
//  src: url('../assets/fonts/avenir/AvenirNextCyr-DemiItalic.woff2') format('woff2');
//  font-weight: bold;
//  font-style: italic;
//  font-display: swap;
//}
