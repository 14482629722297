.b-controls {
  display: flex;
  padding: 5px 15px;
  align-items: center;

  &-back {
    display: flex;
    color: inherit;
    font-size: 14px;
    align-items: center;

    &:hover {
      text-decoration: none;
    }

    [class^="icon"] {
      margin-right: 8px;
    }
  }

  &-settings {
    flex: 1 1 auto;
    text-align: right;

    & > .btn {
      color: inherit;
      font-size: 14px;
    }

    & > .btn + .btn {
      margin-left: 15px;
    }
  }
}

