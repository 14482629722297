@import "../../../src/scss/mixin/responsive";
@import "../../../src/scss/variables";

.b-list {
  margin: 0;

  & > li + li {
    margin-top: 10px;

    @include respond-to-min(768) {
      margin-top: 30px;
    }
  }

  & > .b-list-item {
    display: block;

    &:hover, &.active {
      text-decoration: none;

      .b-list-header, .b-list-content {
        color: $color-green;
      }
    }
  }

  & > .b-list-item + .b-list-item {
    margin-top: 10px;
  }

  &-header {
    margin: 0;
    transition: color .4s ease-in-out;
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;

    &-selected {
      color: $color-green;

      & > span {
        border-bottom: 1px solid $color-green;
      }
    }

    [class^="icon"] {
      margin-right: 6px;
    }
  }

  &-content {
    margin: 0;
    transition: color .4s ease-in-out;
    color: #808080;
    font-size: 14px;

    &-link {
      display: block;
      color: #59B680;
    }
  }
}

.b-list-description {
  padding: 0;
  list-style: none;
  margin: 0 0 12px;

  &>li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &>.key {
      color: #5D5D5D;
      font-size: 18px;
      font-weight: 500;
      padding-right: 10px;
    }

    &>.value {
      color: #4D4D4D;
      font-size: 16px;
      font-weight: 600;
    }

    &+li {
      margin-top: 17px;
    }
  }
}

.b-list--row {
  display: flex;

  &>li {
    flex: 1 1 auto;

    &+li {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}

.b-list-status {
  padding: 0;
  margin: 0 0 20px;
  list-style: none;

  .name {
    width: calc(75% - 17px);
    overflow: hidden;
    padding-right: 10px;
    white-space: nowrap;
    text-overflow: ellipsis;


    &-row {
      color: #4ec280;
      font-size: 18px;
      font-weight: 500;
      text-decoration: underline;
      text-transform: capitalize;
    }
  }

  .status {
    width: 20%;

    &-row {
      color: #A6A6A6;
      font-size: 13px;
    }

    &-title {
      width: 25%;
    }
  }

  &-header {
    display: flex;
    color: #808080;
    font-size: 16px;
    margin-bottom: 20px;
  }

  & > li {
    display: flex;
    align-items: center;

    & + li {
      margin-top: 25px;
    }
  }
}

.my-card-lease .b-list-status .name {
  width: 82%;
}

.my-flex-block .b-list-header {
  text-transform: none;
}

.my-dash-blocks .d-block {
  max-height: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-dash-blocks .d-block {
  max-height: 57px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.my-dash-blocks .d-block:hover {
  text-overflow: initial;
  overflow: auto;
  word-break: break-word;
  white-space: unset;
}

@media(max-width: 1440px) {
  .my-card-lease .b-list-status .name {
    width: 85%;
  }
}

@media(max-width: 1024px) {
  .my-card-lease .b-list-status .name {
    width: 87%;
  }
}

@media(max-width: 414px) {
  .my-second-dash-blocks .b-list {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
  }

  .my-second-dash-blocks .b-list>li+li {
    margin-top: 0;
  }

  .my-second-dash-blocks .b-list li {
    width: 31%;
  }
}

@media(max-width: 378px) {
  .b-list-status .status {
    width: 23% !important;
  }
}
