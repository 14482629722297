@import "../_colors.scss";

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;

  &-inner, &-item, .item {
    width: 100%;
    height: 100%;
  }

  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 18px;
  }

  video {
    display: block;
    width: 100%;
    height: 100%;
  }

  &-indicators {
    position: absolute;
    top: auto;
    bottom: 15px;
    margin: 0;
    padding: 0;

    > li {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: $color-grey-three;
      opacity: 1;

      &.active {
        background-color: $color-white;
      }
    }
  }

  &-control {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: 30px;
    height: 30px;
    opacity: 1;
    transition: opacity 0.2s ease-in-out;

    &.left {
      right: auto;
      left: 20px;
    }

    &.right {
      left: auto;
      right: 20px;
    }

    > span {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 5px 0;
      width: 30px;
      height: 30px;
      border-radius: 9px;
      background: unset;
      background-color: $color-grey-two;
      transition: background-color .2s ease-in-out;
      border: 0;

      &.carousel-control-prev-icon {
        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTguNzkyMDYgMTBMMTQuMzA3NiAxNS4wNTU4TDEyLjczMjEgMTYuNUw1LjY0MDk1IDEwTDEyLjczMjEgMy41TDE0LjMwNzYgNC45NDQyMkw4Ljc5MjA2IDEwWiIgZmlsbD0iIzY4NkY3QiIvPgo8L3N2Zz4=");
          background-position: center center;
          background-size: cover;
        }
      }

      &.carousel-control-next-icon {
        &:before {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMSIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIxIDIwIiBmaWxsPSJub25lIj4KPHBhdGggZD0iTTEyLjIzMzMgMTBMNi43MTc3NyA0Ljk0NDIyTDguMjkzMzMgMy41TDE1LjM4NDQgMTBMOC4yOTMzMyAxNi41TDYuNzE3NzcgMTUuMDU1OEwxMi4yMzMzIDEwWiIgZmlsbD0iIzQyNEI1QSIvPgo8L3N2Zz4=");
          background-position: center center;
          background-size: cover;
        }
      }
    }

    &:hover {
      > span {
        background-color: $color-grey-four;
      }
    }

    .sm & {
      margin-top: -8px;
      width: 16px;
      height: 16px;

      > span {
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background-color: $color-grey-three;
        padding: 4px 0;
        border: 0;

        &.carousel-control-prev-icon {
          &:before {
            width: 8px;
            height: 8px;
            margin-left: -1px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBpZD0iY2hldnJvbiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik0zLjI1IDRMNS43NSAxLjVWNi41TDMuMjUgNFoiIGZpbGw9IiM0MjRCNUEiLz4KPC9nPgo8L3N2Zz4K");
            background-position: center center;
            background-size: cover;
          }
        }

        &.carousel-control-next-icon {
          &:before {
            width: 8px;
            height: 8px;
            margin-right: -1px;
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iOSIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgOSA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8ZyBpZD0iY2hldnJvbiI+CjxwYXRoIGlkPSJWZWN0b3IiIGQ9Ik01Ljc1IDRMMy4yNSA2LjVMMy4yNSAxLjVMNS43NSA0WiIgZmlsbD0iIzQyNEI1QSIvPgo8L2c+Cjwvc3ZnPgo=");
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }
  }
}

carousel {
  &.controls-hidden {
    .carousel {
      &-control {
        opacity: 0;
        pointer-events: none;
      }
    }
  }
}
