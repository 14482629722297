@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?js4s9h');
  src:  url('./icomoon.eot?js4s9h#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?js4s9h') format('truetype'),
    url('./icomoon.woff?js4s9h') format('woff'),
    url('./icomoon.svg?js4s9h#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-inquiries:before {
  content: "\e92a";
}
.icon-more:before {
  content: "\e929";
}
.icon-Availabilities:before {
  content: "\e928";
}
.icon-close:before {
  content: "\e914";
}
.icon-information-outline:before {
  content: "\e90c";
}
.icon-mode:before {
  content: "\e91f";
}
.icon-hand:before {
  content: "\e920";
}
.icon-square:before {
  content: "\e921";
}
.icon-circle:before {
  content: "\e922";
}
.icon-text:before {
  content: "\e923";
}
.icon-delete:before {
  content: "\e924";
}
.icon-undo:before {
  content: "\e925";
}
.icon-repeat:before {
  content: "\e926";
}
.icon-calendar:before {
  content: "\e927";
}
.icon-uploader:before {
  content: "\e91e";
}
.icon-ok:before {
  content: "\e91b";
}
.icon-right:before {
  content: "\e919";
}
.icon-left:before {
  content: "\e91a";
}
.icon-plane:before {
  content: "\e917";
}
.icon-bag:before {
  content: "\e916";
}
.icon-buildings:before {
  content: "\e915";
}
.icon-lease:before {
  content: "\e913";
}
.icon-info:before {
  content: "\e911";
}
.icon-helpdesk:before {
  content: "\e910";
}
.icon-dashboard:before {
  content: "\e90d";
}
.icon-land-area:before {
  content: "\e90f";
}
.icon-Collabo:before {
  content: "\e90e";
}
.icon-up:before {
  content: "\e908";
}
.icon-down:before {
  content: "\e909";
}
.icon-order:before {
  content: "\e90a";
}
.icon-forward:before {
  content: "\e907";
}
.icon-backward:before {
  content: "\e912";
}
.icon-paper:before {
  content: "\e906";
}
.icon-search:before {
  content: "\e905";
}
.icon-pointer:before {
  content: "\e904";
}
.icon-user-round:before {
  content: "\e90b";
}
.icon-back:before {
  content: "\e901";
}
.icon-settings:before {
  content: "\e902";
}
.icon-sort:before {
  content: "\e903";
}
.icon-user:before {
  content: "\e900";
}
.icon-minus:before {
  content: "\e91d";
}
.icon-plus:before {
  content: "\e91c";
}
.icon-rotate-ccw:before {
  content: "\e918";
}
