.term-toast {
  margin-bottom: 20px;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  > i {
    margin-right: 27px;

    > svg {
      width: 24px;
      height: 24px;
    }
  }

  &-success {
    background-color: #D1EBDC;
    color: #215C3A;

    i svg path {
      fill: #215C3A;
    }
  }

  &-neutral {
    background-color: #E8EAEE;
    color: #424B5A;

    i svg path {
      fill: #424B5A;
    }
  }

  &-information {
    background-color: #E8EAEE;
    color: #424B5A;

    i svg path {
      fill: #424B5A;
    }
  }

  &-warning {
    background-color: #FEF7EA;
    color: #832118;

    i svg path {
      fill: #832118;
    }
  }

  &-error {
    background-color: #FCD0CC;
    color: #832118;

    i svg path {
      fill: #832118;
    }
  }
}
