@import '~src/scss/variables';


.b-card-lease {

  &-head {

    .b-header {
      .controls-back.right {
        order: 1;
        top: auto;
        left: auto;
        position: relative;
        margin: 0 0 15px auto;
      }
    }

    .b-header-controls {
      display: flex;

      &>.btn {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3;
        padding: 6px 12px;
        text-transform: capitalize;

        [class*="dx-icon"] {
          font-size: 21px;
        }
      }
    }
  }
}

.b-lease-terms {

  &-header {
    color: #4d4d4d;
    font-size: 16px;
    font-weight: 500;
  }

  &-controls {
    display: flex;
    margin-left: auto;
    align-items: center;

    .b-btn {
      margin-left: 10px;
    }

    &-dropdown {
      display: flex;
      color: #a6a6a6;
      font-size: 10px;
      font-weight: 500;
      letter-spacing: 1.3px;
      position: relative;
      align-items: center;
      border-radius: 25px;
      padding: 3px 10px 3px 10px;
      text-transform: uppercase;
      height: 22px;

      &:hover {
        text-decoration: none;
        color: darken(#a6a6a6, 10%);
      }

      &:before {
        width: 8px;
        height: 8px;
        content: "";
        margin-right: 7px;
        border-radius: 50%;
        background-color: #ccc;
      }

      &.approved {

        &:before {
          background-color: #9DE082;
        }
      }

      &.pending {

        &:before {
          background-color: #F4CB77;
        }
      }

      &.draft {

        &:before {
          background-color: #95D7E4;
        }
      }

      &.reject {

        &:before {
          background-color: #FF9292;
        }
      }
    }
  }

  .dx-accordion-wrapper {

    .dx-accordion-item {
      border-top: none !important;
      border: none;

      &-body {
        padding: 15px 48px;
      }

      @media(max-width: 375px) {
        &-body {
          padding: 5px 20px !important;
        }
      }

      .dx-accordion-item-title {
        display: flex;
        align-items: center;

        &:before {
          width: 17px;
          min-width: 22px;
          height: 22px;
          display: flex;
          margin-left: 0;
          margin-right: 15px;
          align-items: center;
          justify-content: center;
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "";
          background-image: url("/assets/img/plus-icon.svg");
          font-size: 13px;
          border-radius: 6px;
          background-color: #E8EAEE;
        }
      }

      &.dx-state-hover {
        .dx-accordion-item {
          border-color: #DCDFE5;
        }

        .dx-accordion-item-title {
          border-color: #DCDFE5;

          &:before {
            background-color: #DCDFE5;
          }
        }

        & > .dx-accordion-item-title {
          background-color: #FAFAFB;
        }
      }


      &.dx-accordion-item-opened .dx-accordion-item-title {
        border-bottom-color: #E8EAEE;

        &:before {
          background-image: url("/assets/img/minus-icon.svg");
        }

        &:hover {
          color: #424B5A;
        }
      }
    }
  }

  .dx-item.dx-accordion-item.dx-accordion-item-closed {
    background-color: #fff;
    border-bottom: 1px solid #E8EAEE !important;
    border-top: none !important;
  }

  .dx-item.dx-accordion-item.dx-accordion-item-opened {
    border-bottom: 1px solid #e6e6e6;
  }

  .dx-accordion-item.dx-accordion-item-opened,
  .dx-accordion-item.dx-accordion-item-opened.dx-state-hover,
  .dx-accordion-item.dx-item-selected.dx-accordion-item-opened.dx-state-hover {

    & > .dx-accordion-item-title {
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .dx-template-wrapper {
    padding: 15px 20px !important;

    .dx-accordion-item-closed {
      border-bottom: 1px solid #D8D8D8;
    }
  }
}

.w-columns {
  height: 79vh;
  display: flex;
  overflow: hidden;
  flex-wrap: nowrap;

  @include respond-to-max(1200) {
    padding: 0 15px;
  }

  @include respond-to-max(880) {
    padding: 0;
    height: auto;
  }

  .b-card {
    padding: 10px;
  }

  &>.b-header {
    width: 100%;
  }

  &>.b-card+.b-card {
    margin-left: 5px;
  }

  &-channels {
    margin: 0;
    flex: 1;
    min-width: 150px;
  }

  &-chat {
    margin: 0;
    flex: 2;
  }

  &-lease {
    margin: 0;
    min-width: 460px;
    flex: 2;
  }

  &-lease-attribute {
    max-width: calc(60% - 30px);
    flex: 1 1 calc(60% - 30px);
  }

  &-comments {
    flex: 0 1 40%;
    display: flex;
    flex-direction: column;
    max-width: 40%;
    border-radius: 4px;
    margin: 0 0 0 15px;
    background-color: #fff;
    border: 1px solid #E6E6E6;

    @include respond-to-max(880) {
      display: none;
    }

    &.full--screen {
      top: 0;
      left: 0;
      right: 0;
      margin: 0;
      height: 100%;
      display: block;
      max-width: 100%;
      position: fixed;

      .w-columns-comments-head {
        padding-left: 50px;
      }

      .w-columns-comments-body {
        padding-bottom: 130px;
      }
    }

    &-head {
      height: 56px;
      display: flex;
      position: relative;
      padding: 18px 20px;
      align-items: center;
      border-bottom: 1px solid #F5F5F5;

      &>.controls-back {
        top: auto;
        left: auto;
        margin-right: 15px;
        position: relative;
      }

      &>.b-header {
        margin: 0 15px 0 0;
        overflow: hidden;
        font-weight: 600;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-transform: uppercase;

        @include respond-to-max(880) {
          text-transform: capitalize;
        }
      }

      &>.b-badge {
        color: #29313E;
        line-height: 1;
        font-size: 10px;
        font-weight: 500;
        padding: 3px 10px;
        margin-left: auto;
        border-radius: 20px;
        background-color: #D8D8D8;
        text-transform: uppercase;

        &.b-badge-warning {
          color: #C69739;
          background-color: #FEEAC2;
        }
      }
    }

    &-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &-form {
      margin-top: auto;

      .message--form {
        position: relative;

        .dx-texteditor.dx-editor-outlined {
          border: none;
          display: flex;
          border-radius: 0;
          min-height: 70px;
          padding-right: 60px;
          flex-direction: column;
          border-top: 1px solid #E9E9E9;

          .dx-texteditor-container {
            display: flex;
            flex: 1 1 100%;
            flex-direction: column;

            .dx-texteditor-input {
              flex: 1 1 100%;
            }
          }
        }

        .b-btn {
          top: 50%;
          right: 20px;
          font-size: 22px;
          position: absolute;
          color: $color-primary;
          transform: translateY(-50%);
        }
      }
    }
  }
}

.b-message {

  @include respond-to-max(880) {
    margin: 0 0 38px 0;
    flex-direction: column;
  }

  &-ava {

    @include respond-to-max(880) {
      margin-bottom: 15px;
    }
  }
}

.dx-field-value:not(.dx-switch):not(.dx-checkbox):not(.dx-button),
.dx-field-value-static {
  width: 100%;
}

@media(max-width: 425px) {
  .b-lease-terms .dx-accordion-wrapper .dx-accordion-item-body {
    padding: 15px 30px !important;
  }
}


@media(max-width: 412px) {
  .b-lease-terms-controls {
    width: 32% !important;
  }

  .badge {
    opacity: 1;
  }
}

@media(max-width: 425px) {
  .w-columns-comments-head {
    padding: 18px 3px;
  }

  .w-columns-comments-head .controls-back {
    margin-right: 6px;
  }

  .w-columns-comments-head .b-header {
    margin-right: 10px;
  }

  .w-columns-comments-head .b-badge {
    max-width: 200px;
  }

  .b-lease-terms-header {
    max-width: 145px;
  }
}
