@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../scss/fonts";
@import "../scss/variables";
@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

body, html {
  background-color: #FAFCFB;
  font-family: $avenir-font;
}

.btn-primary {
  font-weight: 500;
  font-size: 14px;
  padding: 10px;
  /*width: 100%;*/
}

.container {
  width: 100%;
  max-width: 1200px;

  @include media-breakpoint-down(sm) {
    padding: 0;
  }
}

html {
  overflow-y: auto;
}

body {
  position: relative;
  min-height: 100vh;
}

.no-scroll {
  overflow: hidden;
}

.text-12 {
  font-size: 0.75rem;
}

.my-styling .pb-1 {
  font-weight: bold;
}

.my-styling .pb-4 {
  padding-bottom: 10px !important;
}

.text-13 {
  font-size: 0.8125rem;
}

.text-14 {
  font-size: 0.875rem;
}

.text-15 {
  font-size: 0.9375rem
}

.text-16 {
  font-size: 1rem !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-500 {
  font-weight: 500;
}

.fw-400 {
  font-weight: 400;
}

.w-0 {
  width: 0;
}

.w-91 {
  width: 91%;
}

.w-90 {
  width: 90%;
}

.w-96 {
  width: 96%;
}

.w-80 {
  width: 80%;
}

.w-77 {
  width: 77%;
}

.w-72 {
  width: 72%;
}

.w-60 {
  width: 60%;
}

.w-65 {
  width: 65%;
}

.w-35 {
  width: 35%;
}

.w-15 {
  width: 15%;
}

.w-45 {
  width: 45%!important;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

.w-1rem {
  width: 1rem;
}

.max-w-250 {
  max-width: 250px;
  margin-left: 40px !important;
  padding-left: 0;
  padding-right: 0;
}

.vh-100 {
  min-height: 75vh;
}

.text-aling-center {
  text-align: center;
}

.text-opacity-primary {
  color: $text-opacity-primary;
  text-transform: uppercase !important;
}

.text-opacity-secondary {
  color: $text-opacity-secondary;
}

.text-black {
  color: #424B5A !important;
}

.border-2 {
  border-width: 2px !important;

}

.m-26 {
  margin: 26px;
}

.w-round-image {
  margin: 0 0 30px;
  overflow: hidden;
  border-radius: 4px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default !important;
}

.mh-0 {
  min-height: 0;
}

.mw-0 {
  min-width: 0;
}

.object-fit-cover {
  object-fit: cover !important;
}

.insert-row {
  display: flex;
  align-items: center;
  gap: 6px;
  position: relative;
  top: -5px;
  white-space: nowrap;
}
