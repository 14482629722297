.dialog {
  .dx-popup-content {
    padding: 0 !important;
  }

  // When we change dx-popup title, it triggers size recalculation of title container and does it wrong, so only part of title is visible :(
  .dx-item.dx-toolbar-item.dx-toolbar-label {
    max-width: inherit !important;
  }

  &.overflow-hidden .dx-popup-normal {
    overflow: hidden;
  }
}

