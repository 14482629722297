@import '../_colors.scss';

.trigger-tooltip {
  height: 15px;
  width: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: $color-grey-one;
  border-radius: 50%;

  svg {
    height: 8px;
  }
}

.v2-default-tooltip.dx-tooltip-wrapper  {
  .dx-overlay-content .dx-popup-content {
    padding: 6px 8px;
    border-radius: 8px;

    .tooltip-menu {
      font-weight: 400;
      font-size: 11px;
      line-height: 16px;
      background-color: #686F7B;
      color: #FFFFFF;
    }
  }
}

.dark-bcg-tooltip.dx-tooltip-wrapper  {
  .dx-overlay-content .dx-popup-content {
    background-color:  #686F7B;
    color: #FFFFFF;
    border-radius: 8px;
  }
}
