.b-collapse {

  .dx-accordion-item.dx-state-hover > .dx-accordion-item-title {
    background-color: transparent;
  }

  .dx-accordion-item {
    border: none;

    &-title {
      display: flex;
      font-size: 14px;
      align-items: center;
      color: $color-primary;
      justify-content: center;
      text-transform: capitalize;

      &:before {
        order: 2;
        line-height: 1;
        font-size: 12px;
        color: $color-primary;
      }
    }
  }
}

.b-list-status {
  margin: 0;

  &-header {
    margin: 0;
  }

  .b-lease-terms {

    .dx-accordion-wrapper {

      .dx-accordion-item {

        &-title {
          padding-left: 0;

          @include respond-to-max(1200) {
            padding-left: 20px;
          }
        }

        & + .dx-accordion-item {
          border-top: 1px solid #f2f2f2;
        }

        &.dx-state-hover {

          .b-lease-terms-header {
            color: $color-primary;
          }

          .dx-accordion-item-title {

            &:before {
              color: $color-primary;
            }
          }

          .name-row {
            text-decoration: none;
            color: $color-primary;
          }

          .b-btn-icon {
            color: $color-primary;
          }
        }

        .status-row-indicator {
          width: 8px;
          min-width: 8px;
          height: 8px;
          margin-right: 5px;
          border-radius: 50%;
        }

        .status-row {
          display: flex;
          align-items: center;
        }

        .b-btn-icon {
          color: #d6d6d6;
          font-size: 18px;
        }
      }

      .dx-accordion-item-closed {
        background-color: #fff;
      }
    }
  }
}
