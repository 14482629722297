a {
  transition: color .4s ease-in-out, border-color .4s ease-in-out, background-color .4s ease-in-out;
}

button {
  cursor: pointer;
  outline: none !important;
}

.b-onboarding-body-toolbar {
  .b-btn-question:focus {
    box-shadow: none;
    outline: none;
    border: none;
  }
}

.b-btn {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;

  &-icon {
    vertical-align: middle;
  }

  &--small {
    padding: 5px;
  }

  &-primary {
    color: #fff;
    border-color: $color-primary;
    background-color: $color-primary;

    &:hover {
      color: #fff;
      border-color: darken($color-primary, 10%);
      background-color: darken($color-primary, 10%);
    }
  }

  &-reject {
    color: #fff;
    border-color: $color-reject;
    background-color: $color-reject;

    &:hover {
      color: #fff;
      border-color: darken($color-reject, 10%);
      background-color: darken($color-reject, 10%);
    }
  }

  &-default {
    color: #fff;
    border-color: #858585;
    background-color: #858585;

    &:hover {
      color: #fff;
      border-color: darken(#858585, 10%);
      background-color: darken(#858585, 10%);
    }
  }
}

.b-btn-outline {
  font-size: 12px;
  padding: 3px 6px;
  border: 1px solid;

  &-primary {
    color: #686F7B;
    border-color: #DCDFE5;

    &:hover {
      border-color: #B3B7BD;
    }
  }

  &-reject {
    color: $color-reject;
    border-color: $color-reject;

    &:hover {
      color: darken($color-reject, 10%);
      background-color: #ffffff;
      border-color: darken($color-reject, 10%);
    }
  }

  &-light {
    color: #fff;
    border-color: #fff;

    &:hover {
      color: darken(#fff, 20%);
      border-color: darken(#fff, 20%);
    }
  }
}

.b-link {
  border: none;
  color: #A6A6A6;
  font-size: 13px;
  cursor: pointer;
  padding: 0 0 3px;
  vertical-align: top;
  display: inline-block;
  border-bottom: 1px solid;
  text-transform: uppercase;
  background-color: transparent;

  &:hover {
    color: #59B680;
  }
}

.b-fancy-link {
  color: #59B680;
  font-size: 13px;
  line-height: 1;
  position: relative;
  transition: all .2s;
  padding-bottom: 5px;
  vertical-align: top;
  letter-spacing: .6px;
  display: inline-block;
  text-transform: uppercase;
  border-bottom: 1px solid #59B680;

  &:hover {
    text-decoration: none;
    color: darken(#59B680, 15%);
    border-bottom-color: transparent;
  }
}

.b-btn-icon {
  font-size: 14px;
  border: transparent;
  background-color: transparent;

  &-info {
    background-color: #E8EAEE;
    width: 18px;
    height: 18px;
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon-info {
      display: flex;
      width: 12px;
      height: 12px;

      &:before {
        content: none;
      }
    }

    &:hover {
      background-color: #DCDFE5;
    }
  }
}

.b-download {
  color: #4D4D4D;
  margin: 0 0 5px;
  font-size: 14px;
  flex-wrap: wrap;
  align-items: center;
  display: inline-flex;

  &:hover {
    text-decoration: none;
  }

  [class^="icon"] {
    margin-right: 10px;
  }

  &-decor {
    width: 100%;
    color: #59B680;
    font-size: 13px;
    padding-left: 20px;
  }
}

.b-link-fancy {
  padding: 0;
  border: none;
  font-size: 13px;
  position: relative;
  padding-bottom: 3px;
  display: inline-block;
  color: #fff !important;
  text-transform: uppercase;
  background-color: transparent;

  &:after {
    left: 0;
    bottom: 0;
    width: 16%;
    content: '';
    height: 2px;
    min-width: 30px;
    position: absolute;
    background-color: #fff;
  }

  &:hover {
    opacity: .6;
    text-decoration: none;
  }
}

.b-btn-question {
  border: none;
  padding: 6px 0;
  font-size: 27px;
  font-weight: 500;
  color: $color-primary;
  background-color: transparent;

  &:hover {
    color: darken($color-primary, 10%);
  }

  &>[class^="icon"] {
    font-size: 22px;
    vertical-align: middle;
  }

  &>.icon-rotate-ccw {
    font-size: 40px;
  }

  &.restart {

    &>[class^="icon"] {
      margin-right: 13px;
    }
  }

  &-text {
    display: inline-block;
    vertical-align: middle;
    text-transform: capitalize;
    border-bottom: 1px dashed $color-primary;
  }
}

.controls-back {
  z-index: 1;
  top: 15px;
  left: 20px;
  width: 21px;
  color: #fff;
  height: 21px;
  border: none;
  display: none;
  flex-shrink: 0;
  font-size: 10px;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  align-items: center;
  justify-content: center;
  background-color: #4EC280;

  @include respond-to-max(880) {
    display: flex;
  }
}

.my-div1 .b-btn-icon:hover {
  color: #59b680 !important;
}

.b-btn-icon:focus, .btn-send-message:focus {
  box-shadow: none;
  outline: none;
  border: none;
}

.b-btn-sm {
  padding: 4px 8px;
  font-size: 10px;
  text-transform: uppercase;
}

.b-btn {
  &-save {
    outline: none;
    background: #59B680;
    border: 1px solid #59B680;
    border-radius: 18px;
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover {
      color: #FFFFFF;
      background: #4BAA72;
      border: 1px solid #4BAA72;
    }

    &:focus {
      background: #479266;
      border: 1px solid #479266;
    }

    &:disabled {
      background: #9BD3B3;
      border: 1px solid #9BD3B3;
    }
  }

  &-cancel {
    outline: none;
    border: 1px solid #DCDFE5;
    border-radius: 18px;
    background: #ffffff;
    color: #686F7B;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:hover {
      border: 1px solid #B3B7BD;
    }

    &:focus {
      border: 1px solid #686F7B;
      color: #424B5A;
    }

    &:disabled {
      border: 1px solid #DCDFE5;
      color: #B3B7BD;
    }
  }

  &-xl {
    max-width: 323px;
    width: 100%;
    height: 52px;
  }
}
