.step {
  width: 21px;
  height: 21px;
  padding: 1px;
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  text-align: center;
  position: relative;
  border-radius: 50%;
  color: $color-white;
  justify-content: center;
  background-color: $color-darkgray;
}

.step-name {
  font-weight: 700;
  opacity: .5;
  width: 190px;
}

.step-sub-text {
  opacity: .5;
  font-size: 16px;
}

.step-date {
  opacity: .5;
}

.step-active {
  opacity: 1;
}

.dashes {
  background-image: url("/assets/img/vertical-dashes.svg");
  background-repeat: repeat-y;
  background-position-x: -19px;
}

.vertical-dash {
  position: absolute;
  transform: rotate(90deg);
  margin-top: 53px;
  left: -2px;
}

.dashes-position {
  &:first-child {
    margin-top: 1rem;
  }

  padding-bottom: 1rem;

  &:last-child {
    background: none
  }
}

//app-timeline-vertical #point3 {
//  background: #59b680;
//}
