@import '../../scss/_variables.scss';

.w-accordion {
  .dx-accordion-item-body {
    padding: 0;
  }

  .dx-accordion-item {
    padding: 17px 20px 10px;
    min-height: 55px;
    background: #f9f9fa;
    border: 0 !important;
    border-radius: 4px;

    & + .dx-accordion-item {
      margin-top: 20px;
    }

    &-title {
      padding: 0 0 15px;
      color: #4D4D4D;
      font-size: 16px;
      font-weight: 500;

      &:before {
        transform: rotate(-90deg);
      }
    }

    &.dx-state-hover > .dx-accordion-item-title,
    &.dx-state-active > .dx-accordion-item-title {
      background-color: transparent;
    }

    &.dx-accordion-item-opened > .dx-accordion-item-title:before {
      transform: rotate(180deg);
    }
  }
}

.lease-abstract .w-accordion .dx-accordion-item {
  box-shadow: none;
  background: #fafafa;
  border: 0 !important;
  border-bottom: 1px solid #dedede !important;
  border-radius: 0;

  &:first-child {
    /*border-top: 1px solid #dedede !important;*/
  }

  & + .dx-accordion-item {
    margin-top: 0;
  }

  &-title:before {
    transform: rotate(0);
  }

  &.dx-state-hover {
    background: #f5f5f5;
  }

  &.dx-state-active {
    background: rgba(96, 96, 96, 0.2);
  }

  &.dx-accordion-item-opened {
    background: #ffffff;

    & > .dx-accordion-item-title {
      color: $color-primary;

      &:before {
        transform: rotate(0);
        color: $color-primary;
      }
    }
  }
}

@media(max-width: 425px) {
  .w-accordion .dx-accordion-item {
    padding: 13px 10px;
  }
}
