@import "../../colors";

.tour-schedule-calendar {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;

  .tour-schedule-calendar-header {
    margin-bottom: 24px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-buttons {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &-button {
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;
      border: none;
      background: none;

      > i {
        width: 16px;
        height: 16px;

        > svg {
          display: block;
          width: 16px;
          height: 16px;
          color: #424B5A;
        }
      }

      &:disabled {
        cursor: default;

        > i {
          > svg {
            color: #B3B7BD;
          }
        }
      }
    }

    &-current-date {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      color: $color-grey-eight;
    }
  }

  .tour-schedule-calendar-content {
    &-legend {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        width: 34px;
        min-width: 34px;
        font-weight: 400;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        color: #424B5A;
      }
    }

    &-dates {
      &-row {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }

      &-cell {
        width: 34px;
        min-width: 34px;
        height: 34px;
        background-color: transparent;
        border-radius: 50%;
        border: none;
        outline: none;
        transition: background-color 0.2s ease-in-out;

        > span {
          position: relative;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #8E939C;
          transition: color 0.2s ease-in-out;
        }

        &:disabled {
          cursor: default;
        }

        &--today {
          > span {
            &:after {
              content: '';
              position: absolute;
              left: calc(50% - 3px/2);
              bottom: -1.5px;
              width: 3px;
              height: 3px;
              border-radius: 50%;
              background-color: #8E939C;
              transition: background-color 0.2s ease-in-out;
            }
          }
        }

        &--available {
          background-color: #F1F9F4;

          > span {
            font-weight: 500;
            color: #356D4D;

            &:after {
              background-color: #356D4D;
            }
          }
        }

        &--selected {
          background-color: #59B680;

          > span {
            font-weight: 500;
            color: #FFFFFF;

            &:after {
              background-color: #FFFFFF;
            }
          }
        }

        &--hidden {
          opacity: 0;
          pointer-events: none;
        }
      }
    }
  }

  .tour-schedule-calendar-footer {
    margin-top: 24px;

    &-timezone {
      display: flex;
      align-items: center;

      > i {
        margin-right: 7px;
        width: 16px;
        height: 16px;

        > svg {
          display: block;
          width: 16px;
          height: 16px;
          color: #424B5A;
        }
      }

      > span {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #8E939C;
      }
    }
  }
}
