@import "../../colors";

.modal-dialog .modal-content.tour-schedule-dialog {
  padding: 0;
  border-radius: 10px;
}

app-tour-schedule-dialog {
  height: 100%;
  overflow: hidden;
}

.tour-schedule-dialog-container {
  display: flex;
  height: 100%;
  padding: 0;
  overflow: hidden;

  .tour-schedule-dialog-pane {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: $color-grey-eight;

    &-header {
      padding: 15.5px 30px;
      display: flex;
      align-items: center;
      gap: 15px;
      border-bottom: solid 1px $color-grey-four;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.03em;
      color: #424B5A;

      &--noborder {
        padding: 16px 30px;
        border: none !important;
      }

      &-title {
        flex: 1;
      }

      .close-button {
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: none;
        background-color: $color-grey-three;
        outline: none;
        transition: background-color 0.2s ease-in-out;

        &:hover {
          background-color: $color-grey-four;
        }
        &:active {
          background-color: $color-grey-four;
        }

        > i {
          width: 16px;
          height: 16px;

          > svg {
            width: 16px;
            height: 16px;
            float: left;
            fill: $color-grey-seven;
          }
        }
      }

      .info-icon {
        width: 18px;
        height: 18px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-grey-three;
        cursor: pointer;

        > i {
          > svg {
            width: 12px;
            height: 12px;
            fill: $color-grey-seven;
          }
        }
      }

      p {
        margin-bottom: 0;
      }
    }

    &-content {
      flex: 1;
      flex-direction: column;
      display: flex;
      padding: 30px;

      &-wrapper {
        max-height: 230px;
      }
    }

    &-footer {
      margin-top: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-weight: 400;
      color: $color-grey-six;
    }
  }

  .tour-schedule-dialog-left-pane {
    width: 261px;
  }

  .tour-schedule-dialog-middle-pane {
    width: 620px;
    border-left: solid 1px $color-grey-four;
    border-right: solid 1px $color-grey-four;
  }

  .tour-schedule-dialog-right-pane {
    width: 300px;
    overflow: hidden;

    .tour-schedule-dialog-pane-header {
      gap: 0;
      justify-content: space-between;
    }
  }

  .today {
    font-weight: bold;
  }

  .tour-schedule-dialog-calendar {
    flex: 1;
    padding: 30px 6px 30px 30px;
    display: flex;
    overflow: hidden;

    &-wrapper {
      width: 310px;
    }

    &-selected-date {
      padding-right: 16px;
      padding-bottom: 26px;
      font-size: 14px;
      font-weight: 500;
    }

    &-time-slots {
      margin-left: 30px;
      height: 100%;
      min-width: 220px;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }

    &-time-slots-list {
      flex: 1;
      padding-right: 24px;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    &-time-slot-button {
      padding: 12px;
      width: 100%;
      min-height: 52px;
      font-size: 13px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-align: center;
      text-transform: uppercase;
      color: $color-grey-seven;
      background-color: $color-white;
      border: 1px solid $color-grey-four;
      border-radius: 18px;
      outline: inherit;
      cursor: pointer;
      transition: color 0.2s ease-in-out,
                  border-color 0.2s ease-in-out,
                  background-color 0.2s ease-in-out;

      &:hover {
        border-color: $color-grey-five;
      }

      &--active {
        color: $color-green-dark;
        background-color: #F1F9F4;
        border-color: #9BD3B3;
      }
    }
  }

  .time-slot-option-button {
    background: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    font-size: 13px;
    line-height: 18px;
    text-align: center;
    width: 220px;
    min-height: 45px;
    color: $color-grey-eight;
    border: 1px solid $color-grey-four;
    border-radius: 13px;

    &--active {
      background-color: #F1F9F4;
    }
  }

  .approve-button {
    color: white;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;

    font-size: 18px;
    line-height: 24px;
    text-align: center;
    width: 300px;
    min-height: 50px;
    background-color: $color-green;
    border: 1px solid $color-grey-four;
    border-radius: 18px;

    &:disabled {
      background-color: $color-grey-six;
      cursor: default;
    }
  }

  .greeting {
    color: $color-grey-eight;
    font-size: 18px;
    padding-bottom: 70px;
  }

  .approve-time-slot-options {
    max-width: 93%;
    padding-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }

  .approve-time-slot-buttons {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
  }

  .building-info {
    display: flex;
    align-items: center;
    gap: 14px;

    img {
      border-radius: 10px;
      object-fit: cover;
    }

    &-address {
      flex: 1;

      p {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 0;
        color: $color-grey-eight;
      }
    }

    &-unit-name {
      font-size: 14px;
      font-weight: 400;
      color: $color-grey-six;
    }
  }

  .tour-length-info {
    margin-top: 15px;
    display: flex;
    align-items: center;

    > i {
      margin-right: 6px;
      width: 20px;
      height: 20px;

      > svg {
        width: 20px;
        height: 20px;
        color: #424B5A;
      }
    }

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8E939C;
    }
  }

  .picked-time-slots {
    &-button {
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      min-height: 45px;
      width: 100%;
      border-radius: 13px;
      background-color: $color-green;
      color: white;
    }

    &-badges {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 7px;
      padding-bottom: 10px;
    }
  }

  .picked-time-slots {
    &-date-heading {
      font-size: 14px;
      font-weight: 500;
      color: $color-grey-eight;
      padding-bottom: 10px;
    }

    &-badges {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 7px;
      padding-bottom: 10px;
    }

    &-no-data {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;

      > i {
        width: 24px;
        height: 24px;

        > svg {
          width: 24px;
          height: 24px;
          color: #424B5A;
        }
      }

      p {
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #8E939C;
      }

      .subtitle {
        font-weight: 400;
        font-size: 11px;
        line-height: 15px;
        text-align: center;
        color: #8E939C;
      }
    }

    &-badge {
      padding: 0 10px;
      height: 22px;
      display: flex;
      align-items: center;
      gap: 2px;
      background-color: #D1E8DC;
      border-radius: 100px;
      font-weight: 500;
      font-size: 10px;
      line-height: 14px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #215C3A;

      > i {
        width: 12px;
        height: 12px;
        cursor: pointer;

        > svg {
          display: block;
          width: 12px;
          height: 12px;
          color: #356D4D;
        }
      }

      &--gray {
        color: #424B5A;
        background-color: #E8EAEE;
      }
    }

    &-too-many {
      text-align: center;
    }

    &-confirm-button {
      min-height: 52px;
      width: 100%;
      color: white;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.075rem;
      line-height: 24px;
      text-align: center;
      background-color: $color-green;
      border: none;
      border-radius: 18px;
      text-transform: uppercase;

      &:hover:not(:disabled) {
        background-color: #4BAA72;
      }

      &:disabled {
        background-color: #9BD3B3;
      }
    }

    &-cancel-button {
      margin-top: 10px;
      min-height: 52px;
      width: 100%;
      color: #686F7B;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      font-size: 13px;
      font-weight: 500;
      letter-spacing: 0.075rem;
      line-height: 24px;
      text-align: center;
      background-color: $color-white;
      border: 1px solid #DCDFE5;
      border-radius: 18px;
      text-transform: uppercase;
      transition: color 0.2s ease-in-out,
                  border-color 0.2s ease-in-out;

      &:hover {
        border-color: #B3B7BD;
      }

      &:active {
        color: #424B5A;
        border-color: #8E939C;
      }
    }
  }

  .tour-cancel {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    color: $color-grey-six;
    font-size: 14px;
    text-decoration: underline;
    user-select: none;

    > i {
      width: 12px;
      height: 12px;

      svg {
        width: 12px;
        height: 12px;
        display: block;
      }
    }
  }

  .counterpart-cell {
    margin-top: 15px;
    display: flex;
    align-items: center;

    &-images-container {
      display: inline-block;
      position: relative;
      width: 43px;
      min-width: 43px;
      height: 43px;
      min-height: 43px;
    }

    &-user-avatar {
      display: block;
      width: 29px;
      height: 29px;
      background-color: $color-grey-two;
      border-radius: 50%;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;

      border: 2px solid white;

      &_empty {
        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 0.76px solid $color-grey-five;

          > svg {
            width: 16.88px;
            height: 16.88px;
            fill: $color-grey-eight;
          }
        }
      }
    }

    &-company-logo {
      display: block;
      position: absolute;
      top: 15.5px;
      left: 15.5px;
      width: 26px;
      height: 26px;
      background-color: $color-grey-two;
      border-radius: 6px;
      overflow: hidden;

      &_empty {
        border: 0.62px solid $color-grey-five;

        i {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;

          > svg {
            width: 14.86px;
            height: 14.86px;
            fill: $color-grey-eight;
          }
        }
      }
    }

    &-info {
      margin-left: 14px;

      &-user {
        display: block;
        color: $color-grey-eight;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }

      &-name {
        display: inline-block;
        color: $color-grey-six;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      &-status {
        display: inline-block;
        margin-bottom: -5px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: $color-grey-three;

        i {
          > svg {
            margin-top: 4px;
            margin-left: 4.5px;
            width: 9px;
            height: 9px;
            fill: $color-grey-seven;
          }
        }
      }
    }
  }
}
