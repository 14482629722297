@import "../../../src/scss/mixin/responsive";

.b-dx-select.dx-selectbox {
  font-size: 13px;
  border-color: #DFDFDF;

  @include respond-to-max(580) {
    width: 100%;
  }

  &.select--year {
    width: 96px;

    @include respond-to-max(580) {
      width: 100%;
    }
  }

  .dx-dropdowneditor-icon {
    color: $color-primary;
    font-size: 11px;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &:before {
      margin: 0;
      content: "\e909";
      transform: translate(-50%, -50%);
    }
  }
}
