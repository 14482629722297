@import "~src/scss/mixin/responsive";

.b-card {
  display: block;
  margin: 0 0 10px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 6px 8px rgba(77, 77, 77, .1);

  &:hover {
    text-decoration: none;
  }

  &-header {
    display: flex;
    margin-bottom: 12px;
    align-items: center;
    padding-bottom: 10px;
    justify-content: space-between;
    border-bottom: 1px solid #E6E6E6;

    &-title {
      color: #4D4D4D;
      font-size: 14px;
      font-weight: 500;
    }
  }

  &>.b-controls-back {
    margin: 20px 0;
    font-size: 16px;
  }

  &--full {
    min-height: 100%;
  }
}

.b-card-lease {
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #DFDFDF;

  &-head {
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #EDF8F2;
    border-radius: 4px 4px 0 0;

    &>.b-header {
      margin: 0;
      color: #29313E;
      font-size: 15px;
      text-transform: capitalize;
    }
  }

  &-body {

    .b-list-status-header {
      color: #29313E;
      font-weight: 500;
      padding: 14px 20px;
      border-bottom: 1px solid #DFDFDF;
    }
  }
}

.b-card--user {
  margin: 0 0 5px;
  padding: 10px 7px;
}

.b-user-ava {
  width: 100%;
  display: flex;
  align-items: center;

  &.big {

    .b-user-ava-media {
      width: 54px;
      height: 54px;
    }
  }

  &-media {
    width: 30px;
    height: 30px;
    flex-shrink: 0;
    margin-bottom: 0;
    overflow: hidden;
    border-radius: 50%;

    &>img {
      width: 100%;
      object-fit: cover;
    }
  }

  &-name {
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 500;
    padding-left: 15px;
  }
}

.c-card {
  padding: 0 20px 20px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #E2E2E2;

  &-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.2;
    margin: 0 0 26px;
    padding-left: 10px;
    border-left: 4px solid #4D4D4D;
  }
}

.b-building-card {
  display: flex;
  white-space: nowrap;
  align-items: center;

  &-media {
    width: 7rem;
    flex-shrink: 0;
    margin: 10px auto;
  }

  &-title {
    color: #4D4D4D;
    font-size: 14px;
    font-weight: 500;
    margin: 0 0 3px 0;
    white-space: pre-wrap;
  }

  &-subtitle {
    color: #979797;
    font-weight: 400;
  }

  &-inner {
    display: flex;
    font-size: 14px;
    flex-direction: column;
  }
}

.my-styling .b-controls-back,
.my-styling .b-user-ava {
  padding-left: 15px;
}

.b-controls-back .b-controls-back-header{
  color: #59b680;
}

@media(max-width: 425px) {
  .c-card {
    padding: 23px 10px;
  }
}
